import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useMount from 'react-use/lib/useMount'

import { getRankNum, getUserRankLabel, isMaxRank } from 'shared/utils/user'

import { userRank } from 'shared/api/stats'

const UserRank = () => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    rank: null,
    nextRank: null,
    nextRankPercent: 0,
  })

  const percent = data.nextRankPercent.toFixed() ?? 0
  const maxRank = isMaxRank(data.rank)
  const currentRankNumber = getRankNum(data.rank)

  const fetchData = () => {
    setLoading(true)
    userRank()
      .then((data) => setData(data))
      .finally(() => setLoading(false))
  }

  useMount(() => {
    fetchData()
  })

  return maxRank ? (
    <div className={`user-status ${loading ? 'loading' : ''}`}>
      <div className="user-status--item">
        <div className="user-status--container">
          <div className="user-status--title">
            <span>{t('Текущий статус')}</span>
          </div>
          <div className="user-status--value">
            <div className="user-status--value">
              {maxRank ? `VIP ${t('Агент')}` : t(getUserRankLabel(currentRankNumber))}
            </div>
          </div>
        </div>
      </div>
      <div className="user-status--item">
        <div className="user-status--container">
          <div className="stone-logo stone-logo_lg"></div>
        </div>
      </div>
    </div>
  ) : (
    <div className={`user-status ${loading ? 'loading' : ''}`}>
      <div className="user-status--item">
        <div className="user-status--container">
          <div className="user-status--title">{t('Текущий статус')}:</div>
          <div className="user-status--value">{data.rank ? t('Агент') : t('Клиент')}</div>
        </div>
      </div>
      <div className="user-status--item">
        <div className="user-status--icon">
          <svg
            viewBox="0 0 35 35"
            className="chart-circle"
            style={{ '--stroke-offset': 100 - percent, '--stroke-width': 1.3 }}
          >
            <defs>
              <linearGradient id="cg" x1="0" y1="0" x2="0" y2="100%" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FA6E7F" />
                <stop offset="1" stopColor="#FDB199" />
              </linearGradient>
            </defs>
            <circle cx="50%" cy="50%" r="16" className="circle2"></circle>
            <circle cx="50%" cy="50%" r="16" className="circle1" stroke="url(#cg)"></circle>
          </svg>
          <div className="user-status--icon-value">{percent}%</div>
        </div>
        <div className="user-status--container">
          <div className="user-status--title">
            <span>{t('До следующего статуса')}</span>
          </div>
          <div className="user-status--value">
            <div className="user-status--value">{isMaxRank(data.nextRank) ? `VIP ${t('Агент')}` : t('Агент')}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserRank
