import React from 'react'

import History from './components/History'
import LevelsStats from './components/LevelsStats'
import PageHeader from './components/PageHeader'
import ReferralBlock from './components/ReferralBlock'
import Tree from './components/Tree'
import UserRank from './components/UserRank'

const Affiliate = () => {
  return (
    <div className={'page'}>
      <PageHeader />

      <div className="page--content">
        <div className="dashboard-grid">
          <div className="dashboard-grid--item">
            <div className="panel panel_vam">
              <div className="panel-body">
                <UserRank />
              </div>
            </div>
          </div>
          <div className="dashboard-grid--item">
            <div className="panel ">
              <div className="panel-body">
                <ReferralBlock />
              </div>
            </div>
          </div>
          <div className="dashboard-grid--line">
            <div className="panel ">
              <div className="panel-body">
                <LevelsStats />
              </div>
            </div>
          </div>
        </div>
        <History />
        <Tree />
      </div>
    </div>
  )
}

export default Affiliate
