import { useState } from 'react'
import { toast } from 'react-toastify'
import useSetState from 'react-use/lib/useSetState'

import useNotificationsStore from 'shared/stores/notifications'
import useUserStore from 'shared/stores/user'

import { clearNotification, getNotificationList, hideNotifications } from 'shared/api/notification'

import { NOTIFICATION_FILTER_NEW, WS_URL } from '../constants'

const useNotifications = () => {
  const user = useUserStore()
  const notifications = useNotificationsStore()
  const [loading, setLoading] = useState(false)
  const list = notifications.list

  const connectWs = async (connection, renderToast) => {
    connection.current = new WebSocket(`${WS_URL}/?token=${user.wsToken}`)

    connection.current.onopen = () => {
      connection.current.addEventListener('message', (message) => {
        const currentToast = JSON.parse(message.data)

        renderToast(currentToast)
        fetchList(1, 10, 'new')
      })
    }

    connection.current.onclose = () => {
      setTimeout(function () {
        connectWs(connection, renderToast)
      }, 1000)
    }

    connection.current.onerror = function () {
      connection.current.close()
    }
  }

  const fetchList = (page, limit, filter) => {
    setLoading(true)
    getNotificationList(page, limit, filter)
      .then((res) => {
        notifications.setNotifications({ ...res })
        if (filter === NOTIFICATION_FILTER_NEW && JSON.stringify(notifications.new.data) !== JSON.stringify(res.data)) {
          notifications.updateNotifications({
            ...res,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const hideItem = (ids, disableFetch = false, fetchCb) => {
    hideNotifications(ids)
      .then(() => {
        if (!disableFetch) {
          fetchList(1, 10, NOTIFICATION_FILTER_NEW)
        }
        if (fetchCb) {
          fetchCb(1, 10, 0)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const clearNotificationsHandler = () => {
    clearNotification()
      .then(() => {
        fetchList(1, 10, NOTIFICATION_FILTER_NEW)
        toast.dismiss()
      })
      .catch((e) => console.log(e))
  }

  const fetchDropdownList = () => {
    getNotificationList(1, 10, NOTIFICATION_FILTER_NEW)
      .then((res) => {
        notifications.setDropdownData(res.data)
        if (JSON.stringify(notifications.new.data) !== JSON.stringify(res.data)) {
          notifications.updateNotifications({
            ...res,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    list,
    loading,
    fetchList,
    hideItem,
    clearNotificationsHandler,
    connectWs,
    fetchDropdownList,
  }
}

export default useNotifications
