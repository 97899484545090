import React from 'react'
import { useTranslation } from 'react-i18next'

const Tabs = ({ options, activeTab, onChangeTab }) => {
  const { t } = useTranslation()

  return (
    <div className="btn-tabs">
      {options.map(({ value, label }) => (
        <button
          key={value}
          className={`btn-tab ${activeTab === value ? 'is-active' : ''}`}
          onClick={() => onChangeTab(value)}
        >
          <span>{t(label)}</span>
        </button>
      ))}
    </div>
  )
}

export default Tabs
