import { LOGIN_SOURCE } from 'shared/constants'

import { get, post } from 'shared/api/fetch'

export const createTicket = (data) => {
  return post(`support/tickets`, { ...data, source: LOGIN_SOURCE }, true)
}

export const getTickets = (page = 1, size = 10) => {
  return get(`support/tickets?page=${page}&size=${size}&source=${LOGIN_SOURCE}`, true)
}

export const getTicketById = (id) => {
  return get(`support/tickets/${id}?source=${LOGIN_SOURCE}`, true)
}

export const reopenTicket = (id) => {
  return get(`support/tickets/${id}/reopen?source=${LOGIN_SOURCE}`, true)
}

export const closeTicket = (id) => {
  return get(`support/tickets/${id}/close?source=${LOGIN_SOURCE}`, true)
}

export const sendMessage = (id, data) => {
  return post(`support/tickets/${id}/messages`, { ...data, source: LOGIN_SOURCE }, true)
}

export const getTicketMessages = (id, page, size) => {
  return get(`support/tickets/${id}/messages?page=${page}&size=${size}&source=${LOGIN_SOURCE}`, true)
}

export const loadFilesPreview = (data) => {
  return post(`support/load-preview`, data, true, {
    isFormData: true,
  })
}
