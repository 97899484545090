import React from 'react'
import { Navigate } from 'react-router-dom'

import useAmbassador from 'shared/hooks/useAmbassador'

import Loader from '../../components/Loader'
import HeaderInfo from './components/HeaderInfo'
import History from './components/History'
import PageHeader from './components/PageHeader'

const Ambassador = () => {
  const { statusLoading, status } = useAmbassador()

  if (statusLoading) return <Loader />
  if (!statusLoading && !status.ambassador) return <Navigate to={'/404'} />

  return (
    <div className={'page'}>
      <PageHeader />
      <div className="page--content">
        <div className="layout-block">
          <HeaderInfo />
          <History />
        </div>
      </div>
    </div>
  )
}

export default Ambassador
