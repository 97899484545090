import React from 'react'
import { useNavigate } from 'react-router'
import useMount from 'react-use/lib/useMount'

import Loader from 'components/Loader'

import useUserStore from 'shared/stores/user'

import { isGuest } from 'shared/utils/user'

const Logout = () => {
  const navigate = useNavigate()
  const user = useUserStore()

  const isUserGuest = isGuest(user)

  useMount(() => {
    if (isUserGuest) {
      navigate('/')
      return
    }

    setTimeout(() => {
      user.logout()
      navigate('/')
    }, 350)
  })

  return <Loader />
}

export default Logout
