import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import useMount from 'react-use/lib/useMount'

import Loader from 'components/Loader'

import useUserStore from 'shared/stores/user'

import { isGuest } from 'shared/utils/user'

import { refresh as refreshToken } from 'shared/api/auth'

const Refresh = () => {
  const navigate = useNavigate()
  const user = useUserStore()

  useMount(() => {
    setTimeout(() => refresh(user.refreshToken), 1500)
  })

  useEffect(() => {
    if (isGuest(user)) {
      navigate('/')
    }
  }, [user])

  const refresh = (token) => {
    refreshToken({ token })
      .then(({ token }) => {
        user.update({ accessToken: token })
        history.back()
      })
      .catch(() => logout())
  }

  const logout = () => {
    user.logout()
    navigate('/auth/logout')
  }

  return <Loader />
}

export default Refresh
