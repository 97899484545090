import { NEWS_SOURCE_COMMON } from '../constants'
import { get } from './fetch'

export const getNews = (language = 'en', page = 1, size = 12, source = NEWS_SOURCE_COMMON) => {
  return get(`news?lang=${language}&page=${page}&size=${size}&source=${source}`, false)
}

export const getNewsItem = (id, language) => {
  return get(`news/${id}?lang=${language}`, false)
}
