import Chartist from 'chartist'
//   eslint-disable-next-line
import * as Gradient from 'chartist-plugin-gradient'
//   eslint-disable-next-line
import * as Tooltip from 'chartist-plugin-tooltips-updated'
import get from 'lodash/get'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSetState from 'react-use/lib/useSetState'
import { PERIOD_MONTH, PERIOD_WEEK, PERIOD_YEAR } from 'shared/constants'

import useLevelStatsDataStore from 'shared/stores/levelStatsData'
import useUserStore from 'shared/stores/user'

import { parseAndFormat } from 'shared/utils/date'
import { asUSCR } from 'shared/utils/money'
import { declOfNum } from 'shared/utils/ui'

import { getParticipants } from 'shared/api/structure'

function createChart(chart, data, mylow = 0, myHeight = '23rem', valueString) {
  if (chart.current) {
    new Chartist.Bar(chart.current, data, {
      low: 0,
      showArea: true,
      areaBase: 0,
      showPoint: true,
      axisX: {
        showGrid: false,
      },
      axisY: {
        showGrid: true,
        offset: 0,
        showLabel: false,
        labelInterpolationFnc: function (value) {
          return Math.round(value)
        },
      },
      height: myHeight,
      fullWidth: true,
      chartPadding: {
        right: 35,
        left: 35,
      },
      plugins: [
        Chartist.plugins.tooltip({
          // anchorToPoint: true,
          transformTooltipTextFnc: function (value) {
            if (valueString) {
              return value + valueString
            } else {
              return value
            }
          },
        }),
      ],
    })
  }
}

const ClientsBlock = () => {
  const { t } = useTranslation()
  const user = useUserStore()
  const { totalStats } = useLevelStatsDataStore()
  const [chartData, setChartData] = useSetState({
    data: [],
    loading: false,
  })

  const [period, setPeriod] = useState(PERIOD_WEEK)
  const chartRef = useRef()

  const totalClients =
    get(totalStats.data, 'personalParticipants.active', 0) + get(totalStats.data, 'personalParticipants.passive', 0)

  useEffect(() => {
    fetchData(period)
  }, [])

  useEffect(() => {
    renderChart(chartData.data)
  }, [JSON.stringify(chartData.data), user.language])

  const renderChart = (data, locale = 'ru') => {
    const chartData = data.filter((item, key) =>
      period === 'month' ? key === 0 || key === data.length - 1 || (key + 1) % 2 === 0 : true,
    )

    const dataProfit = {
      labels: chartData.map((item) =>
        period === PERIOD_YEAR
          ? parseAndFormat(item.date, 'MMM', undefined, undefined, user.language)
          : parseAndFormat(item.date, 'dd.MM'),
      ),
      series: [chartData.map((item) => (item.count > 0 ? item.count : null))],
    }

    createChart(chartRef, dataProfit, 0, '12rem', '')
  }

  const fetchData = (period) => {
    setChartData({ loading: true })

    getParticipants(period)
      .then((res) => setChartData({ data: res }))
      .finally(() => setChartData({ loading: false }))
  }

  const handleChangeChartPeriod = (ev, currPeriod) => {
    ev.preventDefault()
    if (period !== currPeriod) {
      setPeriod(currPeriod)
      fetchData(currPeriod)
    }
  }

  return (
    <div className={`stats-block stats-block_clients${chartData.loading ? ' loading' : ''}`}>
      <div className="stats-block--header">
        <div className="stats-block--value">
          <div className="stats-value">
            <div className="stats-value--title">{t('Объем продаж')}</div>
            <div className="stats-value--value-row">
              <div className="stats-value--value">{asUSCR(totalStats.data?.totalTurnover)}</div>
            </div>
          </div>
          <div className="stats-value">
            <div className="stats-value--title">{t('Клиенты филиала')}</div>
            <div className="stats-value--value-row">
              <div className="stats-value--value">
                {totalClients} {declOfNum(totalClients, [t('Клиент'), t('клиента'), t('Клиентов')])}
              </div>
            </div>
          </div>
        </div>
        <div className="stats-block--controls">
          <ul className="chart-range-controls">
            <li className={`chart-range-controls--item ${period === PERIOD_WEEK ? 'is-active' : ''}`}>
              <button
                className="chart-range-controls--button"
                onClick={(ev) => handleChangeChartPeriod(ev, PERIOD_WEEK)}
              >
                {t('Нед')}
              </button>
            </li>
            <li className={`chart-range-controls--item ${period === PERIOD_MONTH ? 'is-active' : ''}`}>
              <button
                className="chart-range-controls--button"
                onClick={(ev) => handleChangeChartPeriod(ev, PERIOD_MONTH)}
              >
                {t('Мес')}
              </button>
            </li>
            <li className={`chart-range-controls--item ${period === PERIOD_YEAR ? 'is-active' : ''}`}>
              <button
                className="chart-range-controls--button"
                onClick={(ev) => handleChangeChartPeriod(ev, PERIOD_YEAR)}
              >
                {t('Год')}
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="stats-block--chart">
        <div className="chart-container">
          <div className="chart-wrapper" ref={chartRef} />
        </div>
      </div>
    </div>
  )
}

export default ClientsBlock
