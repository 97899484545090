import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import TransactionsTable from './Transactions'
import WithdrawalsTable from './Withdrawals'

const HISTORY_TYPE_TRANSACTIONS = 'transactions'
const HISTORY_TYPE_WITHDRAWALS = 'withdrawals'

const TABLES = {
  [HISTORY_TYPE_WITHDRAWALS]: <WithdrawalsTable />,
  [HISTORY_TYPE_TRANSACTIONS]: <TransactionsTable />,
}
const History = () => {
  const { t } = useTranslation()

  const [tab, setTab] = useState(HISTORY_TYPE_TRANSACTIONS)

  const handleChangeTab = (type) => {
    setTab(type)
  }

  return (
    <div className="layout-block">
      <div className="layout-block--header">
        <div className="layout-block--title">{t('Статистика')}</div>

        <div className="layout-block--controls">
          <div className="btn-group">
            <button
              className={`btn btn-light ${tab === HISTORY_TYPE_TRANSACTIONS ? 'btn-primary' : ''}`}
              onClick={() => handleChangeTab(HISTORY_TYPE_TRANSACTIONS)}
            >
              <span>{t('Операции')}</span>
            </button>
            <button
              className={`btn btn-light ${tab === HISTORY_TYPE_WITHDRAWALS ? 'btn-primary' : ''}`}
              onClick={() => handleChangeTab(HISTORY_TYPE_WITHDRAWALS)}
            >
              <span>{t('Выводы')}</span>
            </button>
          </div>
        </div>
      </div>
      {TABLES[tab]}
    </div>
  )
}

export default History
