import find from 'lodash/find'

import { CURRENCY_CR, CURRENCY_USCR } from '../constants'

const getAmount = (obj, key, currency) => {
  return (
    find(obj[key], { currency }) || {
      value: 0,
      currency,
    }
  )
}
const transformRank = (data, rank) => {
  const current = data[rank]
  const bonusUSCR = getAmount(current, 'bonuses', CURRENCY_USCR)
  const bonusCR = getAmount(current, 'bonuses', CURRENCY_CR)

  const turnoverUSCR = getAmount(current, 'turnovers', CURRENCY_USCR)

  const turnoverCR = getAmount(current, 'turnovers', CURRENCY_CR)

  return {
    rankNumber: rank,
    ...current,
    bonus: {
      [CURRENCY_USCR]: bonusUSCR,
      [CURRENCY_CR]: bonusCR,
    },
    turnover: {
      [CURRENCY_USCR]: turnoverUSCR,
      [CURRENCY_CR]: turnoverCR,
    },
  }
}

export const buildLevelStatsData = (data) => {
  return Object.keys(data).map((rank) => transformRank(data, rank))
}

export const buildLevelStatsDataObj = (data) => {
  const obj = {}

  Object.keys(data).forEach((rank) => {
    obj[rank] = transformRank(data, rank)
  })

  return obj
}
