import { useEffect, useState } from 'react'
import useMount from 'react-use/lib/useMount'

import useUiStore from 'shared/stores/ui'

import { DARK_THEME, LIGHT_THEME, THEME_LOCALSTORAGE_KEY } from '../constants'

const useThemeToggle = () => {
  const { toggleTheme: changeTheme } = useUiStore()
  const htmlElement = document.getElementsByTagName('html')[0]

  const [currentTheme, setCurrentTheme] = useState(localStorage.getItem(THEME_LOCALSTORAGE_KEY))

  const toggleTheme = () => {
    const theme = currentTheme === LIGHT_THEME ? DARK_THEME : LIGHT_THEME

    localStorage.setItem(THEME_LOCALSTORAGE_KEY, theme)
    setCurrentTheme(theme)
    changeTheme(theme)
  }

  useMount(() => {
    htmlElement.className = currentTheme
    changeTheme(currentTheme)
  })

  useEffect(() => {
    htmlElement.className = currentTheme
  }, [currentTheme])

  return {
    toggleTheme,
  }
}

export default useThemeToggle
