import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import useMount from 'react-use/lib/useMount'
import * as yup from 'yup'

import useFormWrapper from 'shared/hooks/useFormWrapper'

import Button from 'shared/components/Button'
import FormField from 'shared/components/forms/FormField'
import Input from 'shared/components/forms/Input'

import { sendMessage } from '../../../api/support'
import useLoadPreviews from '../../../hooks/useLoadPreviews'
import useTicketsData from '../../../hooks/useTicketsData'
import { trimValues } from '../../../utils/form'

const defaultValues = {
  message: '',
  files: [],
}

const ACCEPTED_TYPES = ['image/jpeg', 'image/png', 'application/pdf']

const MessageForm = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { fetchTicketMessages } = useTicketsData()
  const [loading, setLoading] = useState(false)
  const validationSchema = yup.object().shape({
    message: yup.string().trim().required(t('Обязательное поле')),
    files: yup.array(),
  })
  const { form, setFormErrors, getFieldError } = useFormWrapper(validationSchema, {
    mode: 'all',
    reValidateMode: 'all',
    defaultValues,
  })
  const { files, handleRemoveFile, handleAddFile, canUpload, previewLoading, setFiles } = useLoadPreviews({
    setError: setFormErrors,
    form,
  })

  const handleSubmit = () => {
    setLoading(true)
    sendMessage(id, trimValues(form.getValues()))
      .then((res) => {
        fetchTicketMessages(id)
        form.reset(defaultValues)
        setFiles([])
      })
      .catch((err) => {
        if (err.errors) {
          setFormErrors(err.errors)
        } else {
          console.log(err)
        }
      })
      .finally(() => setLoading(false))
  }

  const autoResize = () => {
    const element = document.querySelector('[data-autosize]')

    if (element) {
      const offset = element.offsetHeight - element.clientHeight

      element.addEventListener('input', function (event) {
        event.target.style.height = 'auto'
        event.target.style.height = event.target.scrollHeight + offset + 'px'
      })
    }
  }

  useMount(() => {
    autoResize()
  })
  useEffect(() => {
    form.setValue(
      'files',
      files.map((el) => el.loadName),
    )
  }, [files])
  return (
    <div className={`support-ticket--form `}>
      <div className="support-ticket-form">
        <div className={`support-ticket-form--files`}>
          <ul className="upload-img-list upload-img-list_lg">
            {files.map((file, idx) => {
              const isPdf = file.type === 'application/pdf'

              return isPdf ? (
                <li className="upload-img-list--item upload-img-list--item_doc" key={file.name}>
                  <span className="upload-img-list--item-filename">{file.name}</span>
                  <a
                    href="#"
                    className="upload-img-list--remove"
                    title="Удалить"
                    onClick={(e) => handleRemoveFile(e, idx)}
                  >
                    {t('Удалить')}
                  </a>
                </li>
              ) : (
                <li className="upload-img-list--item" key={file.name}>
                  <a href="#" className="upload-img-list--link" onClick={(e) => handleRemoveFile(e, idx)}>
                    <img src={file.uri} alt={file.name} />
                  </a>
                </li>
              )
            })}
            {canUpload && (
              <li className={`upload-img-list--item ${previewLoading ? 'loading' : ''}`} key={'file'}>
                <label htmlFor="svcutgg" className="list-upload-button" title={t('Добавить вложение')}>
                  <input
                    type="file"
                    id="svcutgg"
                    onChange={handleAddFile}
                    disabled={!canUpload}
                    accept={ACCEPTED_TYPES.join(',')}
                  />
                  <span className="list-upload-button--text" />
                </label>
              </li>
            )}
          </ul>
        </div>
        <div className="support-ticket-form--fields">
          <FormField error={getFieldError('message')}>
            <Input type={'textarea'} name={'message'} register={form.register} options={{ rows: 3 }} data-autosize />
          </FormField>
          <FormField error={getFieldError('files')}>
            <div />
          </FormField>
        </div>

        <div className="support-ticket-form--footer">
          <div className="support-ticket-form--description">
            <div>{t('Максимальный размер одного файла: {{value}} Мб', { value: 10 })}</div>
            <div>{t('Допустимые форматы')}: jpg, jpeg, png, pdf</div>
          </div>

          <div className="support-ticket-form--buttons">
            <Button onClick={handleSubmit} variant={'primary'} disabled={!form.formState.isValid} loading={loading}>
              <span>{t('Отправить')}</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MessageForm
