import useUserStore, { VIEW_MODE_DESKTOP } from 'shared/stores/user'

import useScreenSize from './useScreenSize'

const useMedia = (maxWidth = 767) => {
  const user = useUserStore()
  const [width] = useScreenSize()
  const isDesktop = width > maxWidth

  if (width === 0) {
    return {
      isMobile: false,
      isDesktop: false,
    }
  }

  const isViewMode = user.viewMode !== null
  const isViewModeDesktop = user.viewMode === VIEW_MODE_DESKTOP

  return {
    isMobile: isViewMode ? !isViewModeDesktop : !isDesktop,
    isDesktop: isViewMode ? isViewModeDesktop : isDesktop,
  }
}

export default useMedia
