import { PERIOD_MONTH } from 'shared/constants'

import { get } from '../fetch'

export const getParticipants = (period = PERIOD_MONTH, lvl = '8') => {
  return get(`structure/participants?period=${period}&level=${lvl}`, true)
}

export const getStats = (level = 8, period, start = '', end = '') => {
  return get(`structure/stats?level=${level}&period=${period}&start=${start}&end=${end}`, true)
}

export const getLevelStats = (period, start = '', end = '') => {
  return get(`structure/stats/by-levels?period=${period}&start=${start}&end=${end}`, true)
}

export const getList = (page = 1, id = '', search = null, type = null, size = 10) => {
  return get(`structure/list?id=${id}&search=${search ?? ''}&status=${type ?? ''}&page=${page}&size=${size}`, true)
}

export const getHistory = (level = 8, page = 1, size = 10) => {
  return get(`structure/history?level=${level}&page=${page}&size=${size}`, true)
}
export const getParticipantById = (id) => {
  return get(`structure/list/${id}`, true)
}

export const getChartTurnovers = (period, start = '', end = '') => {
  return get(`structure/charts/turnovers?period=${period}&start=${start}&end=${end}`, true)
}
export const getChartParticipants = (period, start = '', end = '') => {
  return get(`structure/charts/participants?period=${period}&start=${start}&end=${end}`, true)
}

export const getStructureBonusesStats = () => {
  return get(`structure/bonuses/stats`, true)
}
