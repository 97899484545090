import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Loader from 'components/Loader'

import useUserStore from 'shared/stores/user'

import { loginByKey } from 'shared/api/auth'

const ByKey = () => {
  const { key } = useParams()
  const user = useUserStore()
  const navigate = useNavigate()

  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (key && !isLoaded) {
      loginByKey(key)
        .then((res) => {
          user.update({ accessToken: res.accessToken, refreshToken: res.refreshToken })
          setTimeout(() => navigate('/'), 350)
        })
        .catch(() => navigate('/'))
        .finally(() => {
          setIsLoaded(true)
        })
    }
  }, [key, isLoaded])

  return <Loader />
}

export default ByKey
