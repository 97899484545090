import {
  BLOCKCHAIN_ICONS,
  BLOCKCHAIN_LABELS,
  CURRENCY_BASE,
  CURRENCY_DESCRIPTIONS,
  CURRENCY_ICONS,
  CURRENCY_USDT,
} from '../constants'

export const getCurrencyDescription = (currency, blockchain = null) => {
  const key = currency === CURRENCY_USDT ? `${blockchain}_${currency}` : currency

  return CURRENCY_DESCRIPTIONS?.[key]
}

export const getCurrencyIcon = (currency, blockchain = null) => {
  const key = currency === CURRENCY_USDT ? `${blockchain}_${currency}` : currency

  return CURRENCY_ICONS?.[key]
}

export const getBlockchainLabel = (blockchain) => {
  return BLOCKCHAIN_LABELS?.[blockchain]
}

export const getBlockchainIcon = (blockchain) => {
  return BLOCKCHAIN_ICONS?.[blockchain]
}

export const exchangeFromBaseCurrency = (baseAmount, currency, rates = {}) => {
  if (currency === CURRENCY_BASE) {
    return baseAmount
  }

  if (rates[currency] === undefined) {
    return 0
  }

  const rate = rates[currency]

  return baseAmount / rate
}

export const getCurrencyAlias = (currency) => {
  const aliases = {
    [CURRENCY_BASE]: 'U$D',
  }

  return aliases[currency] || currency
}

export const composeCurrency = (currency, blockchain = null) => {
  if (blockchain) {
    return `${blockchain}_${currency}`
  }

  return currency
}

export const recomposeCurrency = (currency) => {
  const parts = currency.split('_')

  if (parts.length > 1) {
    return {
      currency: parts[1],
      blockchain: parts[0],
    }
  }

  return {
    currency: parts[0],
    blockchain: null,
  }
}
