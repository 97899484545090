import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import useMount from 'react-use/lib/useMount'
import * as yup from 'yup'

import useFormWrapper from 'shared/hooks/useFormWrapper'

import Button from 'shared/components/Button'
import Modal from 'shared/components/Modal'
import FormField from 'shared/components/forms/FormField'
import Input from 'shared/components/forms/Input'

import { createTicket } from '../api/support'
import useLoadPreviews from '../hooks/useLoadPreviews'
import useTicketsData from '../hooks/useTicketsData'
import { trimValues } from '../utils/form'

const SUBJECT_MAX_LENGTH = 50

const ACCEPTED_TYPES = ['image/jpeg', 'image/png', 'application/pdf']

const defaultValues = {
  subject: '',
  message: '',
  files: [],
}

const NewMessageModal = ({ isOpen = false, onClose }) => {
  const { t } = useTranslation()
  const { fetchTicketsData } = useTicketsData()
  const [loading, setLoading] = useState(false)
  const validationSchema = yup.object().shape({
    subject: yup
      .string()
      .trim()
      .min(
        3,
        t('Минимальное количество {{count}} символа', {
          count: 3,
        }),
      )
      .max(SUBJECT_MAX_LENGTH)
      .required('Обязательное поле'),
    message: yup.string().trim().required(t('Обязательное поле')),
    files: yup.array(),
  })
  const navigate = useNavigate()
  const { getFieldError, form, setFormErrors } = useFormWrapper(validationSchema, {
    mode: 'all',
    reValidateMode: 'all',
    defaultValues,
  })
  const { files, handleRemoveFile, handleAddFile, canUpload, previewLoading, setFiles } = useLoadPreviews({
    setError: setFormErrors,
    form,
  })

  const handleClose = (ev) => {
    ev.preventDefault()
    form.reset(defaultValues)
    setFiles([])
    onClose && onClose()
  }

  const handleSubmit = () => {
    setLoading(true)
    createTicket(trimValues(form.getValues()))
      .then((res) => {
        fetchTicketsData(1, 10)
        navigate(`/support/${res.id}`)
        form.reset(defaultValues)
        setFiles([])
        onClose()
      })
      .catch((err) => {
        if (err.errors) {
          setFormErrors(err.errors)
        } else {
          console.log(err)
        }
      })
      .finally(() => setLoading(false))
  }

  useMount(() => {
    form.watch()
  })

  useEffect(() => {
    form.setValue(
      'files',
      files.map((el) => el.loadName),
    )
    form.trigger('files')
  }, [files])

  return (
    <Modal isOpen={isOpen} onClose={handleClose} additionalClassName={'modal_smd'}>
      <a href="#" className="modal-close btn-modal-close" title={t('Закрыть')} onClick={handleClose}>
        {t('Закрыть')}
      </a>
      <div className="modal-content">
        <div className="modal-body">
          <div className="ticket-form">
            <div className="ticket-form--header">
              <div className="ticket-form--title">{t('Создать новое обращение')}</div>
            </div>

            <div className="ticket-form--content">
              <FormField error={getFieldError('subject')}>
                <label htmlFor={'subject'} className="control-label">
                  {t('Тема обращения')}
                </label>
                <div className="form-field">
                  <Input
                    register={form.register}
                    name={'subject'}
                    placeholder={t('Укажите тему обращения')}
                    maxLength={50}
                  />
                  <div className="form-field--option">
                    {form.getValues('subject').trim()?.length}/{SUBJECT_MAX_LENGTH}
                  </div>
                </div>
              </FormField>

              <FormField error={getFieldError('message')}>
                <Input label={t('Описание вопроса')} type={'textarea'} register={form.register} name={'message'} />
              </FormField>

              <FormField classes={['upload-group']} error={getFieldError('files')}>
                <div className={`upload-group--list ${previewLoading ? 'loading' : ''}`}>
                  <ul className="upload-doc-list">
                    {files.map((file, idx) => {
                      const isPdf = file.type === 'application/pdf'

                      if (isPdf) {
                        return (
                          <li className="upload-doc-list--item" key={idx}>
                            <div className="upload-doc-list--filename">{file.name}</div>
                            <a
                              href="#"
                              className="upload-doc-list--remove"
                              title="Удалить"
                              onClick={(e) => handleRemoveFile(e, idx)}
                            >
                              Удалить
                            </a>
                          </li>
                        )
                      }

                      return null
                    })}
                  </ul>
                </div>
                <div className="upload-group--list">
                  <ul className="upload-img-list">
                    {files.map((file, idx) => {
                      const isPdf = file.type === 'application/pdf'

                      if (!isPdf) {
                        return (
                          <li className="upload-img-list--item" key={idx}>
                            <img src={file.uri} alt={file.name} />
                            <a
                              href="#"
                              className="upload-img-list--remove"
                              title="Удалить"
                              onClick={(e) => handleRemoveFile(e, idx)}
                            >
                              Удалить
                            </a>
                          </li>
                        )
                      }

                      return null
                    })}
                  </ul>
                </div>

                <div className="upload-group--buttons">
                  <div className="simple-upload">
                    <label htmlFor="fl_df">
                      <input
                        type="file"
                        id="fl_df"
                        onChange={handleAddFile}
                        disabled={!canUpload}
                        accept={ACCEPTED_TYPES.join(',')}
                      />
                      <span className="simple-upload--text">
                        <span>{t('Добавить вложение')}</span>
                      </span>
                    </label>
                  </div>
                </div>

                <div className="upload-group--description">
                  <div>{t('Максимальный размер одного файла: {{value}} Мб', { value: 10 })}</div>
                  <div>{t('Допустимые форматы')}: jpg, jpeg, png, pdf</div>
                </div>
              </FormField>
            </div>

            <div className="ticket-form--buttons">
              <Button variant={'primary'} onClick={handleSubmit} disabled={!form.formState.isValid} loading={loading}>
                <span>{t('Отправить')}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default NewMessageModal
