import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Footer = () => {
  const { t } = useTranslation()

  return (
    <div id="footer" className="footer">
      <div className="footer-content">
        <div className="footer-top">
          <div className="footer-brand">
            <Link to="/" className="footer-logo">
              InfluenceClub
            </Link>
          </div>

          <div className="footer-menu">
            <ul className="footer-menu-list">
              <div className="footer-contacts">
                <ul className="footer-contacts-links">
                  <li className="footer-contacts-links--item">
                    <a href="https://t.me/CoinRealtor" target="_blank">
                      <svg style={{ width: '2.9rem', height: '2.4rem' }}>
                        <use xlinkHref="#social-telegram" />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </ul>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="footer-service">
            <ul className="footer-service-links">
              <li className="footer-service-links--item">
                <a disabled>{t('Политика конфидициальности')}</a>
              </li>
              <li className="footer-service-links--item">
                <a disabled>{t('Соглашение о покупке токенов')}</a>
              </li>
              <li className="footer-service-links--item">
                <a disabled>{t('Отказ от ответственности')}</a>
              </li>
            </ul>

            <div className="footer-copyright">© Copyright 2022</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
