import { get } from './fetch'

export const getAmbassadorStatus = () => {
  return get(`ambassador/status`, true)
}

export const getAmbassadorStats = (country, period, start = '', end = '') => {
  return get(`ambassador/stats?country=${country}&period=${period}&start=${start}&end=${end}`, true)
}
export const getAmbassadorHistory = (page = 1, size = 10, country, period, start = '', end = '') => {
  return get(
    `ambassador/history?page=${page}&size=${size}&country=${country}&period=${period}&start=${start}&end=${end}`,
    true,
  )
}

export const getChartTurnovers = (country, period, start = '', end = '') => {
  return get(`ambassador/charts/turnovers?country=${country}&period=${period}&start=${start}&end=${end}`, true)
}
export const getChartParticipants = (country, period, start = '', end = '') => {
  return get(`ambassador/charts/participants?country=${country}&period=${period}&start=${start}&end=${end}`, true)
}

export const getChartBonuses = (country, period, start = '', end = '') => {
  return get(`ambassador/charts/bonuses?country=${country}&period=${period}&start=${start}&end=${end}`, true)
}
