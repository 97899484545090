import create from 'zustand'

const useStore = create((set) => ({
  currentTicket: {},
  ticketMessages: { data: [], page: 1, count: 0, loading: false },
  list: { data: [], page: 1, count: 0, loading: false },
  setTicketsList: (data) => set((state) => ({ list: { ...state.list, ...data } })),
  setCurrentTicket: (data) => set((state) => ({ currentTicket: { ...state.currentTicket, ...data } })),
  setTicketMessages: (data) => set((state) => ({ ticketMessages: { ...state.ticketMessages, ...data } })),
}))

export default useStore
