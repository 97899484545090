import get from 'lodash/get'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CURRENCY_CR, CURRENCY_DCGT, CURRENCY_USCR } from 'shared/constants'

import useMedia from 'shared/hooks/useMedia'
import useRankData from 'shared/hooks/useRankData'

import useLevelStatsDataStore from 'shared/stores/levelStatsData'

import { getCurrencyAlias } from 'shared/utils/billing'
import { buildLevelStatsData, buildLevelStatsDataObj } from 'shared/utils/levelStats'
import { asMoney, asNumber } from 'shared/utils/money'
import { declOfNum } from 'shared/utils/ui'

import DoughnutChart from 'shared/components/DoughnutChart'

import ChartStats from './components/ChartStats'
import FilterHeader from './components/FilterHeader'
import { VIEW_TYPE_TABLE } from './constants'
import useLevelsStore from './stores/levels'

const LevelsStats = () => {
  const { t } = useTranslation()
  const { isMobile } = useMedia()
  const { userRanks, totalStats } = useLevelStatsDataStore()
  const { fetchUserRanks, fetchTotalStats } = useRankData()
  const { currentView, filters } = useLevelsStore()
  const [openedRanks, setOpenedRanks] = useState([])

  const totalCR = totalStats.data.personalBonus.find((el) => el.currency === CURRENCY_CR) || {
    value: 0,
    currency: CURRENCY_CR,
  }
  const totalUSCR = totalStats.data.personalBonus.find((el) => el.currency === CURRENCY_USCR) || {
    value: 0,
    currency: CURRENCY_CR,
  }
  const ranksData = useMemo(() => buildLevelStatsData(userRanks), [userRanks])
  const totalClients =
    get(totalStats.data, 'personalParticipants.active', 0) + get(totalStats.data, 'personalParticipants.passive', 0)
  const objRanksData = useMemo(() => buildLevelStatsDataObj(userRanks), [userRanks])

  const handleToggleRank = (rank, isOpen) => {
    const tmpOpenedRanks = [...openedRanks]

    if (!isMobile) {
      return false
    }
    if (isOpen) {
      const rankIdx = tmpOpenedRanks.findIndex((el) => el === rank)

      tmpOpenedRanks.splice(rankIdx, 1)
    } else {
      tmpOpenedRanks.push(rank)
    }

    setOpenedRanks(tmpOpenedRanks)
  }

  useEffect(() => {
    fetchUserRanks(filters.period, filters.start, filters.end)
    fetchTotalStats(8, filters.period, filters.start, filters.end)
  }, [JSON.stringify(filters)])

  return (
    <div className="agent-profit-stats">
      <FilterHeader />
      {currentView === VIEW_TYPE_TABLE ? (
        <>
          <div className="agent-profit-stats--chart">
            <ul className="agent-profit-charts-list">
              <li className="agent-profit-charts-list--item">
                <DoughnutChart
                  stats={userRanks}
                  total={totalStats?.data.totalTurnover}
                  classes={'level-'}
                  label={t('Объем продаж')}
                  valueKey={'totalTurnover.value'}
                />
              </li>
              <li className="agent-profit-charts-list--item">
                <DoughnutChart
                  stats={userRanks}
                  total={totalClients}
                  classes={'level-'}
                  label={declOfNum(totalClients, [t('Клиент'), t('клиента'), t('Клиентов')])}
                  valueKey={'participants'}
                />
              </li>
              <li className="agent-profit-charts-list--item">
                <DoughnutChart
                  stats={objRanksData}
                  total={totalUSCR.value}
                  currency={getCurrencyAlias(totalUSCR.currency)}
                  showTotal
                  classes={'level-'}
                  valueKey={`bonus[${CURRENCY_USCR}].value`}
                />
              </li>
              <li className="agent-profit-charts-list--item">
                <DoughnutChart
                  stats={objRanksData}
                  total={totalCR.value}
                  currency={totalCR.currency}
                  showTotal
                  classes={'level-'}
                  valueKey={`bonus[${CURRENCY_CR}].value`}
                />
              </li>
            </ul>
          </div>

          <div className="agent-profit-stats--content">
            <div className="table-wrapper">
              <table className="data-table">
                <thead>
                  <tr>
                    <th>{t('Уровень офиса')}</th>
                    <th>{t('Клиентов всего')}</th>
                    <th>{t('Активные клиенты')}</th>
                    <th>{t('Объем продаж {{token}}', { token: CURRENCY_DCGT })}</th>
                    <th>{t('Объем стейка CR')}</th>
                    <th>{t('Вознаграждение {{token}}', { token: getCurrencyAlias(CURRENCY_USCR) })}</th>
                    <th>{t('Вознаграждение {{token}}', { token: CURRENCY_CR })}</th>
                  </tr>
                </thead>
                <tbody>
                  {ranksData.map((rank) => {
                    const isOpened = !!openedRanks.find((el) => el === rank.rankNumber)

                    return (
                      <tr
                        key={rank.rankNumber}
                        className={`tr-collapsed ${isOpened ? 'open' : ''}`}
                        onClick={() => handleToggleRank(rank.rankNumber, isOpened)}
                      >
                        <td className="level-td tr-collapsed--button" data-subtitle={t('Уровень офиса')}>
                          <div className="level-wrapper">
                            <i className={`level-icon level${rank.rankNumber}`} />
                            <span>{rank.rankNumber}</span>
                          </div>
                        </td>
                        <td className="value-td" data-title={t('Клиентов всего')}>
                          {rank.participants}
                        </td>
                        <td className="value-td" data-title={t('Активные клиенты')}>
                          {rank.activeParticipants}
                        </td>
                        <td className="value-td" data-title={t('Объем продаж')}>
                          {asMoney(rank.turnover[CURRENCY_USCR].value, rank.turnover[CURRENCY_USCR].currency, 2)}
                        </td>
                        <td className="value-td" data-title={t('Объем продаж')}>
                          {asMoney(rank.turnover[CURRENCY_CR].value, rank.turnover[CURRENCY_CR].currency, 2)}
                        </td>
                        {/* <td className="percent-td" data-title={t('Агентский процент')}> */}
                        {/*  {rank.percent}% */}
                        {/* </td> */}
                        <td className="profit-td" data-title={t('Вознаграждение')}>
                          {asMoney(rank.bonus[CURRENCY_USCR].value, rank.bonus[CURRENCY_USCR].currency, 2)}
                        </td>
                        <td className="profit-td" data-title={t('Вознаграждение')}>
                          {asMoney(rank.bonus[CURRENCY_CR].value, rank.bonus[CURRENCY_CR].currency, 2)}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <ChartStats totalStats={totalStats.data} filters={filters} />
      )}
    </div>
  )
}

export default LevelsStats
