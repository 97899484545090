import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useMount } from 'react-use'

import useUiStore from 'shared/stores/ui'
import useUserStore from 'shared/stores/user'

import Footer from './Footer'
import Header from './Header'
import ScrollToTop from './ScrollToTop'

const Main = ({ children }) => {
  const user = useUserStore()
  const ui = useUiStore()
  const theme = ui?.theme ? ui.theme.split('-')[0] : null

  useMount(() => {
    user.receiveProfile()
  })

  return (
    <div className={`main ${ui.openMenu ? 'main-nav-open' : ''}`}>
      <ScrollToTop />
      <Header />
      <ToastContainer position="top-right" newestOnTop limit={10} theme={theme} toastClassName={'cr-toaster'} />

      <div className="main_parent">
        <div className="main_content">{children}</div>
        <Footer />
      </div>
    </div>
  )
}

export default Main
