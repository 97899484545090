import create from 'zustand'

import { THEME_LOCALSTORAGE_KEY } from '../constants'

const useStore = create((set) => ({
  openMenu: true,
  openUserMenu: false,
  theme: localStorage.getItem(THEME_LOCALSTORAGE_KEY),
  toggleMenu: () => set((state) => ({ openMenu: !state.openMenu })),
  toggleTheme: (data) => set(() => ({ theme: data })),
  toggleUserMenu: () => set((state) => ({ openUserMenu: !state.openUserMenu })),
}))

export default useStore
