import { last } from 'lodash/array'

import { USER_RANK_LABELS, WALLET_TYPE_USCR } from '../constants'

const getBalance = (user, type = WALLET_TYPE_USCR) => {
  return user.balances[type] !== undefined ? user.balances[type].balance : 0
}

const getFullName = (user) => {
  return [user.profile.firstName, user.profile.lastName].filter(Boolean).join(' ')
}

const getAvatar = (user) => {
  return user.profile.avatar || '/images/default-avatar.svg'
}

const getReferrer = (user) => {
  return user.referrer.login ? user.referrer : null
}

const isGuest = (user) => {
  return user.accessToken === null
}

const getUserRankLabel = (rank) => {
  if (rank === null) {
    return 'Новичок'
  }

  return USER_RANK_LABELS[rank]
}

const getRankNum = (rank) => {
  if (!rank) {
    return null
  }
  return rank.split('-')[1]
}

const isMaxRank = (rank) => {
  const lastRank = last(Object.keys(USER_RANK_LABELS))

  return rank === lastRank
}

const getTelegramLink = (user) => {
  if (user?.system) {
    return 'tg://resolve?domain=Coin_Realtor'
  }

  return `tg://resolve?domain=${user.login}`
}

const getStatementLink = (lang = 'en') => {
  const versions = ['en', 'ru']

  if (!versions.includes(lang)) {
    return `/statement/state-en.pdf`
  }

  return `/statement/state-${lang}.pdf`
}

export {
  getBalance,
  getFullName,
  getAvatar,
  getReferrer,
  isGuest,
  getUserRankLabel,
  getRankNum,
  getTelegramLink,
  isMaxRank,
  getStatementLink,
}
