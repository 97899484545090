import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { loadFilesPreview } from '../api/support'

const MAX_FILE_UPLOAD = 10

const MAX_FILE_SIZE_UPLOAD = MAX_FILE_UPLOAD * 1024 * 1024
const ACCEPTED_TYPES = ['image/jpeg', 'image/png', 'application/pdf']
const useLoadPreviews = ({ setError }) => {
  const { t } = useTranslation()
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const canUpload = files.length < MAX_FILE_UPLOAD

  const handleAddFile = (ev) => {
    if (!ev.target.files) {
      return
    }

    if (!canUpload) {
      return
    }

    const file = ev.target.files[0]

    if (file.size > MAX_FILE_SIZE_UPLOAD) {
      setError({ files: [t(`Превышен максимальный размер файла {{name}}`, { name: file.name })] })

      return false
    }

    if (!ACCEPTED_TYPES.includes(file.type)) {
      setError({ files: [t(`Недопустимый формат файла {{name}}`, { name: file.name })] })
      return false
    }
    setError({ files: [] })

    loadPreview(file)
  }

  const loadPreview = (data) => {
    const formData = new FormData()

    setLoading(true)
    formData.append('files[]', data)

    loadFilesPreview(formData)
      .then((res) => {
        if (!res.success) {
          setError(res.data)
        } else {
          const filePreview = res.data[0]

          setFiles([...files, { type: data.type, ...filePreview, name: data.name, loadName: filePreview.name }])
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => setLoading(false))
  }
  const handleRemoveFile = (ev, key) => {
    ev.preventDefault()

    setFiles(files.filter((_, index) => index !== parseInt(key)))
  }

  return {
    files,
    canUpload,
    handleRemoveFile,
    handleAddFile,
    previewLoading: loading,
    setFiles,
  }
}

export default useLoadPreviews
