import create from 'zustand'

const USER_RANKS = {
  1: {
    rank: 'agent-1',
    percent: 5,
    turnovers: [],
    bonuses: [],
    participants: 0,
    activeParticipants: 0,
  },
  2: {
    rank: 'agent-2',
    percent: 4,
    turnovers: [],

    bonuses: [],
    participants: 0,
    activeParticipants: 0,
  },
  3: {
    rank: 'agent-3',
    percent: 3,
    turnovers: [],

    bonuses: [],
    participants: 0,
    activeParticipants: 0,
  },
  4: {
    rank: 'agent-4',
    percent: 3,
    turnovers: [],

    bonuses: [],
    participants: 0,
    activeParticipants: 0,
  },
  5: {
    rank: 'agent-5',
    percent: 2,
    turnovers: [],

    bonuses: [],
    participants: 0,
    activeParticipants: 0,
  },
  6: {
    rank: 'agent-6',
    percent: 1,
    turnovers: [],

    bonuses: [],
    participants: 0,
    activeParticipants: 0,
  },
  7: {
    rank: 'agent-7',
    percent: 1,
    turnovers: [],
    bonuses: [],
    participants: 0,
    activeParticipants: 0,
  },
  8: {
    rank: 'agent-8',
    percent: 1,
    turnovers: [],
    bonuses: [],
    participants: 0,
    activeParticipants: 0,
  },
}

const defaultState = {
  userRanks: USER_RANKS,
  totalStats: {
    data: { personalBonus: [], personalParticipants: { active: 0, passive: 0 }, totalTurnover: 0 },
    loading: false,
  },
}
const useStore = create((set) => ({
  ...defaultState,
  setUserRanks: (data) => set(() => ({ userRanks: data })),
  setTotalStats: (data) => set((state) => ({ totalStats: { ...state.totalStats, ...data } })),
}))

export default useStore
