import useMount from 'react-use/lib/useMount'
import useSetState from 'react-use/lib/useSetState'

import { getCountries, getStates } from 'shared/api/settings'

const useSettings = () => {
  const [settings, setSettings] = useSetState({
    countries: {},
    states: {},
  })

  useMount(() => {
    getCountries()
      .then((data) => setSettings({ countries: data }))
      .catch((err) => console.log(err))
  })

  const fetchStates = (country) => {
    return getStates(country)
      .then((res) => {
        setSettings({ states: res })

        return res
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return {
    countries: settings.countries,
    states: settings.states,
    fetchStates,
  }
}

export default useSettings
