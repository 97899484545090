import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import useModalsStore from 'shared/stores/modals'

import { getNowDate } from 'shared/utils/date'
import { asMoney, asUSCR } from 'shared/utils/money'

import Button from 'shared/components/Button'

import useStepsStore from '../stores/steps'

const Confirmation = () => {
  const { t } = useTranslation()
  const modals = useModalsStore()
  const steps = useStepsStore()
  const stepData = steps.composeDataSteps()

  const handleClose = () => {
    modals.hide('withdrawal')
    steps.resetSteps()
  }

  return (
    <>
      <div className="finance-form--info">
        <div className="action-message action-message_success">
          <div className="action-message--content">
            <div className="action-message--title">{t('Заявка успешно создана!')}</div>
            <div className="action-message--description">
              {t('Ваша заявка поставлена в очередь на вывод.')}
              <br />
              {t('Обычно это занимает от 10 секунд до 72 часов.')}
              <br />
              <Trans
                i18nKey={'Статус выполнения заявки можно посмотреть в разделе <a>Финансы</a>'}
                components={{
                  a: <Link to={'/finances'} onClick={() => handleClose()} />,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="finance-form--info">
        <div className="transaction-final-info">
          <div className="transaction-final-info--item">
            <div className="transaction-final-info--title">{t('Сумма вывода')}:</div>
            <div className="transaction-final-info--value">{asUSCR(stepData.amount)}</div>
          </div>
          <div className="transaction-final-info--item">
            <div className="transaction-final-info--title">{t('Сумма к получению')}:</div>
            <div className="transaction-final-info--value">{asMoney(stepData.currencyAmount, stepData.toCurrency)}</div>
          </div>
          <div className="transaction-final-info--item">
            <div className="transaction-final-info--title">{t('Адрес кошелька')}:</div>
            <div className="transaction-final-info--value" style={{ wordBreak: 'break-all' }}>
              {stepData.account}
            </div>
          </div>
          <div className="transaction-final-info--item">
            <div className="transaction-final-info--title">{t('Дата операции')}:</div>
            <div className="transaction-final-info--value">{getNowDate()}</div>
          </div>
        </div>
      </div>

      <div className="finance-form--buttons">
        <Button variant={'primary'} classes={['btn-lg', 'btn_sqr', 'btn-modal-close']} onClick={handleClose}>
          <span>{t('Закрыть')}</span>
        </Button>
      </div>
    </>
  )
}

export default Confirmation
