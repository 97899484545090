import { useState } from 'react'

import { createSession } from 'shared/api/auth/telegram-passport'

import { LOGIN_SOURCE, TELEGRAM_BOT_ID } from '../constants'

const useTelegramLogin = (language = null, callbackUrl, onTooltipShow, onTooltipHide) => {
  const [loading, setLoading] = useState(false)

  return {
    loading,
    login: async () => {
      if (loading) {
        return
      }

      setLoading(true)

      const { ssid, nonce } = await createSession({ language, source: LOGIN_SOURCE })

      const params = {
        bot_id: TELEGRAM_BOT_ID,
        scope: {
          data: ['phone_number', 'email', { type: 'personal_details', first_name: true, last_name: true }],
          v: 1,
        },
        public_key:
          '-----BEGIN PUBLIC KEY-----\n' +
          'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA1fR9GdYNrQcXgX4NEDLe\n' +
          'rWz7Zy/Lz/jPUTojezZdZhu3FL9cMB12WLrjjhqclK1375pPQIebYyaoyOeDn1ZP\n' +
          '2oRSrGYtnBAluMvlj4vaYxJv9hZhhw4REox9/FyPwSQwkcLMJjJmM4G814kNyfOt\n' +
          'J1YqLqVpzlYE8jWPgM7zJggRK7kmGi4YmYCkkWLeCURXZxxiwduVo8gE6J/OA+/M\n' +
          'NLjEiZKVC3s7qpBNj28SLsQMET82ito6vr+hV2XK7cDuBVySqOKul8SXLv5jqWCV\n' +
          'ddIiTGqOv0Dx2ML0l3q1HUzpB6T0Kni5kQ2pLjMNYydyQDIxBYGfylNTj2LYluXY\n' +
          'QQIDAQAB\n' +
          '-----END PUBLIC KEY-----\n',
        nonce: nonce,
        callback_url: `${callbackUrl}?ssid=${ssid}`,
      }

      // eslint-disable-next-line no-undef
      Telegram.Passport.auth(params, function (show) {
        if (show) {
          onTooltipShow && onTooltipShow()
        } else {
          onTooltipHide && onTooltipHide()
        }
      })

      setLoading(false)

      return ssid
    },
  }
}

export default useTelegramLogin
