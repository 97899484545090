import React from 'react'

const Input = ({
  register = () => {},
  name,
  type = 'text',
  label = null,
  disabled = false,
  classes = [],
  options = {
    cols: 30,
    rows: 10,
  },
  ...rest
}) => {
  return (
    <>
      {label && (
        <label htmlFor={name} className="control-label">
          {label}
        </label>
      )}
      {type === 'textarea' ? (
        <textarea {...register(name)} {...rest} {...options} id={name} className="form-control" />
      ) : (
        <input
          {...register(name)}
          {...rest}
          type={type}
          className={`form-control ${classes.join(' ')}`}
          id={name}
          disabled={disabled}
        />
      )}
    </>
  )
}

export default Input
