import { LOGIN_SOURCE } from '../constants'
import { get, post } from './fetch'

export const finanseStats = () => {
  return get(`finance/stats`, true)
}

export const getFinanceTransactions = (page = 1, size = 10) => {
  return get(`finance/transactions?page=${page}&size=${size}`, true)
}
export const getFinanceWithdrawals = (page = 1, size = 10) => {
  return get(`finance/withdrawal/history?page=${page}&size=${size}`, true)
}

// transfer

export const requestConfirmation = () => {
  return post(`finance/transfer/request-confirmation?source=${LOGIN_SOURCE}`, null, true)
}
export const transferMoney = (payload) => {
  return post(`finance/transfer?source=${LOGIN_SOURCE}`, payload, true)
}

export const validateTransfer = (payload) => {
  return post('finance/transfer-validation', payload, true)
}

//  withdrawal

export const validateWithdrawal = (payload) => {
  return post('finance/withdrawal/withdraw-validation', payload, true)
}
export const requestWithdrawalConfirmation = () => {
  return post(`finance/withdrawal/request-confirmation?source=${LOGIN_SOURCE}`, null, true)
}

export const withdrawalMoney = (payload) => {
  return post(`finance/withdrawal/withdraw?source=${LOGIN_SOURCE}`, payload, true)
}

export const calculateWithdrawalTotal = (payload) => {
  return post('finance/withdrawal/withdraw-calculation', payload, true)
}
