import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Link, useLocation } from 'react-router-dom'
import useMount from 'react-use/lib/useMount'
import useUnmount from 'react-use/lib/useUnmount'

import Breadcrumbs from 'components/Breadcrumbs'

import NewMessageModal from './components/NewMessageModal'
import TicketsList from './components/TicketsList'

const Support = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const { state } = useLocation()
  const navigate = useNavigate()
  const showModal = state?.showModal

  const handleOpenModal = () => {
    setIsOpen(true)
  }

  const handleCloseModal = () => {
    setIsOpen(false)
    clearRouteState()
  }

  const clearRouteState = () => {
    navigate('', {})
  }

  useMount(() => {
    if (showModal) handleOpenModal()
  })

  return (
    <div className="page page_finance">
      <div className="page--header">
        <Breadcrumbs />
      </div>

      <div className="page--header-container">
        <div className="page--title">{t('Техническая поддержка')}</div>

        <div className="page--header-controls">
          <button className="btn btn_dark btn_gt btn-modal" onClick={handleOpenModal}>
            {t('написать новое обращение')}
          </button>
        </div>
      </div>

      <div className="page--content">
        <TicketsList />
      </div>

      <NewMessageModal isOpen={isOpen} onClose={handleCloseModal} />
    </div>
  )
}

export default Support
