import { isArray } from 'lodash'
import get from 'lodash/get'
import React from 'react'
import { CURRENCY_BASE } from 'shared/constants'

import { asMoney, asNumber } from 'shared/utils/money'

const DoughnutChart = ({
  stats = {},
  total = 0,
  sumTotal = null,
  classes,
  showTotal = false,
  currency = CURRENCY_BASE,
  valueKey,
  label,
  totalClassName = 'chart-wrapper--value',
  wrapperCls = ['agent-profit-stats--chart', 'chart-wrapper', 'chart-wrapper--box'],
}) => {
  const filledCirclesCount = Object.values(stats).filter((el) => (valueKey ? get(el, valueKey) > 0 : el > 0)).length
  const add = 1 / filledCirclesCount

  let percentSum = 0
  const finalTotal = isArray(total) ? sumTotal : total
  const defaultData = Object.keys(stats).map((type) => {
    const percent = parseInt(finalTotal) === 0 && Object.keys(stats).length === 1 ? 100 : 20
    const result = {
      percent,
      className: typeof classes === 'string' ? `${classes}${type}` : classes[type],
      rotate: percentSum,
      rank: type,
    }

    percentSum += percent / 100

    return result
  })

  const data = Object.keys(stats)
    .filter((type) => type !== 'totalBonus' && (valueKey ? get(stats, `${type}.${valueKey}`) > 0 : stats[type]))
    .map((type) => {
      const amount = valueKey ? get(stats[type], valueKey) : stats[type]
      const percent = parseInt(finalTotal) === 0 ? 100 : parseFloat(((amount * 100) / Number(finalTotal)).toFixed(2))

      const result = {
        percent,
        className: typeof classes === 'string' ? `${classes}${type}` : classes[type],
        rotate: percentSum,
        rank: type,
      }

      percentSum += percent / 100

      return result
    })

  return (
    <div className={wrapperCls[0]}>
      <div className={wrapperCls[1]}>
        <div className={wrapperCls[2]}>
          <svg viewBox="0 0 35 35" className="agent-profit-chart">
            <defs>
              <linearGradient id="lev1" x1="0" y1="0" x2="0" y2="100%" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FA7581" />
                <stop offset="1" stopColor="#FF9696" />
              </linearGradient>
              <linearGradient id="lev2" x1="0" y1="0" x2="0" y2="100%" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFB7B9" />
                <stop offset="1" stopColor="#FFAEE3" />
              </linearGradient>
              <linearGradient id="lev3" x1="0" y1="0" x2="0" y2="100%" gradientUnits="userSpaceOnUse">
                <stop stopColor="#3D44F2" />
                <stop offset="1" stopColor="#96E6FF" />
              </linearGradient>
              <linearGradient id="lev4" x1="0" y1="0" x2="0" y2="100%" gradientUnits="userSpaceOnUse">
                <stop stopColor="#823DF2" />
                <stop offset="1" stopColor="#969AFF" />
              </linearGradient>
              <linearGradient id="lev5" x1="0" y1="0" x2="0" y2="100%" gradientUnits="userSpaceOnUse">
                <stop stopColor="#D93DF2" />
                <stop offset="1" stopColor="#E496FF" />
              </linearGradient>
              <linearGradient id="lev6" x1="0" y1="0" x2="0" y2="100%" gradientUnits="userSpaceOnUse">
                <stop stopColor="#B349C7" />
                <stop offset="1" stopColor="#963EF6" />
              </linearGradient>
              <linearGradient id="lev7" x1="0" y1="0" x2="0" y2="100%" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFA1A5" />
                <stop offset="1" stopColor="#FFC6B4" />
              </linearGradient>
              <linearGradient id="lev8" x1="0" y1="0" x2="0" y2="100%" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FA6E7F" />
                <stop offset="1" stopColor="#FF3D00" />
              </linearGradient>
            </defs>
            {(finalTotal > 0 ? data : defaultData).map((part, key) => {
              return (
                <circle
                  key={key}
                  className={part.className}
                  stroke={`url(#lev${part.rank})`}
                  cx="50%"
                  cy="50%"
                  r="16"
                  strokeDasharray={`${part.percent + add} 100`}
                  style={{ transform: `rotate(calc(360deg * ${part.rotate}))` }}
                />
              )
            })}
          </svg>

          {showTotal && (
            <div className={totalClassName}>
              {isArray(total) ? (
                <>
                  {total.map((el) => (
                    <span key={el.currency}>{asMoney(el.value, el.currency, 2)}</span>
                  ))}
                </>
              ) : (
                <>
                  <span>{asNumber(total, 2)}</span> {currency}
                </>
              )}
            </div>
          )}
          {label && (
            <div className={totalClassName}>
              <span>{asNumber(total, 2)}</span> {label}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DoughnutChart
