import React from 'react'

const FormField = ({ error, children, classes = [] }) => {
  return (
    <div className={`form-group ${error ? 'has-error' : ''} ${classes.join(' ')}`}>
      {children}
      <div className={`${error ? 'feedback-message' : ''} `}>{error}</div>
    </div>
  )
}

export default FormField
