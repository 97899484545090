import { get, post } from '../fetch'

export const refresh = (payload) => {
  return post(`auth/refresh`, payload)
}

export const getReferrer = (key) => {
  return get(`auth/referrer?key=${key}`)
}

export const loginByKey = (key) => {
  return post(`auth/login/key`, { key })
}

export const confirmInvite = (key) => {
  return get(`auth/activation/${key}`)
}
