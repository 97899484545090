import Chartist from 'chartist'
// eslint-disable-next-line
import * as Gradient from 'chartist-plugin-gradient'
// eslint-disable-next-line
import * as Tooltip from 'chartist-plugin-tooltips-updated'
import get from 'lodash/get'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSetState from 'react-use/lib/useSetState'
import { PERIOD_ALL, PERIOD_CUSTOM, PERIOD_YEAR } from 'shared/constants'

import useUserStore from 'shared/stores/user'

import { parseAndFormat } from 'shared/utils/date'
import { asMoney, asNumber } from 'shared/utils/money'

import { getChartBonuses, getChartParticipants, getChartTurnovers } from 'shared/api/ambassador'

function createFilialChart(type = 'turnover', currentRef, data, valueString = '') {
  if (currentRef.current) {
    const min = Math.min(...data.series[0], ...data.series[1])
    const finalData = type !== 'turnover' ? data : { labels: data.labels, series: [[], [], ...data.series] }
    const chart = new Chartist.Line(currentRef.current, finalData, {
      low: min,
      showArea: false,
      showPoint: true,
      axisX: {
        showGrid: false,
        showLabel: true,
      },
      axisY: {
        showGrid: true,
        showLabel: true,
        onlyInteger: true,
      },
      height: '30rem',
      fullWidth: true,
      chartPadding: {
        right: 35,
        left: 0,
      },
      series: {},
      plugins: [
        Chartist.plugins.tooltip({
          // anchorToPoint: true,
          transformTooltipTextFnc: function (value) {
            if (valueString) {
              return value + valueString
            } else {
              return asNumber(value, 2)
            }
          },
        }),
      ],
    })

    chart.on('created', () => {
      const points = document.querySelectorAll('.ct-point')
      const tooltip = document.querySelector('.chartist-tooltip')

      points.forEach((point) => {
        point.addEventListener('mouseover', () => {
          tooltip.className = 'chartist-tooltip'
          tooltip.classList.add('filial-tooltip')
          tooltip.classList.add(point.parentElement.classList[1])
        })
      })
    })
  }
}
const ChartStats = ({ totalStats, filters, country }) => {
  const { t } = useTranslation()
  const user = useUserStore()

  const chartTurnover = useRef()
  const chartParticipants = useRef()
  const [turnover, setTurnover] = useSetState({
    turnover: {},
    bonuses: {},
  })
  const [participants, setParticipants] = useState({})

  useEffect(() => {
    fetchChartTurnovers(country, filters.period, filters.start, filters.end)
    fetchChartParticipants(country, filters.period, filters.start, filters.end)
  }, [JSON.stringify(filters), country])

  useEffect(() => {
    renderTurnoverChart(turnover)
  }, [JSON.stringify(turnover)])

  useEffect(() => {
    renderChart('participants', participants)
  }, [JSON.stringify(participants), user.language])

  const fetchChartTurnovers = (country, period, start, end) => {
    if (period === PERIOD_CUSTOM && (!start || !end)) return false

    const turnoverPromise = getChartTurnovers(country, period, start ?? '', end ?? '')

    const bonusesPromise = getChartBonuses(country, period, start ?? '', end ?? '')

    Promise.all([turnoverPromise, bonusesPromise]).then((v) =>
      setTurnover({
        turnover: v[0],
        bonuses: v[1],
      }),
    )
  }

  const fetchChartParticipants = (country, period, start, end) => {
    if (period === PERIOD_CUSTOM && (!start || !end)) return false

    getChartParticipants(country, period, start ?? '', end ?? '')
      .then((res) => setParticipants(res))
      .catch((e) => console.log(e))
  }

  const renderChart = (type, data, locale = 'ru') => {
    const activeData = data?.active?.data ?? []
    const passiveData = data?.passive?.data ?? []

    const active = activeData.filter((item, key) =>
      filters.period === 'month' ? key === 0 || key === activeData.length - 1 || (key + 1) % 2 === 0 : true,
    )
    const passive = passiveData.filter((item, key) =>
      filters.period === 'month' ? key === 0 || key === passiveData.length - 1 || (key + 1) % 2 === 0 : true,
    )

    const turnoverData = {
      labels: active.map((item) =>
        filters.period === PERIOD_YEAR || filters.period === PERIOD_ALL
          ? parseAndFormat(item.date, 'MMM', undefined, undefined, user.language)
          : parseAndFormat(item.date, 'dd.MM'),
      ),
      series: [
        active.map((item) => {
          if (type === 'turnover') return item.amount.value
          return item.count
        }),
        passive.map((item) => {
          if (type === 'turnover') return item.amount.value
          return item.count
        }),
      ],
    }

    createFilialChart(type, type === 'turnover' ? chartTurnover : chartParticipants, turnoverData)
  }

  const renderTurnoverChart = (data, locale = 'ru') => {
    const turnoversData = data?.turnover?.data ?? []
    const bonusesData = data?.bonuses?.data ?? []

    const turnover = turnoversData.filter((item, key) =>
      filters.period === 'month' ? key === 0 || key === turnoversData.length - 1 || (key + 1) % 2 === 0 : true,
    )
    const bonuses = bonusesData.filter((item, key) =>
      filters.period === 'month' ? key === 0 || key === bonusesData.length - 1 || (key + 1) % 2 === 0 : true,
    )

    const turnoverData = {
      labels: turnover.map((item) =>
        filters.period === PERIOD_YEAR || filters.period === PERIOD_ALL
          ? parseAndFormat(item.date, 'MMM', undefined, undefined, user.language)
          : parseAndFormat(item.date, 'dd.MM'),
      ),
      series: [turnover.map((item) => item.count), bonuses.map((item) => item.count)],
    }

    createFilialChart('turnover', chartTurnover, turnoverData)
  }

  return (
    <div className="filial-charts">
      <div className="filial-charts--item">
        <div className="filial-charts-legend">
          <div className="filial-charts-legend--item">
            <div className="filial-chart-legend filial-chart-legend_a">
              <div className="filial-chart-legend--title">{t('Активные клиенты')}</div>
              <div className="filial-chart-legend--value">{get(totalStats, 'activeParticipants', 0)}</div>
            </div>
          </div>
          <div className="filial-charts-legend--item">
            <div className="filial-chart-legend filial-chart-legend_b">
              <div className="filial-chart-legend--title">{t('Пассивные клиенты')}</div>
              <div className="filial-chart-legend--value">{get(totalStats, 'passiveParticipants', 0)}</div>
            </div>
          </div>
        </div>

        <div className="filial-charts-chart">
          <div className="filial-chart" id="filial-chart1" ref={chartParticipants} />
        </div>
      </div>

      <div className="filial-charts--item">
        <div className="filial-charts-legend">
          <div className="filial-charts-legend--item">
            <div className="filial-chart-legend filial-chart-legend_c">
              <div className="filial-chart-legend--title">{t('Объем продаж')}</div>
              <div className="filial-chart-legend--value">
                {asMoney(get(totalStats, 'totalTurnover.value', 0), get(totalStats, 'totalTurnover.currency', ''), 2)}
              </div>
            </div>
          </div>
          <div className="filial-charts-legend--item">
            <div className="filial-chart-legend filial-chart-legend_d">
              <div className="filial-chart-legend--title">{t('Вознаграждение')}</div>
              <div className="filial-chart-legend--value">
                {asMoney(get(totalStats, 'totalBonus.value', 0), get(totalStats, 'totalBonus.currency', ''), 2)}
              </div>
            </div>
          </div>
        </div>

        <div className="filial-charts-chart">
          <div className="filial-chart" id="filial-chart2" ref={chartTurnover} />
        </div>
      </div>
    </div>
  )
}

export default ChartStats
