import { getTicketById, getTicketMessages, getTickets } from '../api/support'
import useTicketsStore from '../stores/tickets'

const MAX_PAGE_SIZE = 100
const useTicketsData = () => {
  const tickets = useTicketsStore()

  const fetchData = (page, size) => {
    tickets.setTicketsList({ loading: true })
    getTickets(page, size)
      .then((res) => tickets.setTicketsList(res))
      .catch((err) => console.log(err))
      .finally(() => tickets.setTicketsList({ loading: false }))
  }

  const fetchTicketDetail = (id) => {
    getTicketById(id)
      .then((res) => tickets.setCurrentTicket(res))
      .catch((err) => console.log(err))
  }
  const fetchTicketMessages = (id, page = 1, size = MAX_PAGE_SIZE) => {
    tickets.setTicketMessages({ loading: true })
    getTicketMessages(id, page, size)
      .then((res) => tickets.setTicketMessages(res))
      .catch((err) => console.log(err))
      .finally(() => tickets.setTicketMessages({ loading: false }))
  }

  return {
    ticketsList: tickets.list,
    ticketData: tickets.currentTicket,
    ticketMessages: tickets.ticketMessages,
    fetchTicketsData: fetchData,
    fetchTicketDetail,
    fetchTicketMessages,
  }
}

export default useTicketsData
