import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import ReactModal from 'react-modal'

export default ({
  children,
  isOpen = false,
  onClose = () => {},
  withContent = false,
  title = null,
  additionalClassName = '',
  showOverlayButtonClose = false,
}) => {
  ReactModal.setAppElement(document.getElementById('app'))

  let contentRef = useRef()
  const { t } = useTranslation()

  const afterOpenModal = () => {
    const timer = setTimeout(() => {
      if (contentRef?.classList) {
        contentRef.classList.add('open')
      }
      clearTimeout(timer)
    }, 15)
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterOpen={afterOpenModal}
      shouldCloseOnOverlayClick={true}
      overlayClassName="modal-over"
      bodyOpenClassName="modal-open"
      className={{ base: `modal ${additionalClassName}`, afterOpen: 'open', beforeClose: '' }}
      contentRef={(node) => (contentRef = node)}
      appElement={document.getElementById('app')}
    >
      {showOverlayButtonClose && (
        <a href="" className="modal-close btn-modal-close" title={t('Закрыть')} onClick={onClose}>
          {t('Закрыть')}
        </a>
      )}
      <div className="modal-container">
        <div className="modal-dialog">
          {withContent ? (
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title">{title}</div>
                <a href="#" className="modal-close btn-modal-close" title={t('Закрыть')} onClick={onClose}>
                  {t('Закрыть')}
                </a>
              </div>
              <div className="modal-body">{children}</div>
            </div>
          ) : (
            children
          )}
        </div>
      </div>
    </ReactModal>
  )
}
