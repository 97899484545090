import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import useMount from 'react-use/lib/useMount'
import * as yup from 'yup'

import useFormWrapper from 'shared/hooks/useFormWrapper'

import { confirmInvite } from 'shared/api/auth'

const Activate = () => {
  const { t } = useTranslation()
  const { token } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(null)
  const [error, setError] = useState(null)

  const validationSchema = yup.object().shape({
    confirm: yup.bool(),
  })

  const { form } = useFormWrapper(validationSchema, {
    mode: 'all',
    reValidateMode: 'all',
  })

  const isValid = form.getValues('confirm') === true

  useMount(() => {
    form.watch()
  })

  const handleActivate = (e) => {
    e.preventDefault()

    if (token) {
      setLoading(true)
      confirmInvite(token)
        .then((res) => {
          if (res === true) {
            navigate('/auth/login')
          } else {
            setError('Вы уже проходили активацию! Войдите в систему.')
          }
        })
        .catch((err) => {
          console.log(err)
          setError('Доступ запрещен! Если уже вы проходили активацию, то войдите в систему.')
        })
        .finally(() => setLoading(false))
    }
  }

  return (
    <div className="auth auth_activation">
      <div className="auth-title">{t('Активация')}</div>
      <div className="auth-content">
        <div>
          {t('Приглашаем вас принять участие в Influence Club.')}
          <br />
          {t('Это рекламная и PR площадка, для продвижения различных компаний и проектов в интернет-пространстве.')}
          <br />
          {t(
            'В этом клубе за свою деятельность получают повышенные вознаграждения инфлюенсеры, лидеры мнений, блогеры и просто активные личности.',
          )}
        </div>

        <div>
          {t(
            'Если вы готовы участвовать в нашем клубе, получать удовольствие и зарабатывать, то добро пожаловать в Influence Club!',
          )}
        </div>
      </div>
      <div className="auth-options">
        <div className={`form-group ${error ? 'has-error' : ''}`}>
          <div className="checkbox">
            <input {...form.register('confirm')} name={'confirm'} type="checkbox" id="confirm" />
            <label htmlFor="confirm">
              <span className="box" />
              <span className="text">
                <Trans
                  i18nKey={'Я ознакомился и согласен с <a>правилами участия в InfluenceClub</a>'}
                  components={{
                    a: <a href={'#'} />,
                  }}
                />
              </span>
            </label>
          </div>
          {error && (
            <div className={'feedback-message'} style={{ marginTop: '2em' }}>
              {t(error)}
            </div>
          )}
        </div>
      </div>
      <div className="auth-buttons">
        {error ? (
          <Link to="/auth/login" className="btn btn-primary btn-lg btn_sqr">
            <span>{t('Войти')}</span>
          </Link>
        ) : (
          <a
            href="#"
            className={`btn btn-primary btn-lg btn_sqr ${loading ? 'btn-loading' : ''}`}
            disabled={!isValid}
            onClick={handleActivate}
          >
            <span>{t('Активировать')}</span>
          </a>
        )}
      </div>
    </div>
  )
}

export default Activate
