import { isString, trim } from 'lodash'

export const trimValues = (values) => {
  const obj = {}

  Object.keys(values).forEach((key) => {
    const value = values[key]

    if (isString(value)) {
      obj[key] = trim(value)
    } else {
      obj[key] = value
    }
  })

  return obj
}
