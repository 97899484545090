import create from 'zustand'

const defaultState = {
  status: {
    ambassador: false,
    countries: {},
  },
  statusLoading: true,
  stats: {
    totalBonus: {
      value: 0,
      currency: 'USCR',
    },
    totalTurnover: {
      value: 0,
      currency: 'USCR',
    },
    activeParticipants: 0,
    passiveParticipants: 0,
    countries: [],
  },
  history: {
    data: [],
    page: 1,
    size: 10,
    count: 0,
  },
}
const useStore = create((set) => ({
  ...defaultState,
  setStatus: (data) => set(() => ({ status: data })),
  setStatusLoading: (data) => set(() => ({ statusLoading: data })),
  setStats: (data) => set(() => ({ stats: data })),
  setHistory: (data) => set(() => ({ history: data })),
}))

export default useStore
