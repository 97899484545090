import { post } from '../fetch'

export const identity = (payload) => {
  return post(`auth/telegram-login/identity`, payload)
}

export const login = (payload, validation = false) => {
  return post(`auth/telegram-login/login?validation=${Number(validation)}`, payload)
}

export const requestConfirmation = (payload) => {
  return post(`auth/telegram-login/request-confirmation`, payload)
}
