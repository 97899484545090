import { sortBy } from 'lodash/collection'
import merge from 'lodash/merge'
import React, { useEffect, useMemo, useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { Trans, useTranslation } from 'react-i18next'
import useMount from 'react-use/lib/useMount'
import useSetState from 'react-use/lib/useSetState'
import { VIEW_TYPE_TABLE } from 'shared/constants'
import * as yup from 'yup'

import useAmbassador from 'shared/hooks/useAmbassador'
import useFormWrapper from 'shared/hooks/useFormWrapper'
import useSettings from 'shared/hooks/useSettings'

import { parseAndFormat } from 'shared/utils/date'
import { asMoney, asNumber } from 'shared/utils/money'
import { countryFlag } from 'shared/utils/ui'
import { getAvatar, getFullName } from 'shared/utils/user'

import Pagination from 'shared/components/Pagination'
import PeriodSelector from 'shared/components/PeriodSelector'
import Dropdown from 'shared/components/forms/Dropdown'

import ParticipantModal from '../../Affiliate/components/ParticipantModal'
import ChartStats from './ChartStats'

const validationSchema = yup.object().shape({
  country: yup
    .string()
    .trim()
    .required(<Trans i18nKey={'Обязательное поле'} />),
})

const MAX_PAGE_SIZE = 10

const STATUS_LABELS = {
  waiting: 'К начислению',
  processed: 'Начислено',
}

const STATUS_COLORS = {
  waiting: 'passive',
  processed: 'success',
}

const History = () => {
  const { i18n, t } = useTranslation()
  const language = i18n.language
  const { countries = [] } = useSettings()
  const [filters, setFilters] = useState({})
  const { fetchStats, stats, history, fetchHistory, status, currentView } = useAmbassador()
  const [modal, toggleModal] = useSetState({
    open: false,
    participant: null,
  })

  const { form } = useFormWrapper(validationSchema, {
    mode: 'all',
    defaultValues: {
      country: 'all',
    },
  })
  const country = form.getValues('country') === 'all' ? '' : form.getValues('country').toLowerCase()
  const statsCountries = stats.countries.map((item) => item.country.toUpperCase())
  const statusCountries = status.countries.map((item) => item.country.toUpperCase())
  const availableCountries = useMemo(() => merge(statsCountries, statusCountries), [statusCountries, statsCountries])

  useMount(() => {
    form.watch()
  })

  useEffect(() => {
    if (filters.period) {
      fetchStats(country, filters.period, filters.start, filters.end)
      fetchHistory(1, MAX_PAGE_SIZE, country, filters.period, filters.start, filters.end)
    }
  }, [JSON.stringify(filters), form.getValues('country')])

  const countryOptions = useMemo(() => {
    const result = Object.keys(countries)
      .map((code) => {
        const country = countries[code]
        const lang = language.toUpperCase()

        const label = country.translations[lang] ? country.translations[lang].common : country.name.common

        return {
          label: label,
          value: country.code,
          icon: <ReactCountryFlag countryCode={code} svg />,
        }
      })
      .filter((el) => availableCountries.includes(el.value))

    return [
      { label: t('Все страны'), value: 'all', icon: <img src="./images/icon-earth.svg" alt={'earth'} /> },
      ...sortBy(result, ['label']),
    ]
  }, [countries, availableCountries, language])

  const defaultCountry = 'all'

  const handleOpenModal = (ev, user) => {
    ev.preventDefault()

    toggleModal({ open: true, participant: user })
  }

  return (
    <div className="panel">
      <div className="panel-body">
        <div className="stats-filters">
          {/* <div className="stats-filters--item"> */}
          {/*  <Tabs */}
          {/*    options={VIEW_TYPE_TAB_OPTIONS} */}
          {/*    onChangeTab={(v) => { */}
          {/*      setCurrentView(v) */}
          {/*    }} */}
          {/*    activeTab={currentView} */}
          {/*  /> */}
          {/* </div> */}
          <div className="stats-filters--item stats-filters--item_regions">
            <Dropdown
              key={language}
              name={'country'}
              disabled={!countryOptions.length}
              options={countryOptions}
              control={form.control}
              defaultValue={defaultCountry}
              cardClassName={'country-card'}
            />
          </div>

          <PeriodSelector onChangePeriod={setFilters} />
        </div>

        {currentView === VIEW_TYPE_TABLE ? (
          <>
            <div className="ambassador-stat-panels">
              <div className="stat-panels">
                <div className="stat-panels--item">
                  <div className="stat-panels--title">{t('Активные клиенты')}</div>
                  <div className="stat-panels--value">{asNumber(stats.activeParticipants)}</div>
                </div>
                <div className="stat-panels--item">
                  <div className="stat-panels--title">{t('Объем продаж токенов')}</div>
                  <div className="stat-panels--value">
                    {asMoney(stats.turnovers.realty.value, stats.turnovers.realty.currency)}
                  </div>
                </div>{' '}
                <div className="stat-panels--item">
                  <div className="stat-panels--title">{t('Объем стейка CR')}</div>
                  <div className="stat-panels--value">
                    {asMoney(stats.turnovers.staking.value, stats.turnovers.staking.currency)}
                  </div>
                </div>
                <div className="stat-panels--item">
                  <div className="stat-panels--title">{t('К начислению за месяц ')}</div>
                  <div className="stat-panels--value">
                    {asMoney(stats.frozenBonuses.realty.value, stats.frozenBonuses.realty.currency)}&nbsp;/&nbsp;
                    {asMoney(stats.frozenBonuses.staking.value, stats.frozenBonuses.staking.currency)}
                  </div>
                </div>
              </div>
            </div>
            <div className="table-wrapper">
              <table className="transactions-table transactions-table_history">
                <thead>
                  <tr>
                    <th>{t('Дата')}</th>
                    <th>{t('Клиент')}</th>
                    <th>{t('Сумма покупки')}</th>
                    <th>{t('Токены')}</th>
                    <th>{t('Процент')}</th>
                    <th>{t('Бонус')}</th>
                    <th>{t('Статус')}</th>
                  </tr>
                </thead>
                <tbody>
                  {history.data.length === 0 && (
                    <tr>
                      <td colSpan="6" className="td-empty">
                        {t('Нет данных')}
                      </td>
                    </tr>
                  )}
                  {history.data.map((log) => (
                    <tr key={log.code}>
                      <td data-title={t('Дата')}>{parseAndFormat(log.createdAt)}</td>
                      <td className="very-first-mobile">
                        <div className="usercard usercard_sm">
                          <div className="usercard--image">
                            <img src={getAvatar(log.user)} alt={log.user.login} />
                            {log.user?.profile?.country && (
                              <span className="userflag">
                                <img
                                  src={countryFlag(log.user?.profile?.country)}
                                  style={{
                                    display: 'inline-block',
                                    width: '1em',
                                    height: '1em',
                                    verticalAlign: 'middle',
                                  }}
                                />
                              </span>
                            )}
                          </div>
                          <div className="usercard--content">
                            <div className="usercard--title">{getFullName(log.user)}</div>
                            <div className="usercard--link">
                              <a href="#" onClick={(ev) => handleOpenModal(ev, log.user)}>
                                {log.user.login}
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td data-title={t('Сумма покупок')}>
                        {asMoney(log.purchaseAmount.value, log.purchaseAmount.currency)}
                      </td>
                      <td data-title={t('Токены')}> {asMoney(log.tokenAmount.value, log.tokenAmount.currency)}</td>
                      <td data-title={t('Процент')}>{asNumber(log.percent, 2)}%</td>
                      <td className="td-highlight" data-title={t('Бонус')}>
                        <span className="gt">{asMoney(log.bonus.value, log.bonus.currency)}</span>
                      </td>
                      <td data-title={t('Статус')} className={`text-status ${STATUS_COLORS[log.status]}`}>
                        {t(STATUS_LABELS[log.status])}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              page={history.page}
              initialPage={history.page - 1}
              pageCount={Math.ceil(history.count / MAX_PAGE_SIZE)}
              onSelect={(num) => {
                fetchHistory(num, MAX_PAGE_SIZE, country, filters.period, filters.start, filters.end)
              }}
            />
          </>
        ) : (
          <ChartStats totalStats={stats} filters={filters} country={country} />
        )}
      </div>

      {modal.open && (
        <ParticipantModal
          isOpen={modal.open}
          participant={modal.participant}
          onClose={() => toggleModal({ open: false, participant: null })}
        />
      )}
    </div>
  )
}

export default History
