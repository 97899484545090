import useLevelStatsDataStore from 'shared/stores/levelStatsData'

import { getLevelStats, getStats } from 'shared/api/structure'

import { PERIOD_ALL, PERIOD_CUSTOM } from '../constants'

const useRanksData = () => {
  const data = useLevelStatsDataStore()

  const fetchUserRanks = (period = PERIOD_ALL, start, end) => {
    if (period === PERIOD_CUSTOM && (!start || !end)) return false
    getLevelStats(period, start ?? '', end ?? '')
      .then((res) => {
        data.setUserRanks(res)
      })
      .catch((err) => console.log(err))
  }

  const fetchTotalStats = (lvl = 8, period = PERIOD_ALL, start, end) => {
    if (period === PERIOD_CUSTOM && (!start || !end)) return false
    getStats(lvl, period, start ?? '', end ?? '')
      .then((res) => data.setTotalStats({ data: res }))
      .finally(() => data.setTotalStats({ loading: false }))
  }

  return { fetchUserRanks, fetchTotalStats }
}

export default useRanksData
