import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'

import Loader from 'components/Loader'
import ModalsProvider from 'components/ModalsProvider'
import RoutesRenderer from 'components/RoutesRenderer'

import IconsRenderer from './components/IconsRenderer'
import i18n from './i18n'
import useThemeToggle from './shared/hooks/useThemeToggle'

const Root = () => {
  useThemeToggle()
  return (
    <ModalsProvider>
      <div id="app">
        <IconsRenderer />
        <RoutesRenderer />
      </div>
    </ModalsProvider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Suspense fallback={<Loader />}>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <Root />
      </BrowserRouter>
    </I18nextProvider>
  </Suspense>,
)
