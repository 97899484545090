import { LOGIN_SOURCE } from '../constants'
import { get, post } from './fetch'

export const getNotificationList = (page = 1, limit = 15, filter = '') => {
  return get(`notifications?source=${LOGIN_SOURCE}&read=${filter}&page=${page}&limit=${limit}`, true)
}

export const hideNotifications = (ids) => {
  return post(`notifications/read`, { ids }, true)
}

export const clearNotification = () => {
  return post(`notifications/clear`, null, true)
}
