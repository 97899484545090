import { reverse } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { parseAndFormat } from 'shared/utils/date'

import Button from 'shared/components/Button'

import { closeTicket } from '../../../api/support'
import { SUPPORT_STATUS_DONE } from '../../../constants'
import useTicketsData from '../../../hooks/useTicketsData'

const TicketMessages = ({ setLoading }) => {
  const { t } = useTranslation()
  const { id } = useParams()

  const { fetchTicketDetail, ticketData, ticketMessages } = useTicketsData()
  const messages = reverse([...ticketMessages.data])

  const isClosed = ticketData.status === SUPPORT_STATUS_DONE

  const handleCloseTicket = () => {
    setLoading({ close: true })
    closeTicket(id)
      .then(() => fetchTicketDetail(id))
      .catch((e) => console.log(e))
      .finally(() => setLoading({ close: false }))
  }

  return (
    <div className={`support-ticket--content`}>
      <ul className={`messages-list  ${ticketMessages.loading ? 'loading' : ''}`}>
        {messages.map((message) => {
          return (
            <li className="messages-list--item" key={message.id}>
              <div className={`message-item ${message.isAdmin ? 'message-item_answer' : ''}`}>
                <div className="message-item--container">
                  {message.isAdmin && (
                    <div className="message-item--avatar">
                      <div className="support-avatar" />
                    </div>
                  )}
                  <div className="message-item--content">
                    <div className="message-item--text" dangerouslySetInnerHTML={{ __html: message.content }} />

                    <div className="message-item--footer">
                      <div className="message-item--date">{parseAndFormat(message.createdAt)}</div>
                    </div>
                    {message.attachments.length > 0 && (
                      <div className="message-item--attach">
                        <ul className="upload-img-list upload-img-list_lg">
                          {message.attachments.map((file) => {
                            const isPdf = file.mimeType === 'application/pdf'

                            return isPdf ? (
                              <li className="upload-img-list--item upload-img-list--item_doc" key={file.url}>
                                <a href={file.url} className="upload-img-list--link" target="_blank" />
                                <span className="upload-img-list--item-filename">{file.url}</span>
                              </li>
                            ) : (
                              <li className="upload-img-list--item" key={file.url}>
                                <a href={file.url} target="_blank" className="upload-img-list--link">
                                  <img src={file.url} alt={file.name} />
                                </a>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </li>
          )
        })}

        {!isClosed && (
          <li className="messages-list--item messages-list--item_close">
            <Button onClick={handleCloseTicket} classes={['btn-sm btn_gt btn-light']}>
              <i className="icon icon-check" />

              <span>{t('Проблема решена')}</span>
            </Button>
          </li>
        )}
      </ul>
    </div>
  )
}

export default TicketMessages
