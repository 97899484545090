import React from 'react'
import { Link } from 'react-router-dom'

import LanguageSelector from 'components/LanguageSelector'

const Header = () => {
  return (
    <header id="header">
      <div className="header">
        <div className="header-logo">
          <Link to="/auth/login" className="full-logo" title="InfluenceClub">
            InfluenceClub
          </Link>
        </div>

        <div className="header-userbar">
          <div className="header-userbar__lang">
            <LanguageSelector />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
