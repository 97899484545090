import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Breadcrumbs from 'components/Breadcrumbs'

import { parseAndFormat } from 'shared/utils/date'

import { getNewsItem } from 'shared/api/news'

const NewsDetailsScreen = () => {
  const { t, i18n } = useTranslation()
  const { id } = useParams()

  const [details, setDetails] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchNewsDetails()
  }, [i18n.language])

  const fetchNewsDetails = () => {
    setLoading(true)
    getNewsItem(id, i18n.language)
      .then((res) => {
        setDetails(res)
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false))
  }

  return (
    <div className="page">
      <div className="page--header">
        <Breadcrumbs currentCrumbs={[{ path: 'news/:id', breadcrumb: details.title ?? id }]} />
        <div className="page--header-container">
          <div className="page--title">{t('Новости')}</div>
        </div>
      </div>
      <div className={`page--content ${loading ? 'loading' : ''}`}>
        <div className="news-article">
          <div className="news-article--header">
            <div className="news-article--title">{details.title}</div>

            <div className="news-article--meta">
              <div className="news-article--date">{details.publishedAt && parseAndFormat(details.publishedAt)}</div>
            </div>
          </div>
          <div className="news-article--media">
            <img src={details?.media?.large} alt={details.title} />
          </div>

          <div className="news-article--content" dangerouslySetInnerHTML={{ __html: details.content }} />
        </div>
      </div>
    </div>
  )
}

export default NewsDetailsScreen
