import { PERIOD_ALL } from 'shared/constants'
import create from 'zustand'

import { VIEW_TYPE_TABLE } from '../constants'

const useStore = create((set) => ({
  currentView: VIEW_TYPE_TABLE,
  ticketMessages: { data: [], page: 1, count: 0, loading: false },
  list: { data: [], page: 1, count: 0, loading: false },
  filters: { period: PERIOD_ALL, start: null, end: null },
  setCurrentView: (data) => set(() => ({ currentView: data })),
  setFilters: (data) => set((state) => ({ filters: { ...state.filters, ...data } })),
  setCurrentTicket: (data) => set((state) => ({ currentTicket: { ...state.currentTicket, ...data } })),
  setTicketMessages: (data) => set((state) => ({ ticketMessages: { ...state.ticketMessages, ...data } })),
}))

export default useStore
