import create from 'zustand'

const useStore = create((set) => ({
  currentStep: 0,
  setActiveStep: (num) =>
    set(() => ({
      currentStep: num,
    })),
}))

export default useStore
