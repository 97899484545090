import React from 'react'
import { useTranslation } from 'react-i18next'
import useMount from 'react-use/lib/useMount'
import useSetState from 'react-use/lib/useSetState'

import useAmbassador from 'shared/hooks/useAmbassador'

import useUserStore from 'shared/stores/user'

import { asMoney } from 'shared/utils/money'
import { countryFlag } from 'shared/utils/ui'
import { getAvatar, getFullName } from 'shared/utils/user'

import CountryModal from './CountryModal'

const HeaderInfo = () => {
  const { t } = useTranslation()
  const user = useUserStore()
  const { stats, status, fetchAmbassadorStatus, fetchStats } = useAmbassador()

  const [modal, toggleModal] = useSetState({
    open: false,
    data: {},
  })

  useMount(() => {
    fetchAmbassadorStatus()
    fetchStats()
  })

  const handleOpenCountryDetail = (ev, data) => {
    toggleModal({ open: true, data })
  }

  return (
    <div className="panel">
      <div className="panel-body">
        <div className="ambassador-stats">
          <div className="ambassador-stats--item">
            <div className="usercard usercard_lg">
              <div className="usercard--image">
                <img src={getAvatar(user)} alt="" />
              </div>
              <div className="usercard--content">
                <div className="usercard--title">{getFullName(user)}</div>
                <div className="usercard--link">
                  <a style={{ cursor: 'default' }}>{user.login}</a>
                </div>
              </div>
            </div>
          </div>
          <div className="ambassador-stats--item">
            <div className="ambassador-status">
              <div className="ambassador-status--title">{t('Текущий статус')}</div>
              <div className="ambassador-status--value">
                <span className="ambassador-status--value-text">{t('Амбассадор')}</span>
              </div>
            </div>
          </div>
          <div className="ambassador-stats--item">
            <div className="ambassador-countries">
              <div className="ambassador-countries--title">{t('Проценты по странам')}</div>

              <div className="ambassador-countries--value">
                {status.countries.slice(0, 3).map((item, key) => {
                  return (
                    <div className="ambassador-countries--value-item" key={key}>
                      <a
                        href="#"
                        className="ambassador-country-rate"
                        onClick={(ev) => handleOpenCountryDetail(ev, item)}
                        title={t('Подробнее')}
                      >
                        <div className="ambassador-country-rate--image">
                          <img
                            src={countryFlag(item.country)}
                            style={{
                              display: 'inline-block',
                              width: '1em',
                              height: '1em',
                              verticalAlign: 'middle',
                            }}
                            alt={item.country}
                          />
                        </div>
                        <div className="ambassador-country-rate--value">
                          <div className="ambassador-country-rate--value-text">{item.percent}%</div>
                        </div>
                      </a>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="ambassador-stats--item">
            <div className="ambassador-profit">
              <div className="ambassador-profit--title">{t('Вознаграждение')}</div>
              <div className="ambassador-profit--value">
                <div className="ambassador-profit--value-text">
                  {asMoney(stats.bonuses.realty.value, stats.bonuses.realty.currency)} /{' '}
                  {asMoney(stats.bonuses.staking.value, stats.bonuses.staking.currency)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CountryModal isOpen={modal.open} data={modal.data} onClose={() => toggleModal({ open: false })} />
    </div>
  )
}

export default HeaderInfo
