import 'moment/locale/es'
import 'moment/locale/id'
import 'moment/locale/ru'
import moment from 'moment/moment'
import React, { useEffect, useMemo, useState } from 'react'
import { DateRangePicker } from 'react-dates'
import { HORIZONTAL_ORIENTATION, VERTICAL_ORIENTATION } from 'react-dates/constants'
import { Trans, useTranslation } from 'react-i18next'
import useMount from 'react-use/lib/useMount'
import { PERIOD_ALL, PERIOD_CUSTOM, PERIOD_MONTH, PERIOD_WEEK, PERIOD_YEAR } from 'shared/constants'
import * as yup from 'yup'

import useFormWrapper from 'shared/hooks/useFormWrapper'
import useMedia from 'shared/hooks/useMedia'

import { calendarArrowIcon } from '../assets/icons'
import Dropdown from './forms/Dropdown'

const PeriodSelector = ({ onChangePeriod }) => {
  const { t, i18n } = useTranslation()
  const { isMobile } = useMedia()
  const [focusedDate, setFocusedDate] = useState(null)

  const PERIODS = [
    {
      value: PERIOD_ALL,
      label: <Trans i18nKey={'За все время'} />,
    },
    {
      value: PERIOD_YEAR,
      label: <Trans i18nKey={'Год'} />,
    },
    {
      value: PERIOD_MONTH,
      label: <Trans i18nKey={'Месяц'} />,
    },
    {
      value: PERIOD_WEEK,
      label: <Trans i18nKey={'Неделя'} />,
    },
    {
      value: PERIOD_CUSTOM,
      label: <Trans i18nKey={'Другой период'} />,
    },
  ]
  const [dates, setDates] = useState({ startDate: null, endDate: null })
  const validationSchema = yup.object().shape({
    period: yup.mixed().oneOf([PERIOD_ALL, PERIOD_YEAR, PERIOD_WEEK, PERIOD_MONTH, PERIOD_CUSTOM]),
    start: yup.string().when('period', {
      is: PERIOD_CUSTOM,
      then: yup.string().required('Обязательное поле'),
    }),
    end: yup.string().when('period', {
      is: PERIOD_CUSTOM,
      then: yup.string().required('Обязательное поле'),
    }),
  })

  const { form } = useFormWrapper(validationSchema, {
    mode: 'all',
    defaultValues: {},
  })
  const isCustomPeriod = useMemo(() => form.getValues('period') === PERIOD_CUSTOM, [form.getValues('period')])

  useMount(() => {
    form.watch()
  })
  useEffect(() => {
    onChangePeriod(form.getValues())

    if (form.getValues('period') !== PERIOD_CUSTOM) {
      form.setValue('start', null)
      form.setValue('end', null)
      setDates({ startDate: null, endDate: null })
    }
  }, [JSON.stringify(form.getValues())])

  moment.updateLocale(i18n.language, {
    week: {
      dow: 1,
    },
  })

  return (
    <>
      <div className={'stats-filters--item stats-filters--item_periods'}>
        <Dropdown
          control={form.control}
          name={'period'}
          options={PERIODS}
          defaultValue={PERIOD_ALL}
          key={i18n.language}
        />
      </div>
      {isCustomPeriod && (
        <div className="stats-filters--item">
          <div className="ic-dates">
            <DateRangePicker
              startDate={dates.startDate}
              endDate={dates.endDate}
              startDateId={'start'}
              startDatePlaceholderText={t('Начало')}
              endDatePlaceholderText={t('Конец')}
              endDateId={'end'}
              isOutsideRange={() => false}
              hideKeyboardShortcutsPanel
              orientation={isMobile ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION}
              onDatesChange={({ startDate, endDate }) => {
                if (startDate) {
                  form.setValue('start', startDate.format('yyyy-MM-DD'))
                }

                if (endDate) form.setValue('end', endDate.format('yyyy-MM-DD'))

                setDates({ startDate, endDate })
              }}
              focusedInput={focusedDate}
              onFocusChange={setFocusedDate}
              customArrowIcon={<div>{calendarArrowIcon}</div>}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default PeriodSelector
