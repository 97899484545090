import React from 'react'
import { useTranslation } from 'react-i18next'
import useMount from 'react-use/lib/useMount'
import * as yup from 'yup'

import useFormWrapper from 'shared/hooks/useFormWrapper'

import { getCurrencyAlias } from 'shared/utils/billing'
import { asUSCR } from 'shared/utils/money'

import Modal from 'shared/components/Modal'

import { CURRENCY_BASE } from '../../constants'
import AmountForm from './components/AmountForm'
import Confirmation from './components/Confirmation'
import SecurityForm from './components/SecurityForm'
import useStepsStore from './stores/steps'

const STEPS = [
  {
    title: 'Ввод суммы',
    form: (form) => <AmountForm formWrapper={form} />,
  },
  {
    title: 'Безопасность',
    form: (form) => <SecurityForm formWrapper={form} />,
  },
  {
    title: 'Подтверждение',
    form: (form) => <Confirmation formWrapper={form} />,
  },
]

const MIN_AMOUNT = 1
const MAX_AMOUNT = 10_000

const TransferModal = ({ isOpen = false, onClose }) => {
  const { t } = useTranslation()
  const steps = useStepsStore()
  const validationSchema = yup.object().shape({
    amount: yup
      .number()
      .min(MIN_AMOUNT, `${t('Минимальная сумма')} ${asUSCR(MIN_AMOUNT)}`)
      .max(MAX_AMOUNT, `${t('Максимальная сумма')} ${asUSCR(MAX_AMOUNT)}`)
      .typeError(t('Значение должно быть числом')),
    recipient: yup.string().trim().required(t('Обязательное поле')),
    code: yup.number().required(t('Обязательное поле')).typeError(t('Значение должно быть числом')),
    confirmLogin: yup.bool(),
  })

  const formWrapper = useFormWrapper(validationSchema, {
    mode: 'all',
    defaultValues: {},
  })

  useMount(() => {
    formWrapper.form.watch()
  })

  const handleClose = (ev) => {
    ev.preventDefault()
    formWrapper.form.reset({
      amount: 0,
      recipient: '',
      code: '',
      confirmLogin: false,
    })
    onClose && onClose()
  }

  const handleChangeStep = (step) => {
    if (step === 2) {
      return false
    }

    if (steps.currentStep === 2) {
      return false
    }
    if (!formWrapper.form.formState.isValid) {
      return false
    }
    steps.setActiveStep(step)
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} additionalClassName={'modal_smd'}>
      <a href="#" className="modal-close btn-modal-close" title="Закрыть" onClick={handleClose}>
        {t('Закрыть')}
      </a>
      <div className="modal-content">
        <div className="modal-body">
          <div className="finance-form">
            <div className="finance-form--header">
              <div className="finance-form--title">{t('Перевод средств')}</div>
              <div className="finance-form--subtitle">
                {t('Вы можете перевести любому клиенту токен {{token}}', { token: getCurrencyAlias(CURRENCY_BASE) })}
              </div>
            </div>
            <div className="finance-form--steps">
              <ul className="buy-token-steps">
                {STEPS.map((step, key) => (
                  <li
                    className={`buy-token-steps--item${key === steps.currentStep ? ' is-active' : ''}`}
                    key={key}
                    onClick={() => handleChangeStep(key)}
                  >
                    <div className="buy-token-steps--item-icon" />
                    <div className="buy-token-steps--item-title">{t(step.title)}</div>
                  </li>
                ))}
              </ul>
            </div>
            {STEPS[steps.currentStep].form(formWrapper)}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default TransferModal
