import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import TelegramLoginButton from 'react-telegram-login'
import useSessionStore from 'screens/Auth/stores/session'
import { BASE_URL, LOGIN_SOURCE, SUPPORT_EMAIL, TELEGRAM_BOT_NAME } from 'shared/constants'

import useLogin from 'shared/hooks/useLogin'
import useTelegramLogin from 'shared/hooks/useTelegramLogin'

import useUserStore from 'shared/stores/user'

import { isGuest } from 'shared/utils/user'

import { identity } from 'shared/api/auth/telegram-bot'

const BON_NAME = TELEGRAM_BOT_NAME

const AUTH_METHOD_PASSPORT = 1
const AUTH_METHOD_BOT = 2

const Login = ({ onError }) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const user = useUserStore()
  const { setSession } = useSessionStore()

  const login = useLogin()

  const language = user.language || i18n.language

  const { loading, login: telegramLogin } = useTelegramLogin(language, `${BASE_URL}/auth/login/waiting`)
  const [btnLoading, setBtnLoading] = useState(false)
  const [method, setMethod] = useState(AUTH_METHOD_PASSPORT)

  useEffect(() => {
    if (!isGuest(user)) {
      navigate('/')
    }
  }, [user])

  const handleChangeMethod = (ev) => {
    const mtd = parseInt(ev.target.value)

    setMethod(mtd)

    if (mtd === AUTH_METHOD_BOT) {
      setBtnLoading(true)
      setTimeout(() => setBtnLoading(false), 350)
    } else {
      setBtnLoading(false)
    }
  }

  const handleButtonResponse = (authData) => {
    identity({ ...authData, language, source: LOGIN_SOURCE })
      .then((data) => {
        if (data?.errors) {
          onError && onError(data.errors)
          navigate('/auth/login/fail')
        }

        if (data?.token) {
          login(data.token, data.refreshToken)
        }

        if (data?.chatId) {
          setSession(data)
        }
      })
      .catch((err) => console.log(err))
  }

  const handleLogin = async (ev) => {
    ev.preventDefault()

    await telegramLogin()
  }

  return (
    <div className="auth">
      <div className="auth-title">{t('Войти / Регистрация')}</div>
      <div className="auth-content">
        <div>
          <Trans
            i18nKey={
              'Для входа в личный кабинет вам необходимо иметь установленное приложение <a>Telegram</a> на вашем устройстве.'
            }
            components={{
              a: <a href="https://telegram.org" target="_blank" />,
            }}
          />
        </div>

        <div>
          <div>
            <Trans
              i18nKey={'Если у вас возникли проблемы, <a>напишите нам</a> об этом.'}
              components={{
                a: <a href={`mailto:${SUPPORT_EMAIL}`} />,
              }}
            />
          </div>
        </div>
      </div>

      <div className="auth-options">
        <ul className="auth-options-list">
          <li className="auth-options-list--item">
            <div className="form-group">
              <div className="radio radio_md">
                <input
                  type="radio"
                  name="auth-option"
                  id="passport"
                  checked={method === AUTH_METHOD_PASSPORT}
                  value={AUTH_METHOD_PASSPORT}
                  onChange={handleChangeMethod}
                />
                <label htmlFor="passport">
                  <span className="input-button" />
                  <span className="text">{t('Войти через Telegram Passport')}</span>
                </label>
              </div>

              <div className="help-message">{t('Для Windows, Linux')}</div>
            </div>
          </li>
          <li className="auth-options-list--item">
            <div className="form-group">
              <div className="radio radio_md">
                <input
                  type="radio"
                  name="auth-option"
                  id="bot"
                  checked={method === AUTH_METHOD_BOT}
                  value={AUTH_METHOD_BOT}
                  onChange={handleChangeMethod}
                />
                <label htmlFor="bot">
                  <span className="input-button" />
                  <span className="text">{t('Войти через Telegram Bot')}</span>
                </label>
              </div>

              <div className="help-message">{t('Для MacOS, Android, iPhone')}</div>
            </div>
          </li>
        </ul>
      </div>

      <div className="auth-buttons">
        {method === AUTH_METHOD_PASSPORT && (
          <a href="#" className="btn btn-primary btn-lg btn_sqr" disabled={loading} onClick={handleLogin}>
            <svg width="1.9rem" height="1.6rem">
              <use xlinkHref="#social-telegram" />
            </svg>
            <span>{t('Войти')}</span>
          </a>
        )}

        {method === AUTH_METHOD_BOT && (
          <>
            <div className={btnLoading ? 'loading' : ''} style={{ height: 40 }}>
              <div style={{ visibility: btnLoading ? 'hidden' : 'visible' }}>
                <TelegramLoginButton
                  botName={BON_NAME}
                  dataOnauth={handleButtonResponse}
                  usePic={false}
                  buttonSize={'large'}
                  cornerRadius={5}
                  lang={language}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Login
