import React from 'react'
import { useTranslation } from 'react-i18next'
import { CURRENCY_BASE } from 'shared/constants'

import useLevelStatsDataStore from 'shared/stores/levelStatsData'

import { asMoney } from 'shared/utils/money'

const Profit = () => {
  const { t } = useTranslation()
  const { totalStats } = useLevelStatsDataStore()
  const structureStats = totalStats.data

  return (
    <div className="dashboard-grid--item">
      <div className="panel">
        <div className="panel-body">
          <div className="agent-profit">
            <div className="agent-profit--header">
              <div className="stats-value">
                <div className="stats-value--title">{t('Агентское вознаграждение')}</div>
                {structureStats?.personalBonus?.map((el, idx) => (
                  <div className="stats-value--value-row" key={idx}>
                    <div className="stats-value--value">{asMoney(el.value, el.currency)}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profit
