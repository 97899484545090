import React from 'react'
import { useTranslation } from 'react-i18next'

import useThemeToggle from 'shared/hooks/useThemeToggle'

const ThemeToggler = () => {
  const { t } = useTranslation()
  const { toggleTheme } = useThemeToggle()

  return (
    <button className="theme-button" title={t('Переключить тему')} onClick={toggleTheme}>
      {t('Переключить тему')}
    </button>
  )
}

export default ThemeToggler
