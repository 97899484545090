import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CURRENCY_CR, CURRENCY_USCR } from 'shared/constants'

import useLevelStatsDataStore from 'shared/stores/levelStatsData'

import { buildLevelStatsData, buildLevelStatsDataObj } from 'shared/utils/levelStats'
import { asMoney, asUSD } from 'shared/utils/money'

import DoughnutChart from 'shared/components/DoughnutChart'

const ProfitStats = () => {
  const { t } = useTranslation()

  const { userRanks, totalStats } = useLevelStatsDataStore()
  const ranksData = useMemo(() => buildLevelStatsData(userRanks), [userRanks])
  const totalUscr = totalStats.data.personalBonus.find((el) => el.currency === CURRENCY_USCR) || {
    value: 0,
    currency: CURRENCY_USCR,
  }
  const objRanksData = useMemo(() => buildLevelStatsDataObj(userRanks), [userRanks])

  return (
    <div className="agent-profit-stats">
      <div className="agent-profit-stats--header">
        <div className="agent-profit-stats--title">{t('Агентское вознаграждение')}</div>

        <div className="agent-profit-stats--controls">
          <a
            href="#"
            className="arrow-link btn-modal"
            data-target="messageProfitConditions"
            style={{ display: 'none' }}
          >
            <span>{t('Подробнее')}</span>
          </a>
        </div>
      </div>
      <DoughnutChart
        stats={objRanksData}
        total={totalStats.data.personalBonus}
        sumTotal={totalUscr.value}
        classes={'level-'}
        showTotal
        valueKey={`bonus[${CURRENCY_USCR}].value`}
        currency={''}
      />

      <div className="agent-profit-stats--content">
        <div className="table-wrapper">
          <table className="data-table">
            <thead>
              <tr>
                <th>{t('Уровень офиса')}</th>
                <th>{t('Агентский процент')}</th>
                <th>{t('Объем продаж')}</th>
                <th colSpan={2}>{t('Вознаграждение')}</th>
              </tr>
            </thead>
            <tbody>
              {ranksData.map((rank) => {
                return (
                  <tr key={rank.rankNumber}>
                    <td className="level-td" data-subtitle={t('Уровень офиса')}>
                      <div className="level-wrapper">
                        <i className={`level-icon level${rank.rankNumber}`} />
                        <span>{rank.rankNumber}</span>
                      </div>
                    </td>
                    <td className="percent-td" data-title={t('Агентский процент')}>
                      {rank.percent}%
                    </td>
                    <td className="value-td" data-title={t('Объем продаж')}>
                      {asMoney(rank.totalTurnover?.value, rank.totalTurnover?.currency, 2)}
                    </td>
                    <td colSpan={1} className="profit-td" data-title={t('Вознаграждение')}>
                      {asMoney(rank.bonus[CURRENCY_USCR].value, rank.bonus[CURRENCY_USCR].currency, 2)}
                    </td>
                    <td colSpan={1} className="profit-td" data-title={t('Вознаграждение')}>
                      {asMoney(rank.bonus[CURRENCY_CR].value, rank.bonus[CURRENCY_CR].currency, 2)}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ProfitStats
