import Chartist from 'chartist'
// eslint-disable-next-line
import * as Gradient from 'chartist-plugin-gradient'
// eslint-disable-next-line
import * as Tooltip from 'chartist-plugin-tooltips-updated'
import get from 'lodash/get'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PERIOD_ALL, PERIOD_CUSTOM, PERIOD_YEAR } from 'shared/constants'

import useUserStore from 'shared/stores/user'

import { parseAndFormat } from 'shared/utils/date'
import { asMoney, asNumber } from 'shared/utils/money'

import { getChartParticipants, getChartTurnovers } from 'shared/api/structure'

function createFilialChart(type = 'turnover', currentRef, data, valueString = '') {
  if (currentRef.current) {
    const min = Math.min(...data.series[0], ...data.series[1])
    const finalData = type === 'turnover' ? data : { labels: data.labels, series: [[], [], ...data.series] }
    const chart = new Chartist.Line(currentRef.current, finalData, {
      low: min,
      showArea: false,
      showPoint: true,
      axisX: {
        showGrid: false,
        showLabel: true,
      },
      axisY: {
        showGrid: true,
        showLabel: true,
        onlyInteger: true,
      },
      height: '30rem',
      fullWidth: true,
      chartPadding: {
        right: 35,
        left: 0,
      },
      series: {},
      plugins: [
        Chartist.plugins.tooltip({
          // anchorToPoint: true,
          transformTooltipTextFnc: function (value) {
            if (valueString) {
              return value + valueString
            } else {
              return asNumber(value, 2)
            }
          },
        }),
      ],
    })

    chart.on('created', () => {
      const points = document.querySelectorAll('.ct-point')
      const tooltip = document.querySelector('.chartist-tooltip')

      points.forEach((point) => {
        point.addEventListener('mouseover', () => {
          tooltip.className = 'chartist-tooltip'
          tooltip.classList.add('filial-tooltip')
          tooltip.classList.add(point.parentElement.classList[1])
        })
      })
    })
  }
}
const ChartStats = ({ totalStats, filters }) => {
  const { t } = useTranslation()
  const user = useUserStore()

  const chartTurnover = useRef()
  const chartParticipants = useRef()
  const [turnover, setTurnover] = useState({})
  const [participants, setParticipants] = useState({})

  useEffect(() => {
    fetchChartTurnovers(filters.period, filters.start, filters.end)
    fetchChartParticipants(filters.period, filters.start, filters.end)
  }, [JSON.stringify(filters)])

  useEffect(() => {
    renderChart('turnover', turnover)
  }, [JSON.stringify(turnover)])

  useEffect(() => {
    renderChart('participants', participants)
  }, [JSON.stringify(participants), user.language])

  const fetchChartTurnovers = (period, start, end) => {
    if (period === PERIOD_CUSTOM && (!start || !end)) return false

    getChartTurnovers(period, start ?? '', end ?? '')
      .then((res) => setTurnover(res))
      .catch((e) => console.log(e))
  }
  const fetchChartParticipants = (period, start, end) => {
    if (period === PERIOD_CUSTOM && (!start || !end)) return false

    getChartParticipants(period, start ?? '', end ?? '')
      .then((res) => setParticipants(res))
      .catch((e) => console.log(e))
  }

  const renderChart = (type, data, locale = 'ru') => {
    const affiliateData = data?.affiliate?.data ?? []
    const officeData = data?.office?.data ?? []

    const affiliate = affiliateData.filter((item, key) =>
      filters.period === 'month' ? key === 0 || key === affiliateData.length - 1 || (key + 1) % 2 === 0 : true,
    )
    const office = officeData.filter((item, key) =>
      filters.period === 'month' ? key === 0 || key === officeData.length - 1 || (key + 1) % 2 === 0 : true,
    )

    const turnoverData = {
      labels: affiliate.map((item) =>
        filters.period === PERIOD_YEAR || filters.period === PERIOD_ALL
          ? parseAndFormat(item.date, 'MMM', undefined, undefined, user.language)
          : parseAndFormat(item.date, 'dd.MM'),
      ),
      series: [
        affiliate.map((item) => {
          if (type === 'turnover') return item.amount.value
          return item.count
        }),
        office.map((item) => {
          if (type === 'turnover') return item.amount.value
          return item.count
        }),
      ],
    }

    createFilialChart(type, type === 'turnover' ? chartTurnover : chartParticipants, turnoverData)
  }

  return (
    <div className="filial-charts">
      <div className="filial-charts--item">
        <div className="filial-charts-legend">
          <div className="filial-charts-legend--item">
            <div className="filial-chart-legend filial-chart-legend_a">
              <div className="filial-chart-legend--title">{t('Объем филиала')}</div>
              <div className="filial-chart-legend--value">
                {asMoney(
                  get(totalStats, 'affiliate.turnover.value', 0),
                  get(totalStats, 'affiliate.turnover.currency', ''),
                  2,
                )}
              </div>
            </div>
          </div>
          <div className="filial-charts-legend--item">
            <div className="filial-chart-legend filial-chart-legend_b">
              <div className="filial-chart-legend--title">{t('Объем личного офиса')}</div>
              <div className="filial-chart-legend--value">
                {asMoney(
                  get(totalStats, 'office.turnover.value', 0),
                  get(totalStats, 'office.turnover.currency', ''),
                  2,
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="filial-charts-chart">
          <div className="filial-chart" id="filial-chart1" ref={chartTurnover} />
        </div>
      </div>

      <div className="filial-charts--item">
        <div className="filial-charts-legend">
          <div className="filial-charts-legend--item">
            <div className="filial-chart-legend filial-chart-legend_c">
              <div className="filial-chart-legend--title">{t('Кол-во участников филиала')}</div>
              <div className="filial-chart-legend--value">{asNumber(get(totalStats, 'affiliate.participants', 0))}</div>
            </div>
          </div>
          <div className="filial-charts-legend--item">
            <div className="filial-chart-legend filial-chart-legend_d">
              <div className="filial-chart-legend--title">{t('Кол-во участников офиса')}</div>
              <div className="filial-chart-legend--value">{asNumber(get(totalStats, 'office.participants', 0))}</div>
            </div>
          </div>
        </div>

        <div className="filial-charts-chart">
          <div className="filial-chart" id="filial-chart2" ref={chartParticipants} />
        </div>
      </div>
    </div>
  )
}

export default ChartStats
