import React from 'react'

import useModalsStore from 'shared/stores/modals'

import ChangeCountryModal from 'shared/components/ChangeCountryModal'
import TransferModal from 'shared/components/TransferModal'
import WithdrawalModal from 'shared/components/WithdrawalModal'

const ModalsProvider = ({ children }) => {
  const modals = useModalsStore()

  const handleClose = async (type) => {
    await modals.hide(type)
  }

  return (
    <>
      {children}

      <TransferModal isOpen={modals.transfer} onClose={() => handleClose('transfer')} />
      <WithdrawalModal isOpen={modals.withdrawal} onClose={() => handleClose('withdrawal')} />
      <ChangeCountryModal isOpen={modals.changeCountry} onClose={() => handleClose('changeCountry')} />
    </>
  )
}

export default ModalsProvider
