import omit from 'lodash/omit'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CURRENCY_BASE } from 'shared/constants'

import useUserStore from 'shared/stores/user'

import { getCurrencyAlias } from 'shared/utils/billing'
import { asUSCR } from 'shared/utils/money'
import { roundDown } from 'shared/utils/number'
import { getBalance, isGuest } from 'shared/utils/user'

import { validateTransfer } from 'shared/api/finances'

import Button from 'shared/components/Button'
import FormField from 'shared/components/forms/FormField'
import Input from 'shared/components/forms/Input'

import useStepsStore from '../stores/steps'

const MAX_AMOUNT = 10_000

const AmountForm = ({ formWrapper }) => {
  const user = useUserStore()
  const steps = useStepsStore()
  const { getFieldError, form, setFormValues, setFormErrors } = formWrapper
  const { t } = useTranslation()
  const isUserGuest = isGuest(user)
  const balance = isUserGuest ? 0 : getBalance(user)
  const disabled = form.formState.errors.amount || form.formState.errors.recipient || !form.getValues().confirmLogin
  const [validationLoading, setValidationLoading] = useState(false)

  const handleSetMaxAmount = (ev) => {
    ev.preventDefault()

    const amount = roundDown(balance)

    setFormValues({ amount: amount > MAX_AMOUNT ? MAX_AMOUNT : amount })
    form.trigger('amount')
  }

  const handleClickSubmit = () => {
    const data = omit(form.getValues(), ['confirmLogin'])

    setValidationLoading(true)
    validateTransfer({
      amount: data.amount,
      recipient: data.recipient.trim(),
    })
      .then(() => {
        steps.setActiveStep(1)
      })
      .catch((err) => {
        if (err.errors) {
          setFormErrors(err.errors)
        }
      })
      .finally(() => setValidationLoading(false))
  }

  return (
    <>
      <div className="finance-form--fields">
        <FormField error={getFieldError('amount')}>
          <label htmlFor="amount" className="control-label">
            {t('Сумма')}
          </label>
          <div className="form-field">
            <Input register={form.register} name={'amount'} />
            <div className="form-field--option">{getCurrencyAlias(CURRENCY_BASE)}</div>
          </div>
          <div className="form-group--controls">
            <a href="#" onClick={(ev) => handleSetMaxAmount(ev)}>
              max: {balance > MAX_AMOUNT ? MAX_AMOUNT : asUSCR(balance)}
            </a>
          </div>
        </FormField>
        <FormField error={getFieldError('recipient')}>
          <Input label={t('Логин получателя')} register={form.register} name={'recipient'} />
          <div className="help-message">{t('Указывайте только реальный логин')}</div>
        </FormField>

        <FormField className="form-group">
          <div className="checkbox">
            <input {...form.register('confirmLogin')} name={'confirmLogin'} type="checkbox" id="confirmLogin" />
            <label htmlFor="confirmLogin">
              <span className="box" />
              <span className="text">{t('Я подтверждаю правильность введенного логина')}</span>
            </label>
          </div>
        </FormField>
      </div>
      <div className="finance-form--details">
        <div className="finance-transaction-details">
          <div className="finance-transaction-details--item">
            <div className="finance-transaction-details--title">{t('Минимальная сумма')}:</div>
            <div className="finance-transaction-details--value">{asUSCR(1)}</div>
          </div>
          <div className="finance-transaction-details--item">
            <div className="finance-transaction-details--title">{t('Максимальная сумма')}:</div>
            <div className="finance-transaction-details--value">{asUSCR(MAX_AMOUNT)}</div>
          </div>
          <div className="finance-transaction-details--item">
            <div className="finance-transaction-details--title">{t('Комиссия')}:</div>
            <div className="finance-transaction-details--value">{asUSCR(0)}</div>
          </div>
          <div className="finance-transaction-details--item">
            <div className="finance-transaction-details--title feedback-message">
              {t(
                'Стабильный внутренний токен {{token}} будет переводиться 1:1 во внутренний токен выбранного проекта.',
                { token: 'U$D' },
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="finance-form--total">
        <div className="finance-form-total">
          <div className="finance-form-total--title">{t('Итого')}:</div>
          <div className="finance-form-total--value">{asUSCR(form.getValues().amount || 0)}</div>
        </div>
      </div>

      <div className="finance-form--buttons">
        <Button
          variant="primary"
          classes={['btn-lg', 'btn_sqr', 'btn-modal']}
          loading={validationLoading}
          onClick={handleClickSubmit}
          disabled={disabled}
        >
          <span>{t('Продолжить')}</span>
        </Button>
      </div>
    </>
  )
}

export default AmountForm
