import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import useMount from 'react-use/lib/useMount'
import useSetState from 'react-use/lib/useSetState'
import { CURRENCY_BASE, CURRENCY_CR, USER_STATUS_ACTIVE } from 'shared/constants'

import useUserStore from 'shared/stores/user'

import { getCurrencyAlias } from 'shared/utils/billing'
import { asMoney, asNumber } from 'shared/utils/money'
import { countryFlag } from 'shared/utils/ui'
import { getAvatar, getFullName, getTelegramLink, isMaxRank } from 'shared/utils/user'

import { getList } from 'shared/api/structure'

import Pagination from 'shared/components/Pagination'
import Checkbox from 'shared/components/forms/Checkbox'
import Input from 'shared/components/forms/Input'

import ParticipantModal from './ParticipantModal'

const MAX_PAGE_SIZE = 10

const TYPE_ACTIVE = 'active'
const TYPE_PASSIVE = 'passive'
const TYPE_ARCHIVED = 'archived'

const TYPE_CLASSES = {
  [TYPE_ACTIVE]: '',
  [TYPE_PASSIVE]: 'user-data-container_passive',
  [TYPE_ARCHIVED]: 'user-data-container_archived',
}

const TYPE_LABELS = {
  [TYPE_ACTIVE]: 'Активный',
  [TYPE_PASSIVE]: 'Пассивный',
  [TYPE_ARCHIVED]: 'Архивный',
}

const Tree = () => {
  const { t } = useTranslation()
  const user = useUserStore()

  const referrer = user.referrer
    ? {
        login: user.referrer.login,
        status: user.referrer.status,
        system: user.referrer.system,
        profile: user.referrer,
      }
    : null
  const refCountry = referrer.profile.country
  const rankClass = referrer.login === 'admin' ? '' : isMaxRank(referrer.rank) ? 'agent_vip' : 'agent_agent'
  const [type, setType] = useState()

  const [search, setSearch] = useState('')
  const [isSearch, setIsSearch] = useState('')
  const [data, setData] = useState({
    data: [],
    page: 1,
    count: 0,
  })
  const [openedRows, setOpenedRows] = useState([])
  const [childrenRows, setChildren] = useSetState({})
  const [loading, setLoading] = useState(false)
  const [loadingChildren, setLoadingChildren] = useState(false)
  const [clickedRow, setClickedRow] = useState(null)

  const [modal, toggleModal] = useSetState({
    open: false,
    participant: null,
  })

  const clearOpenedRows = () => setOpenedRows([])

  const fetchData = (page = 1, id, s) => {
    setLoading(true)
    clearOpenedRows()

    getList(page, id, s, type)
      .then((res) => {
        setData(res)
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  const toggleRows = (id) => {
    fetchChildren(id).then(() => {
      const ids = [...openedRows]

      const openedIndex = ids.findIndex((row) => row === id)

      if (openedIndex === -1) {
        ids.push(id)
      } else {
        ids.splice(openedIndex, 1)
      }
      setOpenedRows(ids)
    })
  }

  const fetchChildren = (id) => {
    const isOpen = openedRows.find((row) => row === id)

    if (childrenRows[id] && isOpen) {
      return Promise.resolve(false)
    }

    setLoadingChildren(true)
    return getList(1, id, search, type)
      .then((res) => {
        setChildren({
          [id]: res.data,
        })
        setLoadingChildren(false)
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingChildren(false))
  }

  const handleClick = (e) => {
    e.preventDefault()
    if (search.length >= 3) {
      fetchData(1, undefined, search.trim())
      setIsSearch(search.trim())
    }
  }

  const handleClearSearch = (e) => {
    e.preventDefault()
    setSearch('')

    if (!isSearch) {
      return false
    }
    setIsSearch('')
    fetchData(1, '')
  }

  const handleSearchChange = (e) => {
    setSearch(e.target.value)
  }

  const handleKeyup = (event) => {
    if (event.keyCode === 13 && search.length >= 3) {
      event.preventDefault()
      fetchData(1, undefined, search.trim())
      setIsSearch(search.trim())
    }
  }

  useEffect(() => {
    fetchData(1, undefined, search.trim())
  }, [type])

  useMount(() => {
    fetchData(1)
  })

  const handleChangeType = (ev) => {
    const val = ev.target.value

    if (type === val) {
      setType(null)
    } else {
      setType(ev.target.value)
    }
  }

  const handleOpenModal = (ev, participant) => {
    ev.preventDefault()

    toggleModal({ open: true, participant })
  }

  const renderRows = (el) => {
    const {
      id,
      participants = 0,
      activityStatus,
      purchases = { value: 0, currency: getCurrencyAlias(CURRENCY_BASE) },
      login,
      turnover: rowTurnover = { value: 0, currency: getCurrencyAlias(CURRENCY_BASE) },
      stakingTurnover: rowStakingTurnover = { value: 0, currency: CURRENCY_CR },
      level = 1,
    } = el
    const isVip = isMaxRank(el.rank)
    const country = el?.profile?.country
    const hasChildren = participants > 0
    const handleToggle = () => {
      if (!hasChildren || level >= 10) {
        return false
      }
      setClickedRow(id)
      toggleRows(id)
    }

    const isOpened = !!openedRows.find((el) => el === id)

    return (
      <li
        key={id}
        className={`structure-tree-list--item ${isOpened ? 'open' : ''} ${
          clickedRow === id && loadingChildren ? 'loading' : ''
        }`}
      >
        <div className="structure-tree-item">
          <a href="#" className="mobile-data-button btn-modal" onClick={(ev) => handleOpenModal(ev, el)} />

          <div className="structure-tree-item--level">{level}</div>

          <div className="structure-tree-item--controls" onClick={handleToggle}>
            <a
              className={`leaf-button ${!hasChildren || level >= 10 ? 'disabled' : ''} ${isOpened ? 'open' : ''}`}
              title={t(isOpened ? 'Закрыть' : 'Открыть')}
            />
          </div>

          <div className={`structure-tree-item--user-data`}>
            <div className={`user-data-container ${TYPE_CLASSES[activityStatus]}`}>
              <div className="user-data-container--user">
                <div className={`usercard usercard_sm  ${isVip ? 'agent_vip' : 'agent_agent'}`}>
                  <div className="usercard--image">
                    <img src={getAvatar(el)} alt="" />
                    {country && (
                      <span className="userflag">
                        <img
                          alt={country}
                          src={countryFlag(country)}
                          style={{
                            display: 'inline-block',
                            width: '1em',
                            height: '1em',
                            verticalAlign: 'middle',
                          }}
                        />
                      </span>
                    )}
                  </div>
                  <div className="usercard--content">
                    <div className="usercard--title">{getFullName(el)}</div>
                    <div className="usercard--link">
                      <a onClick={(ev) => handleOpenModal(ev, el)}>{login}</a>
                    </div>
                  </div>
                </div>
              </div>
              {activityStatus === TYPE_ACTIVE ? (
                <>
                  <div className="user-data-container--purchase">
                    <div className="structure-purchase">
                      <div className="structure-purchase--title">{t('Покупка')}:</div>
                      <div className="structure-purchase--value">
                        {asNumber(purchases.value, 2)} {getCurrencyAlias(purchases.currency)}
                      </div>
                    </div>
                  </div>
                  <div className="user-data-container--users">
                    <div className="structure-users">
                      <div className="structure-users--title" />
                      <div className="structure-users--value">{participants}</div>
                    </div>
                  </div>
                  <div className="user-data-container--value">
                    <div className="structure-value">
                      <div className="structure-value--title">{t('Оборот филиала')}:</div>
                      <div className="structure-value--value">
                        {asMoney(rowTurnover.value, rowTurnover.currency)}
                        &nbsp; &nbsp;&nbsp;
                        {asMoney(rowStakingTurnover.value, rowStakingTurnover.currency, 2)}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="user-data-container--value">
                  <div className="client-condition client-condition_passive">{t(TYPE_LABELS[activityStatus])}</div>
                </div>
              )}
              <div className="user-data-container--link">
                <a
                  href="#"
                  className="user-details-link btn-modal"
                  data-target="modalUserDetails"
                  onClick={(ev) => handleOpenModal(ev, el)}
                >
                  {t('Подробнее')}
                </a>
              </div>
            </div>
          </div>
        </div>

        <ul className="structure-tree-list">{childrenRows[id]?.map((el) => renderRows(el))}</ul>
      </li>
    )
  }

  return (
    <div className="layout-block">
      <div className="layout-block--header">
        <div className="layout-block--title">{t('Структура филиала')}</div>
      </div>

      <div className="layout-block--body">
        <div className="panel">
          <div className="panel-body">
            <div className="structure">
              <div className="structure--header">
                <div className="structure--header-item">
                  <div className="structure-sponsor">
                    <div className="structure-sponsor--agent">
                      <div className="structure-sponsor--agent-title">{t('Вышестоящий агент')}:</div>
                      <div className="structure-sponsor--agent-content">
                        <div className={`usercard ${rankClass}`}>
                          <div className="usercard--image">
                            <img src={getAvatar(referrer)} alt={referrer.login} />
                            {refCountry && (
                              <span className="userflag">
                                <img
                                  alt={refCountry}
                                  src={countryFlag(refCountry)}
                                  style={{
                                    display: 'inline-block',
                                    width: '1em',
                                    height: '1em',
                                    verticalAlign: 'middle',
                                  }}
                                />
                              </span>
                            )}
                          </div>
                          <div className="usercard--content">
                            <div className="usercard--title">
                              {referrer.system ? t('Администратор') : getFullName(referrer)}
                            </div>
                            {!referrer.system && (
                              <div className="usercard--link">
                                <a href={getTelegramLink(referrer)} disabled={referrer.status !== USER_STATUS_ACTIVE}>
                                  {referrer.login}
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="stricture-sponsor--controls">
                      <a
                        className="btn btn-light btn_sqr btn_dark"
                        href={getTelegramLink(referrer)}
                        disabled={referrer.status !== USER_STATUS_ACTIVE}
                      >
                        <svg width="1.9rem" height="1.6rem">
                          <use xlinkHref="#social-telegram" />
                        </svg>
                        <span>{t('Написать сообщение')}</span>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="structure--header-item">
                  <div className="structure-form">
                    <div className="form-group form-group_search">
                      <div className="form-field">
                        <Input
                          value={search}
                          placeholder={t('Поиск клиента')}
                          classes={['form-control_compact']}
                          onChange={handleSearchChange}
                          onKeyUp={handleKeyup}
                        />
                        <div className="form-field--option">
                          <a href="#" className="search-button" onClick={handleClick}>
                            {t('Поиск')}
                          </a>
                        </div>
                      </div>
                      {isSearch && (
                        <div className="form-group-message help-message">
                          <a href="#" onClick={handleClearSearch} className={isSearch ? '' : 'disabled'}>
                            {t('Отменить поиск')}
                          </a>
                        </div>
                      )}
                    </div>

                    <div className="form-group form-group_options">
                      <ul className="structure-options-list">
                        <li className="structure-options-list--item">
                          <Checkbox
                            name={TYPE_ACTIVE}
                            id={TYPE_ACTIVE}
                            value={TYPE_ACTIVE}
                            checked={type === TYPE_ACTIVE}
                            onChange={handleChangeType}
                            label={t('Активные')}
                          />
                        </li>
                        <li className="structure-options-list--item">
                          <Checkbox
                            label={t('Пассивные')}
                            name={TYPE_PASSIVE}
                            id={TYPE_PASSIVE}
                            value={TYPE_PASSIVE}
                            checked={type === TYPE_PASSIVE}
                            onChange={handleChangeType}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`structure--content ${loading ? 'loading' : ''}`}>
                {!data.data.length && !isSearch && (
                  <div className="structure-search-results structure-search-results_no-results">
                    <div className="structure-search-results--title" style={{ textAlign: 'center' }}>
                      {t('В вашей структуре пока никого нет')}
                    </div>
                  </div>
                )}
                {data.data.length === 0 && isSearch && !loading ? (
                  <div className="structure-search-results structure-search-results_no-results">
                    <div className="structure-search-results--title">
                      <Trans
                        i18nKey={'К сожалению, по вашему запросу <span>{{value}}</span> ничего не найдено.'}
                        components={{ span: <span /> }}
                        values={{ value: isSearch }}
                      />
                      <br /> <Trans i18nKey={'Пожалуйста, попробуйте изменить запрос поиска.'} />
                    </div>
                  </div>
                ) : (
                  <ul className="structure-tree-list">{data.data.map((el) => renderRows(el))}</ul>
                )}
              </div>
              <div className="structure--footer">
                <Pagination
                  page={data.page}
                  initialPage={data.page - 1}
                  pageCount={Math.ceil(data.count / MAX_PAGE_SIZE)}
                  onSelect={(num) => {
                    fetchData(num, undefined, isSearch)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {modal.open && (
        <ParticipantModal
          isOpen={modal.open}
          participant={modal.participant}
          onClose={() => toggleModal({ open: false, participant: null })}
        />
      )}
    </div>
  )
}

export default Tree
