import { useState } from 'react'
import useSetState from 'react-use/lib/useSetState'

import useAmbassadorStore from 'shared/stores/ambassador'

import { getAmbassadorHistory, getAmbassadorStats, getAmbassadorStatus } from 'shared/api/ambassador'

import { PERIOD_ALL, PERIOD_CUSTOM, VIEW_TYPE_TABLE } from '../constants'

const useAmbassador = () => {
  const ambassador = useAmbassadorStore()
  const [currentView, setCurrentView] = useState(VIEW_TYPE_TABLE)
  const [history, setHistory] = useSetState({
    data: [],
    page: 1,
    size: 10,
    count: 0,
  })
  const [stats, setStats] = useSetState({
    totalBonus: {
      value: 0,
      currency: 'USCR',
    },
    bonuses: {
      realty: {
        value: 0,
        currency: 'USCR',
      },
      staking: {
        value: 0,
        currency: 'CR',
      },
    },
    frozenBonuses: {
      realty: {
        value: 0,
        currency: 'USCR',
      },
      staking: {
        value: 0,
        currency: 'CR',
      },
    },
    totalTurnover: {
      value: 0,
      currency: 'USCR',
    },
    turnovers: {
      realty: {
        value: 0,
        currency: 'USCR',
      },
      staking: {
        value: 0,
        currency: 'CR',
      },
    },
    activeParticipants: 0,
    passiveParticipants: 0,
    countries: [],
  })

  const fetchAmbassadorStatus = (firstFetch = false) => {
    if (firstFetch) {
      ambassador.setStatusLoading(true)
    }
    getAmbassadorStatus()
      .then((res) => ambassador.setStatus(res))
      .catch((e) => console.log(e))
      .finally(() => ambassador.setStatusLoading(false))
  }

  const fetchHistory = (page = 1, size = 10, country = 'am', period = PERIOD_ALL, start = '', end = '') => {
    if (period === PERIOD_CUSTOM && (!start || !end)) return false
    getAmbassadorHistory(page, size, country, period, start ?? '', end ?? '').then((res) => setHistory(res))
  }

  const fetchStats = (country = 'am', period = PERIOD_ALL, start = '', end = '') => {
    if (period === PERIOD_CUSTOM && (!start || !end)) return false
    getAmbassadorStats(country, period, start ?? '', end ?? '').then((res) => setStats(res))
  }

  return {
    status: ambassador.status,
    statusLoading: ambassador.statusLoading,
    history,
    stats,
    fetchAmbassadorStatus,
    fetchStats,
    fetchHistory,
    currentView,
    setCurrentView,
  }
}

export default useAmbassador
