import { BLOCKCHAIN_BSC, CURRENCY_USDT } from 'shared/constants'
import create from 'zustand'

const defaultState = [
  {
    number: 1,
    label: 'Выбор валюты',
    active: true,
    payload: {
      amount: '',
      currencyAmount: '',
      account: '',
      toCurrency: CURRENCY_USDT,
      toBlockchain: BLOCKCHAIN_BSC,
      confirm: false,
      errors: {},
    },
  },
  {
    number: 2,
    label: 'Безопасность',
    active: false,
    payload: {
      telegramCode: '',
      errors: {},
    },
  },
  {
    number: 3,
    label: 'Подтверждение',
    active: false,
    payload: {},
  },
]

const useStore = create((set, get) => ({
  steps: defaultState,
  getStep: (num) => get().steps.find((el) => el.number === num),
  getActiveStep: () => get().steps.find((el) => el.active),
  setActiveStep: (num, payload = {}) =>
    set((state) => ({
      steps: state.steps.map((step) => {
        if (step.number === num) {
          return { ...step, active: true, payload: { ...step.payload, ...payload } }
        }
        return { ...step, active: false, passed: step.number < num }
      }),
    })),
  updateStep: (num, payload = {}) =>
    set((state) => ({
      steps: state.steps.map((step) => {
        return step.number === num ? { ...step, payload: { ...step.payload, ...payload } } : step
      }),
    })),
  resetSteps: () =>
    set(() => ({
      steps: defaultState,
    })),
  composeDataSteps: () => get().steps.reduce((result, step) => ({ ...result, ...step.payload }), {}),
}))

export default useStore
