import create from 'zustand'

const useStore = create((set) => ({
  list: { data: [], page: 1, count: 0 },
  new: { data: [], page: 1, count: 0 },
  dropdownData: [],
  setDropdownData: (data) => set(() => ({ dropdownData: data })),
  setNotifications: (data) => set(() => ({ list: data })),
  updateNotifications: (data) => set(() => ({ new: data })),
}))

export default useStore
