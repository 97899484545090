import React from 'react'
import { useTranslation } from 'react-i18next'
import useMount from 'react-use/lib/useMount'
import useSetState from 'react-use/lib/useSetState'
import { TRANSACTION_STATUS_CANCELLED, TRANSACTION_STATUS_NEW, TRANSACTION_STATUS_PROCESSED } from 'shared/constants'

import { getCurrencyAlias } from 'shared/utils/billing'
import { parseAndFormat } from 'shared/utils/date'

import { getFinanceTransactions } from 'shared/api/finances'

import Pagination from 'shared/components/Pagination'

const MAX_PAGE_SIZE = 5
const STATUS_CLASSES = {
  [TRANSACTION_STATUS_NEW]: 'wait',
  [TRANSACTION_STATUS_PROCESSED]: 'success',
  [TRANSACTION_STATUS_CANCELLED]: 'cancel',
}

const STATUSES = {
  [TRANSACTION_STATUS_NEW]: 'На проверке',
  [TRANSACTION_STATUS_PROCESSED]: 'Обработана',
  [TRANSACTION_STATUS_CANCELLED]: 'Отмена',
}

const TransactionsTable = () => {
  const { t } = useTranslation()
  const [data, setData] = useSetState({
    data: [],
    page: 1,
    count: 0,
    loading: false,
  })

  const fetchData = (page = 1, size = MAX_PAGE_SIZE) => {
    if (!data.loading) {
      setData({ loading: true })
    }

    getFinanceTransactions(page, size)
      .then((res) => setData({ ...res, loading: false }))
      .catch((err) => console.log(err))
      .finally(() => setData({ loading: false }))
  }

  useMount(() => {
    fetchData()
  })

  return (
    <div className="layout-block--body">
      <div className="panel">
        <div className="panel-body">
          <div className={`table-wrapper`}>
            <table className={`transactions-table transactions-table_history${data.loading ? ' loading' : ''}`}>
              <thead>
                <tr>
                  <th>{t('Дата')}</th>
                  <th>{t('Детали операции')}</th>
                  <th>{t('Сумма')}</th>
                  <th>{t('Статус')}</th>
                </tr>
              </thead>
              <tbody>
                {data.data.length === 0 && (
                  <tr>
                    <td colSpan="4" className="td-empty">
                      {t('Нет данных')}
                    </td>
                  </tr>
                )}
                {data.data.map((log, key) => (
                  <tr key={key}>
                    <td>{parseAndFormat(log.createdAt)}</td>
                    <td data-title={t('Операция')}>
                      <div>{log.description}</div>
                    </td>
                    <td data-title={t('Сумма')}>
                      <div>
                        {log.amount?.value ?? 0} {getCurrencyAlias(log.amount?.currency)}
                      </div>
                    </td>
                    <td data-title={t('Статус')}>
                      <div className={`text-status ${STATUS_CLASSES[log.status]}`}>{t(STATUSES[log.status])}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <Pagination
            page={data.page}
            initialPage={data.page - 1}
            pageCount={Math.ceil(data.count / MAX_PAGE_SIZE)}
            onSelect={(num) => {
              fetchData(num, MAX_PAGE_SIZE)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default TransactionsTable
