import get from 'lodash/get'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSetState from 'react-use/lib/useSetState'

import useSettings from 'shared/hooks/useSettings'

import Button from 'shared/components/Button'
import Modal from 'shared/components/Modal'

const CountryModal = ({ isOpen = false, data = {}, onClose }) => {
  const { i18n, t } = useTranslation()
  const { countries, fetchStates } = useSettings()

  const [lastRegion, setLastRegion] = useState()

  const defaultState = {
    country: {
      code: null,
      label: '',
      percent: 0,
    },
    region: {
      code: null,
      label: '',
      percent: 0,
    },
  }
  const [preparedData, setPreparedData] = useSetState(defaultState)

  const language = i18n.language || 'en'

  useEffect(() => {
    if (Object.keys(data).length > 0 && Object.keys(countries).length > 0) {
      const label = get(countries, `${data.country.toUpperCase()}.translations.${language.toUpperCase()}.common`)

      setPreparedData({
        country: {
          code: data.country,
          label: label,
          percent: data.region ? null : data.percent,
        },
      })

      if (data.region && data.region !== lastRegion) {
        fetchStates(data.country).then((list) => {
          const label = get(list, `${data.region.toUpperCase()}.translations.${language.toUpperCase()}.common`)

          setLastRegion(data.region)
          setPreparedData({
            region: {
              code: data.region,
              label: label,
              percent: data.percent,
            },
          })
        })
      }
    }
  }, [data, countries])

  const handleClose = (ev) => {
    ev.preventDefault()

    if (preparedData.region.code) {
      setPreparedData(defaultState)
      setLastRegion(null)
    }

    onClose && onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} additionalClassName={'modal_sm'}>
      <a href="#" className="modal-close btn-modal-close" title="Закрыть" onClick={handleClose}>
        {t('Закрыть')}
      </a>
      <div className="modal-content">
        <div className="modal-body">
          <div className="finance-form">
            <div className="finance-form--header">
              <div className="finance-form--title">{t('Страна и регион')}</div>
            </div>
            <div className="finance-form--description">
              <div className="form-group">
                <label htmlFor="country" className="control-label">
                  {t('Страна')}
                </label>
                <div className="form-field">
                  <input
                    id="country"
                    name="country"
                    type="text"
                    className="form-control"
                    value={preparedData.country.label}
                    readOnly
                  />
                  {preparedData.country.percent && (
                    <div className="form-field--option">{preparedData.country.percent}%</div>
                  )}
                </div>
              </div>
              {preparedData.region.code && (
                <div className="form-group">
                  <label htmlFor="region" className="control-label">
                    {t('Регион')}
                  </label>
                  <div className="form-field">
                    <input
                      id="region"
                      name="region"
                      type="text"
                      className="form-control"
                      value={preparedData.region.label}
                      readOnly
                    />
                    {preparedData.region.percent && (
                      <div className="form-field--option">{preparedData.region.percent}%</div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="finance-form--buttons">
              <Button classes={['btn-modal-close']} onClick={handleClose} variant={'primary'}>
                {t('Закрыть')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CountryModal
