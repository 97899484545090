import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import useModalsStore from 'shared/stores/modals'
import useUserStore from 'shared/stores/user'

import { asUSCR } from 'shared/utils/money'
import { getBalance } from 'shared/utils/user'

const Balance = () => {
  const { t } = useTranslation()
  const user = useUserStore()
  const modals = useModalsStore()
  const balance = useMemo(() => asUSCR(getBalance(user), 2), [user])

  const handleOpenModal = (ev, type) => {
    ev.preventDefault()
    modals.show(type)
  }

  return (
    <div className="dashboard-grid--item">
      <div className="panel">
        <div className="panel-body">
          <div className="my-balance">
            <div className="my-balance--header">
              <div className="stats-value">
                <div className="stats-value--title">{t('Баланс')}</div>
                <div className="stats-value--value-row">
                  <div className="stats-value--value">{asUSCR(balance)}</div>
                </div>
              </div>
            </div>

            <div className="my-balance--controls">
              <div className="my-balance--controls-buttons">
                <a
                  href="#"
                  className="btn btn-primary btn-modal"
                  data-target="withdrawModal"
                  onClick={(ev) => handleOpenModal(ev, 'withdrawal')}
                >
                  {t('Вывести')}
                </a>
                <a
                  href="#"
                  className="btn btn-modal"
                  data-target="transferModal"
                  onClick={(ev) => handleOpenModal(ev, 'transfer')}
                >
                  {t('Перевести')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Balance
