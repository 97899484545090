import React from 'react'
import ReactPaginate from 'react-paginate'

export default ({ pageCount = 1, page = 1, onSelect, initialPage = 0 }) => {
  const handleSelectPage = ({ selected }) => {
    if (selected < 0) {
      return
    }
    onSelect && onSelect(selected + 1)
  }

  if (pageCount <= 1) {
    return null
  }

  return (
    <div className="page-nav">
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        forcePage={page > 1 ? page - 1 : 0}
        previousLabel={<i className="page-nav-prev" />}
        previousLinkClassName="prev-page"
        nextLabel={<i className="page-nav-next" />}
        nextLinkClassName="next-page"
        onPageChange={handleSelectPage}
        hrefBuilder={() => '#'}
        forcePage={initialPage}
      />
    </div>
  )
}
