import QRCode from 'qrcode.react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Modal from 'shared/components/Modal'

const QRModal = ({ isOpen = false, onClose, value }) => {
  const { t } = useTranslation()

  const handleClose = (ev) => {
    ev.preventDefault()
    onClose && onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} additionalClassName={'modal_sm'}>
      <a href="#" className="modal-close btn-modal-close" title={t('Закрыть')} onClick={handleClose}>
        {t('Закрыть')}
      </a>
      <div className="modal-content">
        <div className="modal-body">
          <div className="qr-form">
            <div className="qr-form--header">
              <div className="qr-form--title">{t('QR-код')}</div>
            </div>
            <div className="qr-form--content">{t('Агентская ссылка в виде QR-кода')}</div>
            <div className="qr-form--image">
              <QRCode value={value} />
            </div>
            <div className="qr-form--buttons">
              <a href="#" className="btn btn-primary btn-modal-close" onClick={handleClose}>
                {t('Закрыть')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default QRModal
