import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import useMount from 'react-use/lib/useMount'

import { parseAndFormat } from 'shared/utils/date'
import { declOfNum } from 'shared/utils/ui'

import Pagination from 'shared/components/Pagination'

import {
  SUPPORT_STATUS_DELETE,
  SUPPORT_STATUS_DONE,
  SUPPORT_STATUS_NEW,
  SUPPORT_STATUS_REOPEN,
  SUPPORT_STATUS_WORK,
} from '../constants'
import useTicketsData from '../hooks/useTicketsData'

const MAX_PAGE_SIZE = 10

const STATUS_CLASSES = {
  [SUPPORT_STATUS_NEW]: 'new',
  [SUPPORT_STATUS_WORK]: 'wait',
  [SUPPORT_STATUS_REOPEN]: 'new',
  [SUPPORT_STATUS_DONE]: 'success',
  [SUPPORT_STATUS_DELETE]: 'cancel',
}

const STATUSES = {
  [SUPPORT_STATUS_NEW]: 'Открыто',
  [SUPPORT_STATUS_WORK]: 'В работе',
  [SUPPORT_STATUS_REOPEN]: 'Открыто заново',
  [SUPPORT_STATUS_DONE]: 'Закрыто',
  [SUPPORT_STATUS_DELETE]: 'Удалено',
}

const TicketsList = () => {
  const { t } = useTranslation()

  const { ticketsList, fetchTicketsData } = useTicketsData()

  useMount(() => {
    fetchTicketsData(1, 10)
  })

  return (
    <div className="layout-block">
      <div className="layout-block--body">
        <div className="panel">
          <div className={`panel-body ${ticketsList.loading ? 'loading' : ''}`}>
            {!ticketsList.data.length ? (
              <div className="empty-data">{t('Нет обращений')}</div>
            ) : (
              <>
                <div className="table-wrapper">
                  <table className={`support-tickets-table `}>
                    <thead>
                      <tr>
                        <th className="td-label" />
                        <th>{t('№ обращения')}</th>
                        <th>{t('Тема')}</th>
                        <th>{t('Статус')}</th>
                        <th>{t('Дата')}</th>
                        <th>{t('Сообщения')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ticketsList.data.map((log, key) => (
                        <tr key={key} className={log.read === false ? 'new' : ''}>
                          <td className="td-label" />
                          <td className="td-num">
                            <Link to={`/support/${log.id}`}>№ {log.id}</Link>
                          </td>
                          <td className="td-link">
                            <Link to={`/support/${log.id}`}>{log.subject}</Link>
                          </td>
                          <td className="td-status">
                            <div className={`text-status ${STATUS_CLASSES[log.status]}`}>{t(STATUSES[log.status])}</div>
                          </td>
                          <td className="td-date">{parseAndFormat(log.createdAt)}</td>
                          <td className="td-comment">
                            {t(`{{count}} ${declOfNum(log.messages, ['сообщение', 'сообщения', 'сообщений'])}`, {
                              count: log.messages,
                            })}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  page={ticketsList.page}
                  initialPage={ticketsList.page - 1}
                  pageCount={Math.ceil(ticketsList.count / MAX_PAGE_SIZE)}
                  onSelect={(num) => {
                    fetchTicketsData(num, MAX_PAGE_SIZE)
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TicketsList
