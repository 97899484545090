import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import useUserStore from 'shared/stores/user'

// import { getLandingUrl } from 'shared/utils/ui'
import { isGuest } from 'shared/utils/user'

import Main from './Main'

const ProtectedRoute = () => {
  const user = useUserStore()
  const navigate = useNavigate()

  if (isGuest(user)) {
    navigate('/auth/login')
  }

  return (
    <Main>
      <Outlet />
    </Main>
  )
}

export default ProtectedRoute
