import { get as getItem, set as setItem } from 'local-storage'
import { LOCALSTORAGE_KEY } from 'shared/constants'
import create from 'zustand'

const STORAGE_KEY = `${LOCALSTORAGE_KEY}_telegram_auth`

const defaultState = {
  session: {
    chatId: null,
    username: null,
    firstName: null,
    lastName: null,
    photoUrl: null,
    hash: null,
    extra: {
      refKey: null,
      language: null,
    },
  },
  errors: null,
}

const initState = getItem(STORAGE_KEY) || defaultState

const useStore = create((set) => ({
  ...initState,
  setSession: (payload) =>
    set(() => ({
      session: payload,
    })),
  setErrors: (errors) =>
    set(() => ({
      errors,
    })),
  reset: () =>
    set(() => ({
      state: initState,
    })),
}))

useStore.subscribe((state) => setItem(STORAGE_KEY, { session: state.session, errors: state.errors }))

export default useStore
