import React from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { useTranslation } from 'react-i18next'
import { VIEW_TYPE_TAB_OPTIONS } from 'shared/constants'

import PeriodSelector from 'shared/components/PeriodSelector'
import Tabs from 'shared/components/Tabs'

import useLevelsStore from '../stores/levels'

const FilterHeader = () => {
  const { t } = useTranslation()
  const { setFilters, setCurrentView, currentView } = useLevelsStore()

  return (
    <div className={'stats-filters'}>
      <div className="stats-filters--item">
        <Tabs options={VIEW_TYPE_TAB_OPTIONS} activeTab={currentView} onChangeTab={(tab) => setCurrentView(tab)} />
      </div>

      <PeriodSelector onChangePeriod={setFilters} />
    </div>
  )
}

export default FilterHeader
