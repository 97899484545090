import get from 'lodash/get'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { NOTIFICATION_FILTER_NEW, NOTIFICATION_FILTER_READ } from 'shared/constants'

import Breadcrumbs from 'components/Breadcrumbs'

import useNotifications from 'shared/hooks/useNotifications'

import useNotificationsStore from 'shared/stores/notifications'

import { parseAndFormat } from 'shared/utils/date'
import { buildUrl } from 'shared/utils/notifications'

import Pagination from 'shared/components/Pagination'

import { NOTIFICATIONS_ICON_MAP } from '../../components/Notification'

const MAX_PAGE_SIZE = 10
const Notifications = () => {
  const { t } = useTranslation()
  const [selectedTab, setSelectedTab] = useState(NOTIFICATION_FILTER_NEW)
  const notifications = useNotificationsStore()
  const { fetchList, hideItem, list } = useNotifications()
  const [currentNotification, setCurrentNotification] = useState(null)

  useEffect(() => {
    fetchList(1, 10, NOTIFICATION_FILTER_NEW)
  }, [])

  useEffect(() => {
    if (!list.loading) {
      fetchList(1, 10, selectedTab)
    }
  }, [selectedTab, notifications.new.count])

  const handleHideNotification = (e, el) => {
    e.preventDefault()
    setCurrentNotification(el.id)
    hideItem([el.id], undefined, () => {
      setCurrentNotification(null)
    })
  }

  return (
    <div className={'page'}>
      <div className={'page--header'}>
        <Breadcrumbs />
        <div className="page--header-container">
          <div className="page--title">{t('Уведомления')}</div>
        </div>
      </div>

      <div className="page--content">
        <div className="all-notifications">
          <div className="all-notifications--header">
            <div className="btn-group">
              <button
                className={`btn btn-light ${selectedTab === NOTIFICATION_FILTER_NEW ? 'btn-primary' : ''}`}
                onClick={() => setSelectedTab(NOTIFICATION_FILTER_NEW)}
              >
                <span>
                  {t('Новые')}
                  <span className="num">({notifications.new.count})</span>
                </span>
              </button>
              <button
                className={`btn btn-light ${selectedTab === NOTIFICATION_FILTER_READ ? 'btn-primary' : ''}`}
                onClick={() => setSelectedTab(NOTIFICATION_FILTER_READ)}
              >
                <span>{t('Прочитанные')}</span>
              </button>
            </div>
          </div>

          <div className="all-notifications--container">
            <div className="all-notifications--content">
              {!list.data.length && (
                <div className="notifications-empty">
                  <div className="title">{t('Новых уведомлений нет')}</div>
                </div>
              )}
              <ul className="notifications-list">
                {list.data.map((el) => {
                  return (
                    <li
                      key={el.id}
                      className="notifications-list__item"
                      style={{
                        opacity: currentNotification === el.id ? '0.3' : '1',
                      }}
                    >
                      <div className="type-icon">{NOTIFICATIONS_ICON_MAP[el.type](get(el, 'extra.avatar'))}</div>
                      <div className="content">
                        <div className="title">
                          {buildUrl(el) ? (
                            <Link to={buildUrl(el)}>{el.message || el.title}</Link>
                          ) : (
                            <span>{el.message || el.title}</span>
                          )}
                        </div>

                        <div className="date">{parseAndFormat(el.date)}</div>
                      </div>
                      {selectedTab === NOTIFICATION_FILTER_NEW && (
                        <a
                          href="#"
                          className="btn-notification-remove"
                          title={t('Удалить')}
                          onClick={(e) => handleHideNotification(e, el)}
                        >
                          {t('Удалить')}
                        </a>
                      )}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>

          <div className="all-notifications--footer">
            <Pagination
              page={list.page}
              initialPage={list.page - 1}
              pageCount={Math.ceil(list.count / MAX_PAGE_SIZE)}
              onSelect={(num) => {
                fetchList(num, MAX_PAGE_SIZE, selectedTab)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notifications
