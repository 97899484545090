import React from 'react'

const Button = ({
  as: Component = 'button',
  disabled = false,
  variant = '',
  children,
  loading = false,
  preferredClass = 'btn',
  classes = [],
  onClick = () => {},
  ...rest
}) => {
  const handleClick = (e) => {
    if (disabled) {
      return false
    }
    onClick(e)
  }

  if (Component === 'a') {
    rest.href = '#'
  }

  return (
    <Component
      {...rest}
      className={`${preferredClass} ${variant ? `${preferredClass}-${variant}` : ''} ${disabled ? 'disabled' : ''} ${
        loading ? 'btn-loading' : ''
      } ${classes.join(' ')}`}
      onClick={handleClick}
    >
      {children}
    </Component>
  )
}

export default Button
