import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { asMoney } from 'shared/utils/money'
import { countryFlag } from 'shared/utils/ui'
import { getAvatar, getFullName, getTelegramLink, getUserRankLabel, isMaxRank } from 'shared/utils/user'

import { getParticipantById } from 'shared/api/structure'

import Modal from 'shared/components/Modal'

const TYPE_ACTIVE = 'active'
const TYPE_PASSIVE = 'passive'

const ParticipantModal = ({ participant, isOpen = false, onClose }) => {
  const { t } = useTranslation()

  const [data, setData] = useState(null)
  const [, setLoading] = useState(false)
  const maxRank = isMaxRank(data?.rank)
  const country = data?.profile?.country

  const handleClose = (ev) => {
    ev.preventDefault()
    onClose && onClose()
  }

  const fetchData = () => {
    setLoading(true)
    getParticipantById(participant?.id)
      .then((res) => setData(res))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (participant) {
      fetchData()
    }
  }, [participant?.id, isOpen])

  if (!data) {
    return null
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} additionalClassName={'modal_sm'}>
      <a href="#" className="modal-close btn-modal-close" title={t('Закрыть')} onClick={handleClose}>
        {t('Закрыть')}
      </a>
      <div className="modal-content">
        <div className="modal-body">
          <div className="client-details">
            <div className="client-details--header">
              <div className="client-details--name">{getFullName(data)}</div>
              <div className="client-details--status 11">
                {maxRank ? `VIP ${t('Агент')}` : t(getUserRankLabel(data.rank))}
              </div>
            </div>
            <div className="client-details--card">
              <div className="client-details--card-content">
                <div className="usercard">
                  <div className="usercard--image">
                    <img src={getAvatar(data)} alt={data.login} />
                    {country && (
                      <span className="userflag">
                        <img
                          alt={country}
                          src={countryFlag(country)}
                          style={{
                            display: 'inline-block',
                            width: '1em',
                            height: '1em',
                            verticalAlign: 'middle',
                          }}
                        />
                      </span>
                    )}
                  </div>
                  <div className="usercard--content">
                    <div className="usercard--title">{getFullName(data)}</div>
                    <div className="usercard--link">{data.login}</div>
                  </div>
                </div>
              </div>

              <div className="client-details--card-buttons">
                <a
                  href={getTelegramLink(data)}
                  className="btn btn-light"
                  disabled={![TYPE_ACTIVE, TYPE_PASSIVE].includes(data.activityStatus)}
                >
                  <svg>
                    <use xlinkHref="#social-telegram" />
                  </svg>
                  <span>{t('Написать сообщение')}</span>
                </a>
              </div>
            </div>
            <div className="client-details--total">
              <div className="client-details--total-title">{t('Покупка токенов')}:</div>
              <div className="client-details--total-value">
                {asMoney(data.purchases.value, data.purchases.currency)}
              </div>
            </div>
            {participant?.presalePurchases && (
              <div className="client-details--total">
                <div className="client-details--total-title">{t('Покупка токенов (предпродажа)')}:</div>
                <div className="client-details--total-value">
                  {asMoney(participant?.presalePurchases?.value, participant?.presalePurchases?.currency)}
                </div>
              </div>
            )}
            <div className="client-details--total">
              <div className="client-details--total-title">{t('Клиентов всего')}:</div>
              <div className="client-details--total-value">{data.participants}</div>
            </div>
            <div className="client-details--total">
              <div className="client-details--total-title">{t('Оборот офиса')}:</div>
              <div className="client-details--total-value">
                {asMoney(data.personalTurnover.value, data.personalTurnover.currency)}
              </div>
            </div>
            <div className="client-details--buttons">
              <a href="#" className="btn btn-primary btn-lg btn-modal-close" title={t('Закрыть')} onClick={handleClose}>
                {t('Закрыть')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ParticipantModal
