import React from 'react'
import { useTranslation } from 'react-i18next'

import Modal from 'shared/components/Modal'

import AmountForm from './components/AmountForm'
import Confirmation from './components/Confirmation'
import SecurityForm from './components/SecurityForm'
import useStepsStore from './stores/steps'

const WithdrawalModal = ({ isOpen = false, onClose }) => {
  const { t } = useTranslation()
  const steps = useStepsStore()

  const handleClose = (ev) => {
    ev.preventDefault()
    steps.resetSteps()
    onClose && onClose()
  }

  const STEPS = {
    1: () => <AmountForm />,
    2: () => <SecurityForm />,
    3: () => <Confirmation />,
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} additionalClassName={'modal_smd'}>
      <a href="#" className="modal-close btn-modal-close" title="Закрыть" onClick={handleClose}>
        {t('Закрыть')}
      </a>
      <div className="modal-content">
        <div className="modal-body">
          <div className="finance-form">
            <div className="finance-form--header">
              <div className="finance-form--title">{t('Вывод средств')}</div>
              <div className="finance-form--subtitle">{t('Выводите средства с помощью доступных методов')}</div>
            </div>
            <div className="finance-form--steps">
              <ul className="buy-token-steps">
                {steps.steps.map((step, key) => (
                  <li
                    className={`buy-token-steps--item${
                      step.number === steps.getActiveStep().number ? ' is-active' : ''
                    }`}
                    key={key}
                  >
                    <div className="buy-token-steps--item-icon"></div>
                    <div className="buy-token-steps--item-title">{t(step.label)}</div>
                  </li>
                ))}
              </ul>
            </div>
            {STEPS[steps.getActiveStep().number]()}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default WithdrawalModal
