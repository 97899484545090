import React from 'react'

const Checkbox = ({ register = () => {}, name, label = null, disabled = false, classes = [], ...rest }) => {
  return (
    <div className="checkbox">
      <input
        {...register(name)}
        {...rest}
        type={'checkbox'}
        className={`${classes.join(' ')}`}
        id={name}
        disabled={disabled}
      />
      <label htmlFor={name}>
        <span className="box"></span>
        <span className="text">{label}</span>
      </label>
    </div>
  )
}

export default Checkbox
