import get from 'lodash/get'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import useClickAway from 'react-use/lib/useClickAway'
import useMount from 'react-use/lib/useMount'
import {
  NOTIFICATION_TYPE_FINANCE,
  NOTIFICATION_TYPE_INFO,
  NOTIFICATION_TYPE_NEWS,
  NOTIFICATION_TYPE_NEW_REFERRAL,
  NOTIFICATION_TYPE_SUPPORT,
} from 'shared/constants'

import useNotifications from 'shared/hooks/useNotifications'

import useNotificationsStore from 'shared/stores/notifications'

import { parseAndFormat } from 'shared/utils/date'

import Button from 'shared/components/Button'

import { buildUrl } from '../../shared/utils/notifications'

export const NOTIFICATIONS_ICON_MAP = {
  [NOTIFICATION_TYPE_INFO]: () => <i className="icon icon-message" />,
  [NOTIFICATION_TYPE_FINANCE]: () => <i className="icon icon-reward" />,
  [NOTIFICATION_TYPE_NEWS]: () => <i className="icon icon-news" />,
  [NOTIFICATION_TYPE_SUPPORT]: () => <i className="icon icon-message-support" />,
  [NOTIFICATION_TYPE_NEW_REFERRAL]: (url) =>
    url ? <img src={url} alt="" /> : <i className={'icon icon-new-client'} />,
}

const Notification = (props) => {
  const { t } = useTranslation()

  const notifications = useNotificationsStore()
  const location = useLocation()
  const [isOpenNotification, setIsOpenNotification] = useState(false)
  const { hideItem, clearNotificationsHandler, fetchDropdownList } = useNotifications()
  const [hideLoading, setHideLoading] = useState(null)
  const dropdownBlock = useRef()

  useMount(() => {
    fetchDropdownList()
  })

  useEffect(() => {
    if (isOpenNotification) {
      fetchDropdownList()
    }
  }, [isOpenNotification])

  useClickAway(dropdownBlock, () => {
    if (isOpenNotification) {
      setIsOpenNotification(false)
    }
  })

  const handleClickNotification = (ev) => {
    ev.preventDefault()
    setIsOpenNotification(!isOpenNotification)
  }

  const handleCloseNotification = (ev) => {
    ev.preventDefault()
    setIsOpenNotification(false)
  }

  const handleHideNotification = (e, el) => {
    e.preventDefault()
    const buffdata = [...notifications.dropdownData]
    const idx = buffdata.findIndex((noty) => el.id === noty.id)

    setHideLoading(el.id)
    if (idx > -1) {
      buffdata.splice(idx, 1)
      notifications.setDropdownData(buffdata)
      hideItem([el.id], location.pathname !== '/notifications', () => {
        setHideLoading(null)
      })
    }
  }

  const handleClearAll = (e) => {
    e.preventDefault()
    clearNotificationsHandler()
    notifications.setDropdownData([])
    props.currentToasts.current = []
  }

  const closeDropdown = () => {
    setIsOpenNotification(false)
  }

  return (
    <div ref={dropdownBlock}>
      <a
        href="#"
        className={`header-notifications-button header-notifications-trigger ${
          notifications.dropdownData.length ? 'header-notifications-button_new' : ''
        }`}
        title={t('Уведомления')}
        onClick={handleClickNotification}
      >
        {t('Уведомления')}
      </a>

      <div className={`header-notifications-dropdown ${isOpenNotification ? 'is-active' : ''}`}>
        {notifications.dropdownData.length === 0 ? (
          <div className={`header-notifications-dropdown__content`}>
            <div className="notifications-empty">
              <div className="title">
                {t('Новых уведомлений нет.')}{' '}
                <Link to={'/notifications'} className="btn-modal" onClick={closeDropdown}>
                  {t('Посмотреть все')}
                </Link>
              </div>

              <a
                href="#"
                className="btn-notification-remove "
                data-target="notifications"
                title={t('Закрыть')}
                onClick={handleCloseNotification}
              >
                {t('Закрыть')}
              </a>
            </div>
          </div>
        ) : (
          <>
            <div className="header-notifications-dropdown__header">
              <div className="title">{t('Уведомления')}</div>
              <div className="controls">
                <Button variant={'secondary'} classes={['btn-sm', 'btn-light']} onClick={handleClearAll}>
                  {t('Очистить все')}
                </Button>
              </div>
            </div>
            <div className={`header-notifications-dropdown__content`}>
              <ul className="notifications-list">
                {notifications.dropdownData.map((el) => {
                  return (
                    <li
                      key={el.id}
                      className="notifications-list__item"
                      style={{ opacity: hideLoading === el.id ? '0.3' : '1' }}
                    >
                      <div className="type-icon">{NOTIFICATIONS_ICON_MAP[el.type](get(el, 'extra.avatar'))}</div>
                      <div className="content">
                        <div className="title">
                          <span
                            onClick={() => {
                              closeDropdown()
                            }}
                          >
                            {el.message || el.title}
                          </span>
                        </div>

                        <div className="date">{parseAndFormat(el.date)}</div>
                      </div>
                      <a
                        href="#"
                        className="btn-notification-remove"
                        title={t('Удалить')}
                        onClick={(e) => handleHideNotification(e, el)}
                      >
                        {t('Удалить')}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className="header-notifications-dropdown__footer">
              <Link to={'/notifications'} className={'btn'} onClick={closeDropdown}>
                {t('Смотреть все уведомления')}
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Notification
