import React from 'react'

import useThemeToggle from '../shared/hooks/useThemeToggle'

export default function () {
  useThemeToggle()
  return (
    <div id="preloader" className="preloader">
      <div className="preloader-logo">
        <div className="full-logo">InfluenceClub</div>
      </div>
      <div className="preloader-animation">
        <div className="spinner"></div>
      </div>
    </div>
  )
}
