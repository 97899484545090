import React from 'react'
import { useTranslation } from 'react-i18next'

import Breadcrumbs from 'components/Breadcrumbs'

const PageHeader = () => {
  const { t } = useTranslation()

  return (
    <div className={'page--header'}>
      <Breadcrumbs />
      <div className="page--header-container">
        <div className="page--title">{t('Амбассадор')}</div>
      </div>
    </div>
  )
}

export default PageHeader
