import { useEffect } from 'react'
import useSetState from 'react-use/lib/useSetState'

import useUserStore from 'shared/stores/user'

import { getNews } from 'shared/api/news'

import { NEWS_SOURCE_COMMON, NEWS_SOURCE_INFLUENCECLUB } from '../constants'

const useNews = (perpage = 8) => {
  const user = useUserStore()
  const language = user.language

  const [data, setData] = useSetState({
    data: [],
    count: 0,
    page: 1,
    loading: false,
  })

  const fetchList = (page = 1, size = 8) => {
    const source = `${NEWS_SOURCE_COMMON},${NEWS_SOURCE_INFLUENCECLUB}`

    setData({ loading: true })
    getNews(language === 'vi' ? 'vn' : language, page, size, source)
      .then((res) => {
        setData({
          data: res.data,
          page: res.page,
          count: res.count,
          loading: false,
        })
      })
      .catch((e) => console.log(e))
      .finally(() => setData({ loading: false }))
  }

  useEffect(() => {
    fetchList(1, perpage, '')
  }, [])

  useEffect(() => {
    fetchList(1, perpage, '')
  }, [language])

  return {
    ...data,
    fetchList,
  }
}

export default useNews
