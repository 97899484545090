import React, { useState } from 'react'
import { Route, Routes } from 'react-router'
import useMount from 'react-use/lib/useMount'
import useUnmount from 'react-use/lib/useUnmount'

import Footer from 'components/Footer'

import Error from '../../components/Error'
import Header from './components/Header'
import Activate from './screens/Activate'
import ByKey from './screens/Login/components/ByKey'
import Fail from './screens/Login/components/Fail'
import Login from './screens/Login/components/Login'
import Waiting from './screens/Login/components/Waiting'
import Logout from './screens/Logout'
import Refresh from './screens/Refresh'

const Auth = () => {
  const [errors, setErrors] = useState()

  const handleLoginError = (errors) => {
    setErrors(errors)
  }

  useMount(() => {
    document.body.classList.add('auth-page')
  })

  useUnmount(() => document.body.classList.remove('auth-page'))

  return (
    <div className={`main`}>
      <Header />
      <div className="main_parent">
        <div className="main_content">
          <Routes>
            <Route path={'*'} element={<Error />} />
            <Route index path={'login'} element={<Login onError={handleLoginError} />} />
            <Route path={'login/waiting'} element={<Waiting onError={handleLoginError} />} />
            <Route path={'login/fail'} element={<Fail errors={errors} />} />
            <Route path={'login/by-key/:key'} element={<ByKey />} />

            <Route path={'refresh'} element={<Refresh />} />
            <Route path={'logout'} element={<Logout />} />
            <Route path={'activate/:token'} element={<Activate />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default Auth
