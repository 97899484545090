import React from 'react'
import useMount from 'react-use/lib/useMount'

import useRankData from 'shared/hooks/useRankData'

import ClientsBlock from './components/ClientsBlock'
import LevelStats from './components/LevelStats'
import PageHeader from './components/PageHeader'
import ProfitStats from './components/ProfitStats'
import ReferralBlock from './components/ReferralBlock'

const Dashboard = () => {
  const { fetchUserRanks, fetchTotalStats } = useRankData()

  useMount(() => {
    fetchUserRanks()
    fetchTotalStats()
  })

  return (
    <div className={'page'}>
      <PageHeader />
      <div className="page--content">
        <div className="dashboard-grid">
          <div className="dashboard-grid--line">
            <div className="panel ">
              <div className="panel-body">
                <ReferralBlock />
              </div>
            </div>
          </div>

          <div className="dashboard-grid--item">
            <div className="panel panel_ah">
              <div className="panel-body">
                <ClientsBlock />
              </div>
            </div>
            <div className="panel panel_ah">
              <div className="panel-body">
                <LevelStats />
              </div>
            </div>
          </div>

          <div className="dashboard-grid--item">
            <div className="panel">
              <div className="panel-body">
                <ProfitStats />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
