import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Breadcrumbs from 'components/Breadcrumbs'

import useNews from 'shared/hooks/useNews'

import { parseAndFormat } from 'shared/utils/date'

import Pagination from 'shared/components/Pagination'

const MAX_PAGE_SIZE = 8
const NewsScreen = () => {
  const { t } = useTranslation()
  const { data, loading, fetchList, page, count } = useNews()

  return (
    <div className="page">
      <div className="page--header">
        <Breadcrumbs />
        <div className="page--header-container">
          <div className="page--title">{t('Новости')}</div>
        </div>
      </div>
      <div className="page--content">
        <div className="news">
          <div className="news--content">
            <ul className={`news-list ${loading ? 'loading' : ''}`}>
              {data.map((news, index) => (
                <li className="news-list--item" key={index}>
                  <div className="news-list-item">
                    <div className="news-list-item--image">
                      <Link to={`/news/${news.slug}`}>
                        <img src={news?.media?.medium} alt="" />{' '}
                      </Link>
                    </div>
                    <div className="news-list-item--content">
                      <div className="news-list-item--meta">
                        <div className="news-list-item--date">{parseAndFormat(news.publishedAt, 'dd.MM.yyyy')}</div>
                      </div>
                      <div className="news-list-item--title">
                        <Link to={`/news/${news.slug}`}>{news.title}</Link>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="news--footer">
            <Pagination
              page={page}
              initialPage={page - 1}
              pageCount={Math.ceil(count / MAX_PAGE_SIZE)}
              onSelect={(num) => {
                fetchList(num, MAX_PAGE_SIZE)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsScreen
