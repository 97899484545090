import { Duration } from 'luxon'

export const durationShift = (value, type = 'milliseconds') => {
  const dur = Duration.fromObject({ [type]: value })

  return dur.shiftTo('hours', 'minutes', 'seconds').toObject()
}

export const format = (value) => {
  const val = parseInt(value)

  if (val >= 10) {
    return '' + val
  }

  if (val > 0) {
    return `0${val}`
  }

  return `00`
}
