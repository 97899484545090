import React from 'react'
import { useTranslation } from 'react-i18next'

import useModalsStore from 'shared/stores/modals'

import { getNowDate } from 'shared/utils/date'
import { asUSCR } from 'shared/utils/money'

import Button from 'shared/components/Button'

import useStepsStore from '../stores/steps'

const Confirmation = ({ formWrapper }) => {
  const { t } = useTranslation()
  const { form } = formWrapper
  const modals = useModalsStore()
  const steps = useStepsStore()

  const handleClose = () => {
    steps.setActiveStep(0)
    modals.hide('transfer')
    form.reset({
      amount: 0,
      recipient: '',
      code: '',
      confirmLogin: false,
    })
  }

  return (
    <>
      <div className="finance-form--info">
        <div className="action-message action-message_success">
          <div className="action-message--content">
            <div className="action-message--title">{t('Перевод успешно совершен!')}</div>
          </div>
        </div>
      </div>

      <div className="finance-form--info">
        <div className="transaction-final-info">
          <div className="transaction-final-info--item">
            <div className="transaction-final-info--title">{t('Сумма перевода')}:</div>
            <div className="transaction-final-info--value">{asUSCR(form.getValues().amount)}</div>
          </div>
          <div className="transaction-final-info--item">
            <div className="transaction-final-info--title">{t('Клиент')}:</div>
            <div className="transaction-final-info--value">{form.getValues().recipient}</div>
          </div>
          <div className="transaction-final-info--item">
            <div className="transaction-final-info--title">{t('Дата операции')}:</div>
            <div className="transaction-final-info--value">{getNowDate()}</div>
          </div>
        </div>
      </div>

      <div className="finance-form--buttons">
        <Button variant={'primary'} classes={['btn-lg', 'btn_sqr', 'btn-modal-close']} onClick={handleClose}>
          <span>{t('Закрыть')}</span>
        </Button>
      </div>
    </>
  )
}

export default Confirmation
