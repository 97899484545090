import get from 'lodash/get'

import {
  NOTIFICATION_TYPE_FINANCE,
  NOTIFICATION_TYPE_NEWS,
  NOTIFICATION_TYPE_NEW_REFERRAL,
  NOTIFICATION_TYPE_SUPPORT,
} from '../constants'

const NOTIFICATIONS_URL_MAP = {
  [NOTIFICATION_TYPE_FINANCE]: '',
  [NOTIFICATION_TYPE_NEWS]: '/news',
  [NOTIFICATION_TYPE_NEW_REFERRAL]: '',
  [NOTIFICATION_TYPE_SUPPORT]: '/support',
}

const buildUrl = (notification) => {
  if (notification.type === NOTIFICATION_TYPE_SUPPORT) {
    return `/support/${get(notification, 'extra.id', '')}`
  } else {
    return ''
  }
}

export { NOTIFICATIONS_URL_MAP, buildUrl }
