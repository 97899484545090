import omit from 'lodash/omit'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDebounce } from 'react-use'
import useMount from 'react-use/lib/useMount'
import {
  BLOCKCHAIN_BSC,
  BLOCKCHAIN_TRX,
  CURRENCY_BASE,
  CURRENCY_BUSD,
  CURRENCY_DAI,
  CURRENCY_USCR,
  CURRENCY_USDC,
  CURRENCY_USDT,
} from 'shared/constants'
import Web3 from 'web3'
import * as yup from 'yup'

import useFormWrapper from 'shared/hooks/useFormWrapper'

import useUserStore from 'shared/stores/user'

import { composeCurrency, getCurrencyDescription, getCurrencyIcon, recomposeCurrency } from 'shared/utils/billing'
import { asMoney, asUSCR } from 'shared/utils/money'
import { roundDown } from 'shared/utils/number'
import { getBalance, getStatementLink, isGuest } from 'shared/utils/user'

import { calculateWithdrawalTotal, validateWithdrawal } from 'shared/api/finances'

import Button from 'shared/components/Button'
import Dropdown from 'shared/components/forms/Dropdown'
import FormField from 'shared/components/forms/FormField'
import Input from 'shared/components/forms/Input'

import useExchange from '../hooks/useExchange'
import useStepsStore from '../stores/steps'

const currencyOptions = [
  {
    label: CURRENCY_USDT,
    value: composeCurrency(CURRENCY_USDT, BLOCKCHAIN_BSC),
    description: getCurrencyDescription(`${BLOCKCHAIN_BSC}_${CURRENCY_USDT}`),
    icon: getCurrencyIcon(`${BLOCKCHAIN_BSC}_${CURRENCY_USDT}`),
    disabled: false,
  },
  {
    label: CURRENCY_USDT,
    value: composeCurrency(CURRENCY_USDT, BLOCKCHAIN_TRX),
    description: getCurrencyDescription(`${BLOCKCHAIN_TRX}_${CURRENCY_USDT}`),
    icon: getCurrencyIcon(`${BLOCKCHAIN_TRX}_${CURRENCY_USDT}`),
    disabled: true,
  },
  {
    label: CURRENCY_USDC,
    value: CURRENCY_USDC,
    description: getCurrencyDescription(CURRENCY_USDC),
    icon: getCurrencyIcon(CURRENCY_USDC),
    disabled: true,
  },
  {
    label: CURRENCY_BUSD,
    value: CURRENCY_BUSD,
    description: getCurrencyDescription(CURRENCY_BUSD),
    icon: getCurrencyIcon(CURRENCY_BUSD),
    disabled: true,
  },
  {
    label: CURRENCY_DAI,
    value: CURRENCY_DAI,
    description: getCurrencyDescription(CURRENCY_DAI),
    icon: getCurrencyIcon(CURRENCY_DAI),
    disabled: true,
  },
]

const MIN_AMOUNT = 10
const MAX_AMOUNT = 10_000

const addressTesters = {
  [BLOCKCHAIN_BSC]: (value) => Web3.utils.isAddress(value),
  [BLOCKCHAIN_TRX]: (value) => /T[A-Za-z1-9]{33}/.test(value),
}

const AmountForm = () => {
  const { t, i18n } = useTranslation()
  const user = useUserStore()
  const steps = useStepsStore()

  const stepData = steps.getActiveStep().payload

  const validationSchema = yup.object().shape({
    amount: yup
      .number()
      .min(MIN_AMOUNT, `${t('Минимальная сумма')} ${asUSCR(MIN_AMOUNT)}`)
      .max(MAX_AMOUNT, `${t('Максимальная сумма')} ${asUSCR(MAX_AMOUNT)}`)
      .typeError(t('Значение должно быть числом'))
      .required('Обязательное поле'),
    account: yup
      .string()
      .test({
        name: 'valid',
        message: t('Неправильный формат'),
        test: (value) => {
          return addressTesters[stepData.toBlockchain](value)
        },
      })
      .required(t('Обязательное поле'))
      .trim(),
    toCurrency: yup.string().trim().required(t('Обязательное поле')),
    confirm: yup.bool().test({
      name: 'valid',
      test: (value) => value === true,
    }),
  })

  const { getFieldError, form, setFormValues, setFormErrors } = useFormWrapper(validationSchema, {
    mode: 'all',
    reValidateMode: 'all',
    defaultValues: { ...stepData, toCurrency: composeCurrency(stepData.toCurrency, stepData.toBlockchain) },
  })

  const { amount, toCurrency } = form?.getValues() ?? {}
  const preparedCurrency = recomposeCurrency(toCurrency)

  const [total, setTotal] = useState({ currency: preparedCurrency.currency, value: amount || 0 })

  const { onChange } = useExchange(form, 'amount', 'currencyAmount')

  const isUserGuest = isGuest(user)
  const balance = isUserGuest ? 0 : getBalance(user)
  const [validationLoading, setValidationLoading] = useState(false)

  useMount(() => {
    form.watch()
  })

  useEffect(() => {
    if (Object.keys(stepData.errors).length > 0) {
      setFormErrors(stepData.errors)
      steps.updateStep(1, { errors: {} })
    }
  }, [stepData])

  useDebounce(
    () => {
      handleCalculateTotal()
    },
    300,
    [amount],
  )

  const handleSetMaxAmount = (ev) => {
    ev.preventDefault()

    const amount = roundDown(balance)

    setFormValues({ amount: amount > MAX_AMOUNT ? MAX_AMOUNT : amount })
    form.trigger('amount')
  }

  const handleChangeCurrency = (opt) => {
    setFormValues({ toCurrency: opt.value })
    form.trigger('toCurrency')

    const preparedCurrency = recomposeCurrency(opt.value)

    steps.updateStep(1, { toCurrency: preparedCurrency.currency, toBlockchain: preparedCurrency.blockchain })
  }

  const handleSubmit = () => {
    const data = omit(form.getValues(), ['confirm'])
    const preparedCurrency = recomposeCurrency(data.toCurrency.trim())

    const payload = {
      amount: parseFloat(data.amount),
      account: data.account.trim(),
      toCurrency: preparedCurrency.currency,
      toBlockchain: preparedCurrency.blockchain,
    }

    setValidationLoading(true)
    validateWithdrawal(payload)
      .then(() => {
        steps.updateStep(1, { ...payload, currencyAmount: total.value })
        steps.setActiveStep(2)
      })
      .catch((err) => {
        if (err.errors) {
          setFormErrors(err.errors)
        }
      })
      .finally(() => setValidationLoading(false))
  }

  const handleCalculateTotal = () => {
    const preparedCurrency = recomposeCurrency(form.getValues('toCurrency'))

    calculateWithdrawalTotal({
      amount: amount || 0,
      fromCurrency: CURRENCY_USCR,
      toCurrency: preparedCurrency.currency,
      toBlockchain: preparedCurrency.blockchain,
    })
      .then((res) => {
        setTotal(res)
        form.setValue('currencyAmount', res.value)
      })
      .catch((e) => console.log(e))
  }

  return (
    <>
      <div className="finance-form--fields">
        <FormField error={getFieldError('amount')}>
          <label htmlFor="amount" className="control-label">
            {t('Сумма вывода')}
          </label>
          <div className="form-field">
            <Input register={form.register} name={'amount'} onChange={onChange} />
            <div className="form-field--option">U$CR</div>
          </div>
          <div className="form-group--controls">
            <a href="#" onClick={(ev) => handleSetMaxAmount(ev)}>
              max: {balance > MAX_AMOUNT ? MAX_AMOUNT : asUSCR(balance)}
            </a>
          </div>
        </FormField>
        <FormField error={getFieldError('toCurrency')}>
          <label htmlFor="toCurrency" className="control-label">
            {t('Валюта')}
            {' / '}
            {t('Токен')}
          </label>
          <Dropdown
            control={form.control}
            name={'toCurrency'}
            options={currencyOptions}
            onChange={handleChangeCurrency}
          />
        </FormField>
        <div style={{ display: 'none' }}>
          <FormField error={getFieldError('currencyAmount')}>
            <label htmlFor="currencyAmount" className="control-label">
              {t('Сумма к получению')}
            </label>
            <div className="form-field">
              <Input register={form.register} name={'currencyAmount'} onChange={onChange} />
              <div className="form-field--option">{form.getValues().toCurrency}</div>
            </div>
          </FormField>
        </div>

        <FormField error={getFieldError('account')}>
          <Input label={t('Адрес кошелька')} register={form.register} name={'account'} />
          <div className="help-message">{t('Указывайте только реальный адрес кошелька')}</div>
        </FormField>

        <FormField className="form-group">
          <div className="checkbox">
            <input {...form.register('confirm')} name={'confirm'} type="checkbox" id="confirm" />
            <label htmlFor="confirm">
              <span className="box" />
              <span className="text">
                <Trans
                  i18nKey={'Я ознакомился с <a>правилами работы</a> платформы'}
                  components={{
                    a: <a href={getStatementLink(i18n.language)} target={'_blank'} />,
                  }}
                />
              </span>
            </label>
          </div>
        </FormField>
      </div>
      <div className="finance-form--details">
        <div className="finance-transaction-details">
          <div className="finance-transaction-details--item">
            <div className="finance-transaction-details--title">{t('Минимальная сумма')}:</div>
            <div className="finance-transaction-details--value">{asUSCR(MIN_AMOUNT)}</div>
          </div>
          <div className="finance-transaction-details--item">
            <div className="finance-transaction-details--title">{t('Максимальная сумма')}:</div>
            <div className="finance-transaction-details--value">{asUSCR(MAX_AMOUNT)}</div>
          </div>
          <div className="finance-transaction-details--item">
            <div className="finance-transaction-details--title">{t('Комиссия')}:</div>
            <div className="finance-transaction-details--value">
              {t('{{percent}}% но не меньше {{value}}', {
                percent: 5,
                value: asMoney(1, CURRENCY_BASE),
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="finance-form--total">
        <div className="finance-form-total">
          <div className="finance-form-total--title">{t('Итого')}:</div>
          <div className="finance-form-total--value">{asMoney(total.value, total.currency, 2)}</div>
        </div>
      </div>

      <div className="finance-form--buttons">
        <Button
          variant="primary"
          classes={['btn-lg', 'btn-modal']}
          loading={validationLoading}
          onClick={handleSubmit}
          disabled={!form.formState.isValid}
        >
          <span>{t('Продолжить')}</span>
        </Button>
      </div>
    </>
  )
}

export default AmountForm
