import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useMount from 'react-use/lib/useMount'

import useRankDataStore from 'shared/stores/levelStatsData'

import { buildLevelStatsData } from 'shared/utils/levelStats'
import { getRankNum, getUserRankLabel, isMaxRank } from 'shared/utils/user'

import { userRank } from 'shared/api/stats'

const LevelStats = () => {
  const { t } = useTranslation()
  const { userRanks } = useRankDataStore()
  const rankData = useMemo(() => buildLevelStatsData(userRanks), [userRanks])
  const [data, setData] = useState({
    rank: null,
    nextRank: null,
    nextRankPercent: 0,
  })
  const maxRank = isMaxRank(data.rank)
  const currentRankNumber = getRankNum(data.rank)
  const fetchData = () => {
    userRank()
      .then((data) => setData(data))
      .catch((err) => console.log(err))
  }

  useMount(() => {
    fetchData()
  })

  return (
    <>
      <div className="user-status user-status_full">
        <div className="user-status--item">
          <div className="user-status--container">
            <div className="user-status--title">{t('Текущий статус')}:</div>
            <div className="user-status--value">
              <span>{maxRank ? `VIP ${t('Агент')}` : t(getUserRankLabel(data.rank))}</span>
            </div>
          </div>
        </div>
        {!maxRank ? (
          <div className="user-status--item">
            <div className="user-status--container">
              <div className="user-status--title">
                <span>{t('Следующий статус')}:</span>
                <a
                  href="#"
                  className="btn-info btn-modal"
                  data-target="messageStatusConditions"
                  style={{ display: 'none' }}
                >
                  <span>Информация</span>
                </a>
              </div>
              <div className="user-status--value">
                <span>{t('Агент')}</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="user-status--item">
            <div className="user-status--container">
              <div className="stone-logo stone-logo_lg"></div>
            </div>
          </div>
        )}
      </div>

      <div className="user-status-progress ">
        <div className="user-status-progress--header">
          <div className="user-status-bar">
            <ul className="user-status-bar-list">
              {rankData.map((rank) => (
                <li
                  className={`user-status-bar-list--item ${
                    rank.rankNumber <= currentRankNumber ? 'user-status-bar-item_done' : ''
                  } ${rank.rankNumber === currentRankNumber && !maxRank ? ' user-status-bar-item_current' : ''}`}
                  key={rank.rankNumber}
                >
                  <div className={`user-status-bar-item `}>
                    <div className="user-status-bar-item--icon">
                      <i className={`level-icon level${rank.rankNumber}`}></i>
                    </div>
                    <div className={`user-status-bar-item--title level${rank.rankNumber}`}>
                      {t('Агент {{number}}', { number: rank.rankNumber })}
                    </div>
                    <div className="user-status-bar-item--value">{rank.percent}%</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default LevelStats
