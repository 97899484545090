import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Link, useLocation } from 'react-router-dom'
import { useClickAway } from 'react-use'
import useMount from 'react-use/lib/useMount'

import useAmbassador from 'shared/hooks/useAmbassador'
import useMedia from 'shared/hooks/useMedia'

import useModalsStore from 'shared/stores/modals'
import useUiStore from 'shared/stores/ui'
import useUserStore, { VIEW_MODE_DESKTOP } from 'shared/stores/user'

import { asUSCR } from 'shared/utils/money'
import { availableBilling, countryFlag } from 'shared/utils/ui'
import { getAvatar, getBalance, getFullName } from 'shared/utils/user'

import LanguageSelector from './LanguageSelector'
import Notification from './Notification'
import ThemeToggler from './ThemeToggler'

export default () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { status, fetchAmbassadorStatus } = useAmbassador()
  const currentToasts = useRef([])
  const user = useUserStore()
  const { isDesktop } = useMedia(1280)
  const modals = useModalsStore()
  const ui = useUiStore()
  const userMenu = useRef(null)
  const balance = useMemo(() => asUSCR(getBalance(user), 2), [user])
  const isAvailableBilling = availableBilling(user.login)
  const profileCountry = user.profile.country

  useMount(() => {
    fetchAmbassadorStatus(true)
  })
  useEffect(() => {
    if (ui.openMenu && !isDesktop) {
      ui.toggleMenu()
    }

    if (isDesktop && !ui.openMenu) {
      ui.toggleMenu()
    }
  }, [location.key, isDesktop])

  useClickAway(userMenu, () => {
    if (ui.openUserMenu) {
      ui.toggleUserMenu()
    }
  })

  const handleToggleSidebar = (e) => {
    e.preventDefault()
    ui.toggleMenu()
  }

  const handleLogout = (e) => {
    e.preventDefault()

    navigate('/auth/logout')
  }

  const handleChangeViewMode = () => {}

  const handleChangePage = () => {}

  const handleWithdraw = () => {
    modals.show('withdrawal')
  }

  const handleTransfer = () => {
    modals.show('transfer')
  }

  const handleToggleUserMenu = (ev) => {
    ev.preventDefault()

    ui.toggleUserMenu()
  }

  const handleOpenModal = (ev) => {
    ev.preventDefault()
    modals.show('changeCountry')
  }

  return (
    <header id="header">
      <div className="header">
        <div className="header-logo">
          <Link to={'/'} className="main-logo" title="InfluenceClub">
            InfluenceClub
          </Link>
        </div>

        <div className="header-buttons">
          <a
            href="#"
            className={`main-nav-button ${ui.openMenu ? 'is-active' : ''}`}
            onClick={(ev) => handleToggleSidebar(ev)}
            title={ui.openMenu ? t('Скрыть меню') : t('Раскрыть меню')}
          >
            <span>
              <span>{t('Меню')}</span>
            </span>
          </a>
        </div>

        <div className="header-balance">
          <div className="header-balance__balance">
            <div className="header-balance__title">{t('Баланс')}:</div>
            <div className="header-balance__value" dir="auto">
              {balance}
            </div>
          </div>
          <div className="header-balance__controls">
            <ul>
              <li>
                <a href="#" className="btn btn-primary btn-modal" onClick={handleTransfer} disabled={false}>
                  {t('Перевод')}
                </a>
              </li>
              <li>
                <a href="#" className="btn" onClick={handleWithdraw} disabled={false}>
                  {t('Вывод')}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="header-userbar">
          <div className="header-userbar__notifications">
            <Notification user={user} currentToasts={currentToasts} />
          </div>
          <div className="header-userbar__theme">
            <ThemeToggler />
          </div>
          <div className="header-userbar__lang">
            <LanguageSelector />
          </div>

          <div className="header-userbar__userinfo">
            <div ref={userMenu} className="header-usermenu">
              <a
                href="#"
                className="usermenu-button header-usermenu-trigger"
                onClick={(ev) => handleToggleUserMenu(ev)}
              >
                <span className="userpic">
                  <img src={getAvatar(user)} alt="" className={'user-photo'} />
                  <span className="userflag">
                    <img
                      src={countryFlag(profileCountry)}
                      style={{ display: 'inline-block', width: '1em', height: '1em', verticalAlign: 'middle' }}
                    />
                  </span>
                </span>
                <span className="usernames">
                  <span className="usernick" dir="auto">
                    {user.login}{' '}
                  </span>
                  <span className="username" dir="auto">
                    {getFullName(user)}
                  </span>
                </span>
              </a>

              <div
                className={`header-usermenu-dropdown ${ui.openUserMenu ? 'is-active' : ''}`}
                style={{ right: '-10%' }}
              >
                <ul className="header-usermenu-dropdown-list">
                  <li className="header-usermenu-dropdown-list__item header-usermenu-dropdown-list__item_userdata">
                    <div className="usernames">
                      <div className="usernick">{user.login}</div>
                      <div className="username">{getFullName(user)}</div>
                    </div>
                    <div className="useremail">{user.email}</div>
                  </li>
                  <li className="header-usermenu-dropdown-list__item">
                    <a href="#" className="header-usermenu-dropdown-list__item-link" onClick={handleOpenModal}>
                      <span className="icon icon-globe" />
                      <span>{t('Сменить страну')}</span>
                    </a>
                  </li>
                  <li className="header-usermenu-dropdown-list__item">
                    <a href="#" className="header-usermenu-dropdown-list__item-link" onClick={handleLogout}>
                      <i className="icon icon-logout" />
                      {t('Выход')}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`main-nav ${ui.openMenu ? '' : 'main-nav-hide'}`}>
        <div className="main-nav-container">
          <div className="main-nav_menu">
            <ul className="main-nav_menu-list">
              <li
                className={`main-nav_menu-list-item ${
                  location.pathname === '/' || location.pathname.match('/dashboard') ? 'is-active' : ''
                }`}
              >
                <Link to="/" title={t('Главная')} onClick={handleChangePage}>
                  <span className="img">
                    <svg id="home" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="m2.9 9.813-.298.284a.943.943 0 0 1-1.343-.038.967.967 0 0 1 .039-1.357l7.245-6.916a2.828 2.828 0 0 1 3.914 0l7.245 6.916a.967.967 0 0 1 .039 1.357.943.943 0 0 1-1.343.038l-.298-.284v7.267c0 1.06-.85 1.92-1.9 1.92h-3.325a.477.477 0 0 1-.475-.48v-5.28c0-1.06-.85-1.92-1.9-1.92-1.05 0-1.9.86-1.9 1.92v5.28c0 .265-.213.48-.475.48H4.8c-1.05 0-1.9-.86-1.9-1.92V9.813Z"
                        fill="url(#ah)"
                      />
                      <defs>
                        <linearGradient
                          id="ah"
                          x1="1"
                          y1="10.191"
                          x2="20.07"
                          y2="10.717"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#FA6E7F" />
                          <stop offset="1" stopColor="#FDB199" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </span>
                  <span className="title">{t('Главная')}</span>
                </Link>
              </li>
              <li className={`main-nav_menu-list-item ${location.pathname.match('/affiliate') ? 'is-active' : ''}`}>
                <Link to="/affiliate" title={t('Филиал')} onClick={handleChangePage}>
                  <span className="img">
                    <svg id="filial" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <defs>
                        <linearGradient
                          id="af"
                          x1="1"
                          y1="10.454"
                          x2="19.032"
                          y2="11.033"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#FA6E7F" />
                          <stop offset="1" stopColor="#FDB199" />
                        </linearGradient>
                      </defs>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.247 6.007h2.274C4.52 4.981 4.227 3 5.84 3h8.289c1.613 0 1.32 1.98 1.32 3.007 1.54 0 3.52-.366 3.52 1.247v9.096c0 .66-.513 1.247-1.173 1.247H2.247A1.236 1.236 0 0 1 1 16.35V7.254c0-.66.513-1.247 1.247-1.247Zm3.814 0h7.849V4.54H6.06v1.467Z"
                        fill="url(#af)"
                      />
                    </svg>
                  </span>
                  <span className="title">{t('Филиал')}</span>
                </Link>
              </li>
              {status.ambassador && (
                <li className={`main-nav_menu-list-item ${location.pathname.match('/ambassador') ? 'is-active' : ''}`}>
                  <Link to="/ambassador" title={t('Амбассадор')} onClick={handleChangePage}>
                    <span className="img">
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none">
                        <defs>
                          <linearGradient
                            id="aa"
                            x1="1"
                            x2="19.068"
                            y1="10.191"
                            y2="10.663"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#FA6E7F" />
                            <stop offset="1" stopColor="#FDB199" />
                          </linearGradient>
                          <linearGradient
                            id="ba"
                            x1="1"
                            x2="19.064"
                            y1="9.129"
                            y2="9.662"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#FA6E7F" />
                            <stop offset="1" stopColor="#FDB199" />
                          </linearGradient>
                          <linearGradient
                            id="ca"
                            x1="1"
                            x2="19.064"
                            y1="9.129"
                            y2="9.662"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#FA6E7F" />
                            <stop offset="1" stopColor="#FDB199" />
                          </linearGradient>
                        </defs>
                        <circle cx="10" cy="10" r="8.5" stroke="url(#aa)" />
                        <path
                          fill="url(#ba)"
                          d="M15.756 16.92a8.981 8.981 0 0 0 3.235-7.328c-.838-.172-1.779-.293-2.238-.295h-2.954a2.426 2.426 0 0 0-2.428 2.43 2.432 2.432 0 0 0 2.428 2.43 1.732 1.732 0 0 1 1.733 1.736l.224 1.026Z"
                        />
                        <path
                          fill="url(#ca)"
                          d="m1.5 13 2.62.445a2.416 2.416 0 0 0 2.417-2.418V8.954a2.419 2.419 0 0 1 2.417-2.418 2.416 2.416 0 0 0 2.417-2.418V1.104A9 9 0 0 0 1 10c0 2 .5 3 .5 3Z"
                        />
                      </svg>
                    </span>
                    <span className="title">{t('Амбассадор')}</span>
                  </Link>
                </li>
              )}
              <li className={`main-nav_menu-list-item ${location.pathname.match('/finances') ? 'is-active' : ''}`}>
                <Link to="/finances" title={t('Финансы')} onClick={handleChangePage}>
                  <span className="img">
                    <svg id="finance" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21 6.25v7.94a1.488 1.488 0 0 1-1.488 1.489H1.647A1.487 1.487 0 0 1 .158 14.19V6.25a1.487 1.487 0 0 1 1.489-1.489h17.865A1.487 1.487 0 0 1 21 6.25Zm-4.281-.483c-1.54-.01-12.279-.013-12.279-.013a1.49 1.49 0 0 0-1.399.98l-.005.015a1.442 1.442 0 0 1-.9.908c-.582.211-.97.763-.972 1.381-.013.898-.013 1.46-.013 2.359 0 .625.392 1.185.98 1.399l.015.004a1.443 1.443 0 0 1 .908.902c.212.583.766.97 1.386.97 1.445.011 12.279.014 12.279.014a1.49 1.49 0 0 0 1.398-.98l.005-.015a1.442 1.442 0 0 1 .902-.908c.58-.211.969-.763.97-1.381.014-.898.014-1.46.014-2.359a1.49 1.49 0 0 0-.98-1.398l-.015-.005a1.442 1.442 0 0 1-.908-.902 1.476 1.476 0 0 0-1.386-.97Zm-.004.993c-1.927-.014-12.274-.014-12.274-.014a.496.496 0 0 0-.464.32 2.438 2.438 0 0 1-1.508 1.526.483.483 0 0 0-.312.451v.008c-.014.893-.014 1.452-.014 2.345 0 .207.129.392.321.464a2.438 2.438 0 0 1 1.525 1.508c.07.188.25.312.452.312h.004c1.83.014 12.273.014 12.273.014a.496.496 0 0 0 .464-.32 2.438 2.438 0 0 1 1.508-1.526.482.482 0 0 0 .313-.451v-.008c.013-.893.013-1.452.013-2.345a.496.496 0 0 0-.32-.464 2.439 2.439 0 0 1-1.526-1.508.482.482 0 0 0-.451-.312h-.004Zm-6.136.979a2.482 2.482 0 0 0 0 4.962 2.482 2.482 0 0 0 0-4.962Zm0 .992a1.49 1.49 0 1 1 0 2.979 1.49 1.49 0 0 1 0-2.979Z"
                        fill="url(#afin)"
                      />
                      <defs>
                        <linearGradient
                          id="afin"
                          x1=".158"
                          y1="10.336"
                          x2="21.041"
                          y2="11.377"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#FA6E7F" />
                          <stop offset="1" stopColor="#FDB199" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </span>
                  <span className="title">{t('Финансы')}</span>
                </Link>
              </li>
              <li className={`main-nav_menu-list-item ${location.pathname.match('/materials') ? 'is-active' : ''}`}>
                <Link to="/materials" title={t('Материалы')} disabled>
                  <span className="img">
                    <svg id="materials" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3 6.857h13.929A1.07 1.07 0 0 1 18 7.928v7.143a1.07 1.07 0 0 1-1.071 1.071H4.07A1.07 1.07 0 0 1 3 15.072V6.856Z"
                        fill="url(#am)"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.363 6.143h5.566c.389 0 .764.127 1.072.357a1.07 1.07 0 0 0-1.072-1.071h-6.28l.714.714Z"
                        fill="url(#bm)"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3 6.143V5.07C3 4.48 3.48 4 4.071 4h3.694c.285 0 .557.113.758.314l1.83 1.829H3Z"
                        fill="url(#cm)"
                      />
                      <defs>
                        <linearGradient
                          id="am"
                          x1="3"
                          y1="11.598"
                          x2="18.04"
                          y2="12.233"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#FA6E7F" />
                          <stop offset="1" stopColor="#FDB199" />
                        </linearGradient>
                        <linearGradient
                          id="bm"
                          x1="10.648"
                          y1="5.976"
                          x2="17.803"
                          y2="7.258"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#FA6E7F" />
                          <stop offset="1" stopColor="#FDB199" />
                        </linearGradient>
                        <linearGradient id="cm" x1="3" y1="5.094" x2="10.326" y2="5.751" gradientUnits="userSpaceOnUse">
                          <stop stopColor="#FA6E7F" />
                          <stop offset="1" stopColor="#FDB199" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </span>
                  <span className="title">{t('Материалы')}</span>
                </Link>
              </li>
              <li className={`main-nav_menu-list-item ${location.pathname.match('/promo') ? 'is-active' : ''}`}>
                <Link to="/promo" title={t('Промоакции')} disabled>
                  <span className="img">
                    <svg id="promoactions" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.981 14.604H3.02a6.054 6.054 0 0 1-.931 1.34c-.31.32-.451.748-.39 1.173.062.425.32.8.702 1.018.389.229.843.484 1.184.674a1.492 1.492 0 0 0 1.825-.288l.003-.002a16.27 16.27 0 0 0 2.57-3.915Zm5.52-10.764c-.496.24-.809.731-.809 1.27v7.637c0 .537.313 1.028.809 1.269l3.326 1.615c.68.33 1.487.295 2.133-.093a2.115 2.115 0 0 0 1.04-1.81v-9.6c0-.737-.393-1.422-1.04-1.81a2.249 2.249 0 0 0-2.133-.093s-2.124 1.03-3.326 1.615ZM3.192 13.185H10.5a.72.72 0 0 0 .73-.71V5.38a.72.72 0 0 0-.73-.71H3.192C1.982 4.67 1 5.623 1 6.8v4.256c0 1.176.981 2.129 2.192 2.129Z"
                        fill="url(#ap)"
                      />
                      <defs>
                        <linearGradient
                          id="ap"
                          x1="1"
                          y1="10.681"
                          x2="20.068"
                          y2="11.237"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#FA6E7F" />
                          <stop offset="1" stopColor="#FDB199" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </span>
                  <span className="title">{t('Промоакции')}</span>
                </Link>
              </li>

              <li
                className={`main-nav_menu-list-item ${location.pathname.match('/news') ? 'is-active' : ''} ${
                  user?.unreadData?.news > 0 ? 'main-nav_menu-list-item_new' : ''
                } `}
              >
                <Link to="/news" title={t('Новости')}>
                  <span className="img">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
                      <path
                        fill="url(#anews)"
                        d="M3.036 2A2.038 2.038 0 0 0 1 4.036v11.31c0 1.62 1.32 2.94 2.94 2.94h13.12c1.62 0 2.94-1.32 2.94-2.94V7.654a2.031 2.031 0 0 0-1.81-2.013v9.25a.679.679 0 0 1-1.357 0V4.037A2.038 2.038 0 0 0 14.798 2H3.036Zm1.357 4.071h9.048a.678.678 0 1 1 0 1.358H4.393a.679.679 0 1 1 0-1.358Zm0 3.62H7.56c.375 0 .678.303.678.678v3.167a.679.679 0 0 1-.678.678H4.393a.679.679 0 0 1-.679-.678v-3.167c0-.375.304-.679.679-.679Zm5.88 0h3.168a.678.678 0 1 1 0 1.357h-3.167a.679.679 0 1 1 0-1.358Zm-5.202 1.357v1.81h1.81v-1.81H5.07Zm5.203 1.81h3.167a.678.678 0 1 1 0 1.356h-3.167a.679.679 0 1 1 0-1.357Z"
                      />
                      <defs>
                        <linearGradient
                          id="anews"
                          x1="1"
                          x2="20.067"
                          y1="10.316"
                          y2="10.897"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#FA6E7F" />
                          <stop offset="1" stopColor="#FDB199" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </span>
                  <span className="title">{t('Новости')}</span>
                </Link>
              </li>
              <li
                className={`main-nav_menu-list-item ${
                  location.pathname.match('/support') || location.pathname.match('/faq') ? 'is-active' : ''
                }  ${user?.unreadData?.support > 0 ? 'main-nav_menu-list-item_new' : ''}`}
              >
                <Link to="/support" title={t('Техподдержка')} onClick={handleChangePage}>
                  <span className="img">
                    <svg id="support" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.807 11.226V3.853a.41.41 0 0 0-.41-.41H.748a.41.41 0 0 0-.41.41v7.373a.41.41 0 0 0 .41.41h2.868v2.048c.002.156.09.298.23.368.056.027.117.04.18.041a.384.384 0 0 0 .245-.082l3.17-2.375h3.958a.41.41 0 0 0 .41-.41Z"
                        fill="url(#as)"
                      />
                      <path
                        d="M19.591 6.72h-6.963v4.916a.819.819 0 0 1-.82.819H8.531v2.048a.41.41 0 0 0 .41.41h3.957l3.17 2.376c.07.054.157.083.246.081a.442.442 0 0 0 .18-.04.418.418 0 0 0 .23-.37v-2.047h2.867a.41.41 0 0 0 .41-.41V7.13a.41.41 0 0 0-.41-.41Z"
                        fill="url(#bs)"
                      />
                      <defs>
                        <linearGradient
                          id="as"
                          x1=".338"
                          y1="8.881"
                          x2="11.85"
                          y2="9.205"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#FA6E7F" />
                          <stop offset="1" stopColor="#FDB199" />
                        </linearGradient>
                        <linearGradient
                          id="bs"
                          x1="8.531"
                          y1="12.159"
                          x2="20.043"
                          y2="12.482"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#FA6E7F" />
                          <stop offset="1" stopColor="#FDB199" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </span>
                  <span className="title">{t('Техподдержка')}</span>
                </Link>
              </li>
              {/* <li className={`main-nav_menu-list-item ${location.pathname.match('/news') ? 'is-active' : ''}`}> */}
              {/*  <Link to="/news" title={t('Новости')} onClick={handleChangePage} disabled> */}
              {/*    <span className="img"> */}
              {/*      <svg> */}
              {/*        <use xlinkHref="#news" /> */}
              {/*      </svg> */}
              {/*    </span> */}
              {/*    <span className="title">{t('Новости')}</span> */}
              {/*  </Link> */}
              {/* </li> */}
              {/* <li className={`main-nav_menu-list-item ${location.pathname.match('/faq') ? 'is-active' : ''}`}> */}
              {/*   <Link to="/faq" title={t('Техподдержка')} onClick={handleChangePage} disabled> */}
              {/*     <span className="img"> */}
              {/*       <svg id="faq" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
              {/*         <path */}
              {/*           d="M10.332 1a9.332 9.332 0 1 0 9.332 9.332A9.343 9.343 0 0 0 10.332 1Zm0 16.175a.933.933 0 1 1 0-1.866.933.933 0 0 1 0 1.866Zm1.106-5.752a.638.638 0 0 0-.484.606v1.414a.622.622 0 1 1-1.244 0v-1.414a1.87 1.87 0 0 1 1.388-1.803 2.8 2.8 0 1 0-3.566-2.693.622.622 0 1 1-1.244 0 4.044 4.044 0 1 1 5.15 3.89Z" */}
              {/*           fill="url(#a)" */}
              {/*         /> */}
              {/*         <defs> */}
              {/*           <linearGradient id="a" x1="1" y1="10.531" x2="19.734" y2="11.02" gradientUnits="userSpaceOnUse"> */}
              {/*             <stop stopColor="#FA6E7F" /> */}
              {/*             <stop offset="1" stopColor="#FDB199" /> */}
              {/*           </linearGradient> */}
              {/*         </defs> */}
              {/*       </svg> */}
              {/*     </span> */}
              {/*     <span className="title">FAQ</span> */}
              {/*   </Link> */}
              {/* </li> */}

              <li className="main-nav_menu-list-item hide-desktop" style={{ display: 'none' }}>
                <a href="#" title={t('Версия для ПК')} onClick={(e) => handleChangeViewMode(e, 'desktop')}>
                  <span className="img">
                    <svg>
                      <use xlinkHref="#pc" />
                    </svg>
                  </span>
                  <span className="title">{t('Версия для ПК')}</span>
                </a>
              </li>
              {user.viewMode === VIEW_MODE_DESKTOP && (
                <li className="main-nav_menu-list-item hide-mobile">
                  <a href="#" title={t('Мобильная версия')} onClick={(ev) => handleChangeViewMode(ev, null)}>
                    <span className="img">
                      <svg>
                        <use xlinkHref="#mobile" />
                      </svg>
                    </span>
                    <span className="title">{t('Мобильная версия')}</span>
                  </a>
                </li>
              )}

              <li className="main-nav_menu-list-item hide-desktop">
                <a href="#" title={t('Выход')} onClick={handleLogout}>
                  <span className="img" />
                  <span className="title">{t('Выход')}</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="main-nav--mobile">
            <div className="mobile-balance">
              <div className="mobile-balance--header">
                <div className="mobile-balance--title">{t('Баланс')}:</div>
                <div className="mobile-balance--value">{balance}</div>
              </div>
              <div className="mobile-balance--controls">
                <a href="#" className="btn btn-primary" onClick={handleTransfer}>
                  {t('Перевести')}
                </a>
                <a href="#" className="btn" onClick={handleWithdraw}>
                  {t('Вывести')}
                </a>
              </div>
            </div>
            <div className="mobile-controls">
              <div className="mobile-controls--lang">
                <LanguageSelector type="list" />
              </div>
              <div className="mobile-controls--theme">
                <ThemeToggler />
              </div>
            </div>
          </div>
        </div>
      </div>
      <svg width="0" height="0" className="hidden" fill="none" xmlns="http://www.w3.org/2000/svg">
        <symbol id="caret" viewBox="0 0 8 6">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.565 1.466a.682.682 0 0 0-.997-.932L4 3.286 1.432.534a.682.682 0 0 0-.997.932L4 5.286l3.565-3.82Z"
            fill="currentColor"
          />
        </symbol>

        <symbol id="icon-bookmark" viewBox="0 0 14 18">
          <path
            d="m6.97 13.8-.236-.1-.236.1-5.862 2.513v-14.4A1.314 1.314 0 0 1 1.95.6h9.568c.723 0 1.314.585 1.314 1.314v14.399L6.97 13.8Z"
            stroke="currentColor"
            strokeWidth="1.2"
          />
        </symbol>

        <symbol id="icon-heart" viewBox="0 0 20 18">
          <path
            d="M8.585 15.85h-.001c-2.476-2.245-4.482-4.068-5.877-5.774C1.32 8.378.6 6.869.6 5.262.6 2.639 2.646.6 5.262.6c1.484 0 2.918.694 3.85 1.78l.456.531.456-.53C10.957 1.294 12.39.6 13.873.6c2.616 0 4.662 2.038 4.662 4.662 0 1.607-.719 3.116-2.107 4.814-1.394 1.706-3.4 3.53-5.876 5.774h-.001l-.984.896-.983-.895Z"
            stroke="currentColor"
            strokeWidth="1.2"
          />
        </symbol>

        <symbol id="icon-share" viewBox="0 0 18 17">
          <path
            d="M4.062 12.175a3.71 3.71 0 0 0 2.947-1.472l3.662 1.974a3.3 3.3 0 0 0-.13.873 3.363 3.363 0 0 0 4.394 3.215 3.36 3.36 0 1 0-3.766-5.156L7.565 9.667c.297-.79.297-1.66 0-2.449l3.608-1.943a3.326 3.326 0 0 0 5.863-2.972 3.327 3.327 0 0 0-6.491 1.031c.004.296.048.59.13.874L7.009 6.182a3.722 3.722 0 0 0-6.295.622 3.727 3.727 0 0 0 3.348 5.37Zm9.817-.786a2.16 2.16 0 0 1 1.527 3.69 2.16 2.16 0 1 1-3.054-3.056 2.16 2.16 0 0 1 1.527-.634Zm0-10.215a2.159 2.159 0 0 1 2.16 2.16 2.161 2.161 0 0 1-3.688 1.529 2.162 2.162 0 0 1 1.528-3.69ZM4.062 5.889a2.551 2.551 0 0 1 2.552 2.553 2.554 2.554 0 0 1-2.552 2.554 2.551 2.551 0 0 1-2.553-2.554A2.557 2.557 0 0 1 4.062 5.89Z"
            fill="currentColor"
          />
        </symbol>

        <symbol id="icon-reverse" viewBox="0 0 13 8">
          <path
            d="M2.828 3.429 0 5.714 2.828 8V6.286h4.969V5.143H2.828V3.429Zm9.93-1.143L9.93 0v1.714H4.962v1.143H9.93v1.714l2.829-2.285Z"
            fill="currentColor"
          />
        </symbol>

        <symbol id="small-arrow" viewBox="0 0 15 8">
          <path
            d="M14.354 4.354a.5.5 0 0 0 0-.708L11.172.464a.5.5 0 1 0-.708.708L13.293 4l-2.829 2.828a.5.5 0 1 0 .708.708l3.182-3.182ZM0 4.5h14v-1H0v1Z"
            fill="currentColor"
          />
        </symbol>

        <symbol id="copy" viewBox="0 0 24 26">
          <path
            d="M14.461 0H3.385C2.487 0 1.627.351.992.975A3.3 3.3 0 0 0 0 3.325v13.13a3.3 3.3 0 0 0 .992 2.35 3.418 3.418 0 0 0 2.393.976h2.621v2.894c0 .881.358 1.727.992 2.35A3.418 3.418 0 0 0 9.391 26h11.224c.898 0 1.758-.351 2.393-.975.634-.623.991-1.469.992-2.35V9.372c0-.882-.358-1.727-.992-2.35a3.418 3.418 0 0 0-2.393-.976h-2.769v-2.72a3.3 3.3 0 0 0-.992-2.351A3.419 3.419 0 0 0 14.462 0ZM1.846 16.455V3.326c0-.4.163-.785.451-1.068.289-.283.68-.443 1.088-.444H14.46c.408.001.8.16 1.088.444.288.283.45.667.45 1.068v13.13c0 .4-.162.784-.45 1.067-.289.284-.68.443-1.088.444H3.385c-.408 0-.8-.16-1.088-.444a1.501 1.501 0 0 1-.451-1.068Zm18.769-8.594c.408 0 .799.16 1.087.443.288.284.45.668.451 1.069v13.302c0 .4-.162.785-.45 1.068-.29.283-.68.443-1.088.444H9.39c-.408 0-.799-.16-1.087-.443a1.5 1.5 0 0 1-.451-1.069v-2.894h6.61c.896 0 1.757-.351 2.391-.975a3.3 3.3 0 0 0 .993-2.35V7.86h2.769Z"
            fill="currentColor"
          />
        </symbol>

        <symbol id="qr" viewBox="0 0 28 22">
          <path
            d="M3.81 8.024V1h7.023M1 10.833h25.286M3.81 13.398v7.269h7.023M16.453 1h7.023v7.024m0 5.619v7.024h-7.024"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </symbol>

        <symbol id="social-facebook" viewBox="0 0 12 24">
          <path
            d="M12 0v4.8H9.6c-.828 0-1.2.972-1.2 1.8v3H12v4.8H8.4V24H3.6v-9.6H0V9.6h3.6V4.8A4.8 4.8 0 0 1 8.4 0H12Z"
            fill="currentColor"
          />
        </symbol>

        <symbol id="social-instagram" viewBox="0 0 23 24">
          <path
            d="M6.67 0h9.66C20.01 0 23 3.12 23 6.96v10.08a7.117 7.117 0 0 1-1.954 4.922C19.796 23.267 18.1 24 16.33 24H6.67C2.99 24 0 20.88 0 17.04V6.96c0-1.846.703-3.616 1.954-4.921C3.204.733 4.9 0 6.67 0Zm-.23 2.4a4.054 4.054 0 0 0-2.927 1.265A4.417 4.417 0 0 0 2.3 6.72v10.56c0 2.388 1.852 4.32 4.14 4.32h10.12a4.054 4.054 0 0 0 2.927-1.265A4.417 4.417 0 0 0 20.7 17.28V6.72c0-2.388-1.851-4.32-4.14-4.32H6.44Zm11.098 1.8c.38 0 .746.158 1.016.44.27.28.421.662.421 1.06s-.151.78-.421 1.06c-.27.282-.635.44-1.016.44-.382 0-.747-.158-1.017-.44A1.534 1.534 0 0 1 16.1 5.7c0-.398.151-.78.421-1.06.27-.282.635-.44 1.017-.44ZM11.5 6c1.525 0 2.988.632 4.066 1.757A6.135 6.135 0 0 1 17.25 12a6.135 6.135 0 0 1-1.684 4.243A5.631 5.631 0 0 1 11.5 18a5.63 5.63 0 0 1-4.066-1.757A6.135 6.135 0 0 1 5.75 12c0-1.591.606-3.117 1.684-4.243A5.631 5.631 0 0 1 11.5 6Zm0 2.4c-.915 0-1.793.38-2.44 1.054A3.68 3.68 0 0 0 8.05 12c0 .955.363 1.87 1.01 2.546A3.379 3.379 0 0 0 11.5 15.6c.915 0 1.793-.38 2.44-1.054A3.68 3.68 0 0 0 14.95 12a3.68 3.68 0 0 0-1.01-2.546A3.379 3.379 0 0 0 11.5 8.4Z"
            fill="currentColor"
          />
        </symbol>

        <symbol id="social-telegram" viewBox="0 0 29 24">
          <path
            d="m10.76 22.548.444-6.712L23.391 4.854c.54-.492-.11-.73-.825-.301L7.523 14.058l-6.507-2.063c-1.396-.396-1.412-1.364.318-2.063L26.676.157c1.159-.523 2.27.286 1.825 2.063l-4.316 20.328c-.302 1.444-1.174 1.793-2.38 1.127l-6.57-4.856-3.158 3.063c-.365.364-.667.666-1.317.666Z"
            fill="currentColor"
          />
        </symbol>

        <symbol id="social-twitter" viewBox="0 0 30 24">
          <path
            d="M30 2.824a12.436 12.436 0 0 1-3.528.974 6.086 6.086 0 0 0 2.696-3.36 12.51 12.51 0 0 1-3.9 1.482C24.135.706 22.543 0 20.736 0c-3.37 0-6.123 2.71-6.123 6.056 0 .48.057.946.158 1.384A17.58 17.58 0 0 1 2.094 1.115a5.921 5.921 0 0 0-.832 3.036A5.936 5.936 0 0 0 4 9.176c-1.018 0-1.965-.282-2.796-.705v.042c0 2.936 2.122 5.393 4.933 5.944a6.146 6.146 0 0 1-2.768.098 6.126 6.126 0 0 0 5.736 4.207C7.012 20.4 4.36 21.36 1.463 21.36c-.488 0-.975-.028-1.463-.085A17.616 17.616 0 0 0 9.436 24c11.3 0 17.51-9.233 17.51-17.238 0-.268 0-.522-.015-.79A12.186 12.186 0 0 0 30 2.824Z"
            fill="currentColor"
          />
        </symbol>

        <symbol id="social-youtube" viewBox="0 0 14 12">
          <path
            d="M12.75 2.357c-.138-.602-.544-1.077-1.062-1.24C10.753.824 7 .824 7 .824s-3.751 0-4.688.293c-.516.16-.923.636-1.062 1.24C1 3.451 1 5.734 1 5.734s0 2.283.25 3.376c.138.602.544 1.078 1.062 1.24.937.293 4.688.293 4.688.293s3.753 0 4.688-.292c.516-.161.923-.636 1.062-1.241.25-1.093.25-3.376.25-3.376s0-2.283-.25-3.377Z"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="m5.8 7.534 3.118-1.8-3.117-1.8v3.6Z"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </symbol>

        <symbol id="arrow-link" viewBox="0 0 14 12">
          <path
            d="M12.818 6.675H.836A.829.829 0 0 1 0 5.853c0-.453.374-.822.836-.822h11.982c.463 0 .837.369.837.823a.829.829 0 0 1-.837.82Z"
            fill="currentColor"
          />
          <path
            d="M8.744 12a.844.844 0 0 1-.527-.185.811.811 0 0 1-.12-1.156l4.001-4.82-3.55-4.515A.81.81 0 0 1 8.7.17.845.845 0 0 1 9.871.32l3.955 5.032a.811.811 0 0 1-.014 1.022l-4.42 5.325a.838.838 0 0 1-.647.302Z"
            fill="currentColor"
          />
        </symbol>

        <symbol id="search" viewBox="0 0 18 18">
          <path
            d="M6.5 0A6.5 6.5 0 0 1 13 6.5c0 1.61-.59 3.09-1.56 4.23l.27.27h.79l5 5-1.5 1.5-5-5v-.79l-.27-.27A6.516 6.516 0 0 1 6.5 13a6.5 6.5 0 1 1 0-13Zm0 2C4 2 2 4 2 6.5S4 11 6.5 11 11 9 11 6.5 9 2 6.5 2Z"
            fill="currentColor"
          />
        </symbol>

        <symbol id="sandclock" viewBox="0 0 16 22">
          <path
            d="M15 20h-1v-1.667a4.998 4.998 0 0 0-.3-1.678.958.958 0 0 0-.035-.084A4.976 4.976 0 0 0 13 15.334l-1.4-1.867a3.02 3.02 0 0 1-.6-1.801v-1.01a3.021 3.021 0 0 1 .878-2.12l.658-.658a4.946 4.946 0 0 0 1.396-2.838c0-.014.008-.026.008-.04l-.003-.014c.035-.213.056-.428.063-.643V2h1a1 1 0 1 0 0-2H1a1 1 0 0 0 0 2h1v2.343c.007.215.028.43.063.643L2.06 5c0 .014.007.026.008.04a4.946 4.946 0 0 0 1.396 2.838l.658.657c.56.564.876 1.326.878 2.121v1.01a3.022 3.022 0 0 1-.6 1.8L3 15.335a4.983 4.983 0 0 0-.665 1.237.968.968 0 0 0-.035.084 5.003 5.003 0 0 0-.3 1.678V20H1a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2ZM4 4V2h8v2H4Zm.878 2.464A2.97 2.97 0 0 1 4.502 6h6.997a2.97 2.97 0 0 1-.377.464l-.658.657A4.96 4.96 0 0 0 9.042 10H6.957a4.96 4.96 0 0 0-1.421-2.879l-.658-.657ZM6 14.666c.58-.775.922-1.7.989-2.666H9.01c.067.965.41 1.89.989 2.666L11 16H5l1-1.334ZM12 20H4v-1.667c.003-.111.012-.223.027-.333h7.946c.015.11.024.222.027.333V20Z"
            fill="currentColor"
          />
          <path
            d="M15 20h-1v-1.667a4.998 4.998 0 0 0-.3-1.678.958.958 0 0 0-.035-.084A4.976 4.976 0 0 0 13 15.334l-1.4-1.867a3.02 3.02 0 0 1-.6-1.801v-1.01a3.021 3.021 0 0 1 .878-2.12l.658-.658a4.946 4.946 0 0 0 1.396-2.838c0-.014.008-.026.008-.04l-.003-.014c.035-.213.056-.428.063-.643V2h1a1 1 0 1 0 0-2H1a1 1 0 0 0 0 2h1v2.343c.007.215.028.43.063.643L2.06 5c0 .014.007.026.008.04a4.946 4.946 0 0 0 1.396 2.838l.658.657c.56.564.876 1.326.878 2.121v1.01a3.022 3.022 0 0 1-.6 1.8L3 15.335a4.983 4.983 0 0 0-.665 1.237.968.968 0 0 0-.035.084 5.003 5.003 0 0 0-.3 1.678V20H1a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2ZM4 4V2h8v2H4Zm.878 2.464A2.97 2.97 0 0 1 4.502 6h6.997a2.97 2.97 0 0 1-.377.464l-.658.657A4.96 4.96 0 0 0 9.042 10H6.957a4.96 4.96 0 0 0-1.421-2.879l-.658-.657ZM6 14.666c.58-.775.922-1.7.989-2.666H9.01c.067.965.41 1.89.989 2.666L11 16H5l1-1.334ZM12 20H4v-1.667c.003-.111.012-.223.027-.333h7.946c.015.11.024.222.027.333V20Z"
            fill="currentColor"
          />
        </symbol>
      </svg>
    </header>
  )
}
