import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AffiliateScreen from 'screens/Affiliate'
import AmbassadorScreen from 'screens/Ambassador'
import AuthScreen from 'screens/Auth'
import Dashboard from 'screens/Dashboard'
import FinancesScreen from 'screens/Finances'
import NewsScreen from 'screens/News'
import NewsDetailsScreen from 'screens/News/screens/NewsDetails'
import NotificationsScreen from 'screens/Notifications'
import SupportScreen from 'screens/Support'
import TicketDetailScreen from 'screens/Support/screens/TicketDetail'

import Error from './Error'
import Main from './Main'
import ProtectedRoute from './ProtectedRoute'

const RoutesRenderer = () => {
  return (
    <Routes>
      <Route
        path={'*'}
        element={
          <Main>
            <Error />
          </Main>
        }
      />
      <Route exact path="/" element={<ProtectedRoute />}>
        <Route path={'/'} exact element={<Dashboard />} />
        <Route path={'/affiliate'} exact element={<AffiliateScreen />} />
        <Route path={'/ambassador'} exact element={<AmbassadorScreen />} />
        <Route path={'/finances'} exact element={<FinancesScreen />} />
        <Route path={'/notifications'} exact element={<NotificationsScreen />} />
        <Route path={'/support'} exact element={<SupportScreen />} />
        <Route path={'/support/:id'} exact element={<TicketDetailScreen />} />
        <Route path={'/news'} exact element={<NewsScreen />} />
        <Route path={'/news/:id'} exact element={<NewsDetailsScreen />} />
        {/* <Route path={'/faq'} exact element={<FaqPage />} /> */}
      </Route>
      <Route path={'auth/*'} element={<AuthScreen />} />
    </Routes>
  )
}

export default RoutesRenderer
