import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useModalsStore from 'shared/stores/modals'
import useUserStore from 'shared/stores/user'

import QRModal from 'shared/components/QRModal'

const ReferralBlock = () => {
  const { t } = useTranslation()
  const user = useUserStore()
  const link = user?.referralLink ?? ''
  const inputEl = useRef()
  const [isCopied, setCopied] = useState(false)
  const modals = useModalsStore()

  const copyCodeToClipboard = (ev) => {
    ev.preventDefault()
    const el = inputEl.current

    el.select()
    document.execCommand('copy')
    setCopied(true)
  }

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setCopied(false), 3000)
    } else {
      clearTimeout()
    }
  }, [isCopied])

  const handleOpenModal = () => {
    modals.show('qr')
  }

  return (
    <div className="home-referal">
      <div className="home-referal--content">
        <div className="home-referal--title">
          {t('Получайте вознаграждение филиала от клиентов с 8-уровней личных офисов!')}
        </div>

        <div className="home-referal--description">
          {t('Огранизуйте работу филиала и получайте повышенные агентские вознаграждения!')}
          <a href="#" className="arrow-link" style={{ display: 'none' }}>
            <span>{t('Подробнее')}</span>
          </a>
        </div>
      </div>

      <div className="home-referal--form">
        <div className="form-group">
          <label className="control-label">{t('Агентская ссылка')}:</label>

          <div className="form-group-row">
            <div className="form-group-row--input">
              <div className="form-field">
                <input type="text" className="form-control" value={link} readOnly ref={inputEl} />
                <div className="form-field--option">
                  <button className={`btn-copy copy-btn ${isCopied ? 'copied' : ''}`} onClick={copyCodeToClipboard}>
                    <svg>
                      <use xlinkHref="#copy" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group-row--button">
              <button className="btn-qr btn-modal" onClick={handleOpenModal} />
            </div>
          </div>
        </div>
      </div>
      <QRModal value={link} isOpen={modals.qr} onClose={() => modals.hide('qr')} />
    </div>
  )
}

export default ReferralBlock
