import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import useSetState from 'react-use/lib/useSetState'

import Breadcrumbs from 'components/Breadcrumbs'

import Button from 'shared/components/Button'

import { reopenTicket } from '../../api/support'
import { SUPPORT_STATUS_DONE } from '../../constants'
import useTicketsData from '../../hooks/useTicketsData'
import MessageForm from './components/MessageForm'
import TicketMessages from './components/Messages'

const TicketDetail = () => {
  const { t } = useTranslation()
  const { id } = useParams()

  const [loading, setLoading] = useSetState({
    close: false,
    open: false,
  })

  const { fetchTicketDetail, fetchTicketMessages, ticketData, ticketMessages } = useTicketsData()

  const isClosed = ticketData.status === SUPPORT_STATUS_DONE

  useEffect(() => {
    fetchTicketDetail(id)
    fetchTicketMessages(id)
  }, [id])

  const handleOpenTicket = () => {
    setLoading({ open: true })
    reopenTicket(id)
      .then(() => fetchTicketDetail(id))
      .catch((e) => console.log(e))
      .finally(() => setLoading({ open: false }))
  }

  return (
    <div className="page page_finance">
      <div className="page--header">
        <Breadcrumbs currentCrumbs={[{ path: 'support/:id', breadcrumb: `${t('Обращение')} №${id}` }]} />

        <div className="page--header-container">
          <div className="page--title">
            {t('Обращение')} №{id}
          </div>
        </div>
      </div>
      <div className="page--content">
        <div className={`support-ticket ${loading.close || loading.open ? 'loading' : ''}`}>
          <div className="support-ticket--header">
            <div className="support-ticket--title">
              {t('Тема')}: {isClosed ? t('Закрытое обращение') : ticketData.subject}
            </div>
          </div>
          <TicketMessages ticketMessages={ticketMessages} isClosed={isClosed} setLoading={setLoading} />
          {isClosed ? (
            <div className="support-ticket--form">
              <div className="support-ticket-closed">
                <div className="support-ticket-closed--description">{t('Обращение закрыто')}</div>
                <div className="support-ticket-closed--buttons">
                  <Button onClick={handleOpenTicket} classes={['btn-sm btn_gt btn-light']}>
                    <span>{t('Открыть заново')}</span>
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <MessageForm />
          )}
        </div>
      </div>
    </div>
  )
}

export default TicketDetail
