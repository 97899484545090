import React from 'react'
import useMount from 'react-use/lib/useMount'

import useRankData from 'shared/hooks/useRankData'

import Balance from './components/Balance'
import History from './components/History'
import PageHeader from './components/PageHeader'
import Profit from './components/Profit'

const Finances = () => {
  const { fetchTotalStats } = useRankData()

  useMount(() => {
    fetchTotalStats()
  })

  return (
    <div className="page page_finance">
      <PageHeader />
      <div className="page--content">
        <div className="dashboard-grid">
          <Balance />
          <Profit />
        </div>
        <History />
      </div>
    </div>
  )
}

export default Finances
