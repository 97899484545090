import React from 'react'
import { useTranslation } from 'react-i18next'
import useMount from 'react-use/lib/useMount'
import useUnmount from 'react-use/lib/useUnmount'

const Error = ({ code = 404 }) => {
  const { t } = useTranslation()

  useMount(() => {
    document.body.classList.add('error-page')
  })

  useUnmount(() => document.body.classList.remove('error-page'))

  return (
    <div className="error">
      <div className="error--code">{code}</div>
      <div className="error--title">{code === 404 ? t('Страница не найдена') : t('Что-то пошло не так')}</div>
    </div>
  )
}

export default Error
