import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useMount from 'react-use/lib/useMount'
import useSetState from 'react-use/lib/useSetState'

import { parseAndFormat } from 'shared/utils/date'
import { asMoney } from 'shared/utils/money'
import { countryFlag } from 'shared/utils/ui'
import { getAvatar, getFullName } from 'shared/utils/user'

import { getHistory as fetchHistory, getStructureBonusesStats } from 'shared/api/structure'

import Pagination from 'shared/components/Pagination'

import ParticipantModal from './ParticipantModal'

const MAX_PAGE_SIZE = 10

const History = () => {
  const { t } = useTranslation()
  const [, setLoading] = useState(false)
  const [data, setData] = useState({
    data: [],
    page: 1,
    size: MAX_PAGE_SIZE,
    count: 0,
  })
  const [bonusesStats, setBonusesStats] = useSetState({
    data: {},
    loading: false,
  })
  const [modal, toggleModal] = useSetState({
    open: false,
    participant: null,
  })

  const fetchData = (page = 1, size = 10, showLoading = true) => {
    if (showLoading) {
      setLoading(true)
    }

    return fetchHistory(8, page, size)
      .then((data) => setData(data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  const fetchBonusesStats = () => {
    setBonusesStats({ loading: true })
    getStructureBonusesStats()
      .then((res) => setBonusesStats({ data: res }))
      .catch((err) => console.log(err))
      .finally(() => setBonusesStats({ loading: false }))
  }

  useMount(() => {
    fetchBonusesStats()

    fetchData(1, 10, false)
  })

  const handleOpenModal = (ev, user) => {
    ev.preventDefault()

    toggleModal({ open: true, participant: user })
  }

  return (
    <div className="layout-block">
      <div className="layout-block--header">
        <div className="layout-block--title">{t('Агентские вознаграждения')}</div>
      </div>
      <div className="layout-block--body">
        <div className="panel">
          <div className="panel-header">
            <div className="my-office-stats">
              <div className="my-office-stats--item">
                <div className="my-office-stats--title">{t('Линейный бонус')}</div>
                <div className="my-office-stats--value">
                  {asMoney(bonusesStats.data.bonuses?.linear[0].amount, bonusesStats.data.bonuses?.linear[0]?.currency)}
                </div>
              </div>
              <div className="my-office-stats--item">
                <div className="my-office-stats--title">{t('Линейный бонус')}</div>
                <div className="my-office-stats--value">
                  {asMoney(bonusesStats.data.bonuses?.linear[1].amount, bonusesStats.data.bonuses?.linear[1]?.currency)}
                </div>
              </div>
              <div className="my-office-stats--item">
                <div className="my-office-stats--title">{t('Матчинг бонус')}</div>
                <div className="my-office-stats--value">
                  {asMoney(bonusesStats.data.bonuses?.matching.amount, bonusesStats.data.bonuses?.matching?.currency)}
                </div>
              </div>
              <div className="my-office-stats--item">
                <div className="my-office-stats--title">{t('Биржевой бонус')}</div>
                <div className="my-office-stats--value">
                  {asMoney(bonusesStats.data.bonuses?.exchange.amount, bonusesStats.data.bonuses?.exchange?.currency)}
                </div>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <div className="table-wrapper">
              <table className="transactions-table transactions-table_history">
                <thead>
                  <tr>
                    <th>{t('Дата')}</th>
                    <th>{t('Клиент')}</th>
                    <th>{t('Сумма покупки')}</th>
                    <th>{t('Уровень')}</th>
                    <th>{t('Процент')}</th>
                    <th>{t('Вознаграждение')}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.data.length === 0 && (
                    <tr>
                      <td colSpan="6" className="td-empty">
                        {t('Нет данных')}
                      </td>
                    </tr>
                  )}
                  {data.data.map((log, key) => (
                    <tr key={key}>
                      <td data-title={t('Дата')}>{parseAndFormat(log.createdAt)}</td>
                      <td className="very-first-mobile">
                        <div className="usercard usercard_sm">
                          <div className="usercard--image">
                            <img src={getAvatar(log.user)} alt={log.user.login} />
                            {log.user?.profile?.country && (
                              <span className="userflag">
                                <img
                                  src={countryFlag(log.user?.profile?.country)}
                                  style={{
                                    display: 'inline-block',
                                    width: '1em',
                                    height: '1em',
                                    verticalAlign: 'middle',
                                  }}
                                />
                              </span>
                            )}
                          </div>
                          <div className="usercard--content">
                            <div className="usercard--title">{getFullName(log.user)}</div>
                            <div className="usercard--link">
                              <a href="#" onClick={(ev) => handleOpenModal(ev, log.user)}>
                                {log.user.login}
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td data-title={t('Сумма покупок')}>
                        {asMoney(log.purchaseAmount.value, log.purchaseAmount.currency)}
                      </td>
                      <td data-title={t('Уровень')}>{log.level}</td>
                      <td data-title={t('Процент')}>{log.percent}%</td>
                      <td className="td-highlight" data-title={t('Вознаграждение')}>
                        {asMoney(log.amount.value, log.amount.currency)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              page={data.page}
              initialPage={data.page - 1}
              pageCount={Math.ceil(data.count / MAX_PAGE_SIZE)}
              onSelect={(num) => {
                fetchData(num, MAX_PAGE_SIZE)
              }}
            />
          </div>
        </div>
      </div>

      {modal.open && (
        <ParticipantModal
          isOpen={modal.open}
          participant={modal.participant}
          onClose={() => toggleModal({ open: false, participant: null })}
        />
      )}
    </div>
  )
}

export default History
