import { LANDING_URL, LANGUAGES } from 'shared/constants'

const declOfNum = (number, titles) => {
  number = Math.abs(number)
  if (Number.isInteger(number)) {
    const cases = [2, 0, 1, 1, 1, 2]

    return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
  }
  return titles[1]
}

const getPercentOfNumber = (value = 0, target = 0, isFixed = true) => {
  if (target === 0) {
    return 0
  }

  let percent = (value * 100) / target

  percent = percent > 100 ? 100 : percent

  if (!isFixed) {
    return percent
  }

  return percent > 0 && percent < 1 ? percent.toFixed(1) : percent.toFixed(0)
}

const getClientLanguage = () => {
  const clientLanguage = Object.keys(LANGUAGES).find((lang) => lang.match(navigator.language.slice(0, 2)) !== null)

  if (clientLanguage) {
    return clientLanguage
  }

  return 'en' // default language
}

const getLandingUrl = () => {
  return LANDING_URL
}

const getLoginUrl = (token) => {
  return `auth/login/${token}`
}

const availableBilling = (login) => {
  const available = Boolean(parseInt(process.env.REACT_APP_AVAILABLE_BILLING))

  if (available) {
    return true
  }

  const availableUsers = (process.env.REACT_APP_AVAILABLE_BILLING_USERS || '').split(',')

  return availableUsers.includes(login)
}

const countryFlag = (country) => `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.4/flags/4x3/${country}.svg`

export { declOfNum, getPercentOfNumber, getClientLanguage, getLandingUrl, availableBilling, getLoginUrl, countryFlag }
