import { sortBy } from 'lodash/collection'
import React, { useMemo, useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useMount } from 'react-use'
import * as yup from 'yup'

import useFormWrapper from 'shared/hooks/useFormWrapper'

import useUserStore from 'shared/stores/user'

import Modal from 'shared/components/Modal'

import { changeCountry } from '../api/profile'
import useSettings from '../hooks/useSettings'
import Button from './Button'
import Dropdown from './forms/Dropdown'
import FormField from './forms/FormField'

const validationSchema = yup.object().shape({
  country: yup
    .string()
    .trim()
    .required(<Trans i18nKey={'Обязательное поле'} />),
})

const ChangeCountryModal = ({ isOpen = false, onClose }) => {
  const { t, i18n } = useTranslation()
  const user = useUserStore()
  const language = i18n.language
  const { countries = [] } = useSettings()
  const { form, getFieldError } = useFormWrapper(validationSchema, {
    mode: 'all',
    defaultValues: {},
  })
  const [loading, setLoading] = useState(false)
  const handleClose = (ev) => {
    ev.preventDefault()
    form.setValue('country', '')
    onClose && onClose()
  }

  const countryOptions = useMemo(() => {
    const result = Object.keys(countries).map((code) => {
      const country = countries[code]
      const lang = language.toUpperCase()

      const label = country.translations[lang] ? country.translations[lang].common : country.name.common

      return {
        label: label,
        value: country.code,
        icon: <ReactCountryFlag countryCode={code} svg />,
      }
    })

    return sortBy(result, ['label'])
  }, [countries, language])

  const defaultCountry = useMemo(() => {
    const navigatorLanguage = navigator.language.split('-')

    let country = user.profile.country || navigatorLanguage?.[0] || language

    country = country ? country.toUpperCase() : null

    if (Object.keys(countries).includes(country)) {
      return country
    }
    return null
  }, [countries, language, user])
  const isSameCountry = defaultCountry === form.getValues('country')
  const isDisabled = !form.formState.isValid || isSameCountry

  const handleSubmit = () => {
    setLoading(true)
    changeCountry(form.getValues('country').toLowerCase())
      .then((res) => {
        toast.success(t('Страна успешно изменена!'))
        user.receiveProfile()
        onClose()
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false))
  }

  useMount(() => {
    form.watch()
  })

  return (
    <Modal isOpen={isOpen} onClose={handleClose} additionalClassName={'modal_sm'}>
      <a href="#" className="modal-close btn-modal-close" title={t('Закрыть')} onClick={handleClose}>
        {t('Закрыть')}
      </a>
      <div className="modal-content">
        <div className="modal-body">
          <div className="finance-form">
            <div className="finance-form--header">
              <div className="finance-form--title">{t('Сменить страну')}</div>
            </div>

            <FormField error={getFieldError('country')}>
              <label className="control-label">{t('Страна')}</label>
              <Dropdown
                name={'country'}
                options={countryOptions}
                control={form.control}
                defaultValue={defaultCountry}
              />
            </FormField>

            <div className="finance-form--buttons">
              <Button
                variant={'primary'}
                classes={['btn_sqr btn-modal-close']}
                onClick={handleSubmit}
                disabled={isDisabled}
                loading={loading}
              >
                <span>{t('Сменить')}</span>
              </Button>
              <Button classes={['btn_sqr btn-modal-close']} onClick={handleClose}>
                <span>{t('Отменить')}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ChangeCountryModal
