import omit from 'lodash/omit'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useInterval from 'react-use/lib/useInterval'
import useMount from 'react-use/lib/useMount'
import * as yup from 'yup'

import useFormWrapper from 'shared/hooks/useFormWrapper'

import useUserStore from 'shared/stores/user'

import { durationShift, format } from 'shared/utils/counter'

import { requestWithdrawalConfirmation, withdrawalMoney } from 'shared/api/finances'

import Button from 'shared/components/Button'
import FormField from 'shared/components/forms/FormField'
import Input from 'shared/components/forms/Input'

import useStepsStore from '../stores/steps'

const COUNTER_PERIOD = 30_000
const COUNTER_FREQUENCY = 1_000
const AMOUNT_STEP_KEYS = ['amount', 'account']

const SecurityForm = () => {
  const steps = useStepsStore()
  const user = useUserStore()
  const { t } = useTranslation()

  const validationSchema = yup.object().shape({
    telegramCode: yup.number().required(t('Обязательное поле')).typeError(t('Значение должно быть числом')),
  })

  const { getFieldError, form, setFormErrors } = useFormWrapper(validationSchema, {
    mode: 'all',
    reValidateMode: 'all',
  })
  const prevStepData = steps.getStep(1).payload
  const stepData = steps.getActiveStep().payload
  const [formLoading, setFormLoading] = useState(false)

  const [loading, setLoading] = useState(false)

  const [counter, setCounter] = useState(0)
  const [received, setReceived] = useState(false)
  const runCounter = counter > 0
  const disabled = loading || runCounter

  const dur = durationShift(counter)

  useMount(() => {
    form.watch()
    handleSendCode()
  })

  useInterval(
    () => {
      if (counter > 0) {
        setCounter(counter - COUNTER_FREQUENCY)
      }
    },
    runCounter ? COUNTER_FREQUENCY : null,
  )

  const handleClickSubmit = () => {
    const data = {
      telegramCode: form.getValues('telegramCode').trim(),
      ...prevStepData,
      ...omit(stepData, ['confirm', 'telegramCode']),
    }

    setFormLoading(true)
    withdrawalMoney({
      amount: data.amount,
      account: data.account,
      telegramCode: data.telegramCode,
      toCurrency: data.toCurrency,
      toBlockchain: data.toBlockchain,
    })
      .then(() => {
        steps.setActiveStep(3)
        user.receiveProfile()
      })
      .catch((err) => {
        if (err.errors) {
          const errors = Object.keys(err.errors)
          const stepNumber = errors.find((el) => AMOUNT_STEP_KEYS.includes(el)) ? 1 : 2

          if (stepNumber === 1) {
            steps.setActiveStep(1, { errors: err.errors })
          } else {
            setFormErrors(err.errors)
          }
        }
      })
      .finally(() => {
        setFormLoading(false)
      })
  }

  const handleSendCode = () => {
    setLoading(true)
    requestWithdrawalConfirmation()
      .then(() => {
        setCounter(COUNTER_PERIOD)
        setReceived(true)
      })
      .catch((err) => {
        if (err.errors) {
          setFormErrors(err.errors)
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <div className="finance-form--fields">
        <FormField error={getFieldError('telegramCode')}>
          <label htmlFor="code" className="control-label">
            {t('Код подтверждения Telegram')}
          </label>

          <div className="form-group-row form-group-row_code">
            <div className="form-group-row--input">
              <Input register={form.register} name={'telegramCode'} disabled={!received} />
              <div className="help-message">{t('Чат-бот отправит вам код подтверждения')}</div>
            </div>
            <div className="form-group-row--button">
              <Button
                variant={'light'}
                classes={['btn-lg', 'btn_sqr', 'btn_gt']}
                loading={loading}
                disabled={disabled}
                onClick={handleSendCode}
              >
                <span>{t('Отправить')}</span>
              </Button>

              {runCounter && (
                <div className="help-message">
                  {t('Повторить через: {{seconds}} сек', { seconds: format(dur.seconds) })}
                </div>
              )}
            </div>
          </div>
        </FormField>
      </div>

      <div className="finance-form--buttons">
        <Button
          variant="primary"
          classes={['btn-lg', 'btn_sqr', 'btn-modal']}
          onClick={handleClickSubmit}
          disabled={!form.formState.isValid}
          loading={formLoading}
        >
          <span>{t('Вывести')}</span>
        </Button>
      </div>
    </>
  )
}

export default SecurityForm
