import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useClickAway } from 'react-use'
import { LANGUAGES } from 'shared/constants'

import useUserStore from 'shared/stores/user'

const LanguageSelector = () => {
  const user = useUserStore()
  const { i18n } = useTranslation()
  const selectEl = useRef(null)

  const [openedList, toggleList] = useState(false)

  const language = user.language ?? i18n.language

  useEffect(() => {
    if (language && user.language !== i18n.language) i18n.changeLanguage(language)
  }, [user.language])

  useClickAway(selectEl, () => {
    if (openedList) {
      toggleList(false)
    }
  })

  const handleSelectLanguage = async (ev, language) => {
    ev.preventDefault()

    await i18n.changeLanguage(language)
    if (language !== user.language) {
      user.setLanguage(language)
    }

    toggleList(false)
  }

  const handleToggleList = (ev) => {
    ev.preventDefault()
    toggleList(!openedList)
  }

  return (
    <div className={`lang-dropdown ${openedList ? 'is-active' : ''}`} ref={selectEl}>
      <a href="#" className="lang-dropdown--button" onClick={handleToggleList}>
        <span className="lang-dropdown--button-title">{LANGUAGES[language]}</span>
        <svg>
          <use xlinkHref="#caret" />
        </svg>
      </a>

      <ul className="lang-dropdown-menu">
        {Object.keys(LANGUAGES)
          .sort((a, b) => LANGUAGES[a].localeCompare(LANGUAGES[b]))
          .map((lang) => (
            <li key={lang} className={`lang-dropdown-menu--item ${lang === language ? 'is-active' : ''}`}>
              <a
                href="#"
                className="lang-dropdown-menu--link"
                onClick={(ev) => handleSelectLanguage(ev, lang)}
                title={LANGUAGES[lang]}
              >
                {LANGUAGES[lang]}
              </a>
            </li>
          ))}
      </ul>
    </div>
  )
}

export default LanguageSelector
