// URLs
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
export const LANDING_URL = process.env.REACT_APP_LANDING_URL
export const BASE_URL = process.env.REACT_APP_BASE_URL
export const WS_URL = process.env.REACT_APP_NOTIFICATION_URL

// LOGIN SOURCE
export const LOGIN_SOURCE = 'influenceclub'

// LOCAL STORAGE
export const LOCALSTORAGE_KEY = process.env.REACT_APP_LOCALSTORAGE_KEY
export const THEME_LOCALSTORAGE_KEY = `${LOCALSTORAGE_KEY}_theme`

// TELEGRAM
export const TELEGRAM_BOT_ID = process.env.REACT_APP_TELEGRAM_BOT_ID
export const TELEGRAM_BOT_NAME = process.env.REACT_APP_TELEGRAM_BOT_NAME

// SUPPORT MAIL
export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL

export const LANGUAGES = {
  ru: 'Русский',
  en: 'English',
  id: 'Indonesia',
}

// FIAT
export const CURRENCY_USD = 'USD'

export const CURRENCY_USCR_NAME = 'U$CR'

// CRYPTO
export const CURRENCY_USCR = 'USCR'
export const CURRENCY_USDR = 'USDR'
export const CURRENCY_USDT = 'USDT'
export const CURRENCY_USDC = 'USDC'
export const CURRENCY_TRX = 'TRX'
export const CURRENCY_BUSD = 'BUSD'
export const CURRENCY_DAI = 'DAI'

// CRYPTO TOKENS
export const CURRENCY_DCGT = 'DCGT'
export const CURRENCY_CR = 'CR'

// BASE
export const CURRENCY_BASE = CURRENCY_USCR

// WALLET TYPES
export const WALLET_TYPE_USCR = 10

export const USER_RANK_LABELS = {
  client: 'Клиент',
  'agent-1': 'Агент',
  'agent-2': 'Агент',
  'agent-3': 'Агент',
  'agent-4': 'Агент',
  'agent-5': 'Агент',
  'agent-6': 'Агент',
  'agent-7': 'Агент',
  'agent-8': 'VIP Агент',
}

// Blockchains
export const BLOCKCHAIN_RELICTUM = 'RELICTUM'
export const BLOCKCHAIN_TRX = 'TRX'
export const BLOCKCHAIN_ETH = 'ETH'
export const BLOCKCHAIN_BSC = 'BSC'

// PERIODS
export const PERIOD_ALL = 'all'
export const PERIOD_WEEK = 'week'
export const PERIOD_MONTH = 'month'
export const PERIOD_YEAR = 'year'
export const PERIOD_CUSTOM = 'custom'

// payment systems
const PAYMENT_SYSTEM_NAME_NODE = 'node'
const PAYMENT_SYSTEM_NAME_CRYPTO_GATEWAY = 'oberon'
const PAYMENT_SYSTEM_NAME_BSC = 'bsc'

export const PS_LABELS = {
  [PAYMENT_SYSTEM_NAME_NODE]: 'Relictum Node',
  [PAYMENT_SYSTEM_NAME_CRYPTO_GATEWAY]: 'Платежный шлюз',
  [PAYMENT_SYSTEM_NAME_BSC]: 'Binance Smart Chain',
}

export const CURRENCY_DESCRIPTIONS = {
  [`${BLOCKCHAIN_TRX}_${CURRENCY_USDT}`]: 'Tether TRC20',
  [`${BLOCKCHAIN_ETH}_${CURRENCY_USDT}`]: 'Tether ERC20',
  [`${BLOCKCHAIN_BSC}_${CURRENCY_USDT}`]: 'Tether BEP20',
  [CURRENCY_DCGT]: 'DC Grove Token',
  [CURRENCY_TRX]: 'Tron',
  [CURRENCY_USCR]: 'USD CoinRealtor',
  [CURRENCY_USDR]: 'USD Relictum',
  [CURRENCY_USDC]: 'USD Coin',
  [CURRENCY_BUSD]: 'Binance USD',
}

export const CURRENCY_ICONS = {
  [`${BLOCKCHAIN_TRX}_${CURRENCY_USDT}`]: '/uploads/coin-trc20.svg',
  [`${BLOCKCHAIN_ETH}_${CURRENCY_USDT}`]: '/uploads/coin-erc20.svg',
  [`${BLOCKCHAIN_BSC}_${CURRENCY_USDT}`]: '/uploads/coin-binance-usdt.svg',
  [CURRENCY_DCGT]: '/uploads/coin-dcgt.svg',
  [CURRENCY_USCR]: '/uploads/coin-uscr.svg',
  [CURRENCY_USDR]: '/uploads/coin-usdr.svg',
  [CURRENCY_TRX]: '/uploads/coin-tron.svg',
  [CURRENCY_USDC]: '/uploads/coin-usdc.svg',
  [CURRENCY_BUSD]: '/uploads/coin-busd.svg',
  [CURRENCY_DAI]: '/uploads/coin-dai.svg',
}

export const BLOCKCHAIN_LABELS = {
  [BLOCKCHAIN_RELICTUM]: 'Relictum',
  [BLOCKCHAIN_ETH]: 'Ethereum',
  [BLOCKCHAIN_TRX]: 'Tron',
  [BLOCKCHAIN_BSC]: 'Binance',
}

export const BLOCKCHAIN_ICONS = {
  [BLOCKCHAIN_RELICTUM]: '/uploads/relictum.svg',
  [BLOCKCHAIN_ETH]: '/uploads/etherium.svg',
  [BLOCKCHAIN_TRX]: '/uploads/tron.svg',
  [BLOCKCHAIN_BSC]: '/uploads/binance.svg',
}

// calculator amount types
export const TYPE_AMOUNT = 'amount'
export const TYPE_CURRENCY_AMOUNT = 'currencyAmount'

export const USER_STATUS_NEW = 0
export const USER_STATUS_ACTIVE = 1
export const USER_STATUS_ARCHIVE = 2
export const USER_STATUS_BLOCKED = 10

// TRANSACTION STATUSES
export const TRANSACTION_STATUS_NEW = 0
export const TRANSACTION_STATUS_PROCESSED = 1
export const TRANSACTION_STATUS_CANCELLED = 2

// WITHDRAWAL STATUSES
export const WITHDRAWAL_STATUS_NEW = 0
export const WITHDRAWAL_STATUS_CANCELLED = 1
export const WITHDRAWAL_STATUS_PENDING = 3
export const WITHDRAWAL_STATUS_PROCESSING = 5
export const WITHDRAWAL_STATUS_ERROR = 7
export const WITHDRAWAL_STATUS_PROCESSED = 9

// notifications TYPES
export const NOTIFICATION_TYPE_INFO = 'INFO'
export const NOTIFICATION_TYPE_NEWS = 'NEWS'
export const NOTIFICATION_TYPE_FINANCE = 'FINANCE'
export const NOTIFICATION_TYPE_SUPPORT = 'SUPPORT'
export const NOTIFICATION_TYPE_NEW_REFERRAL = 'NEW_REFERRAL'

// notifications FILTERS
export const NOTIFICATION_FILTER_ALL = ''
export const NOTIFICATION_FILTER_NEW = 0
export const NOTIFICATION_FILTER_READ = 1

// themes
export const LIGHT_THEME = 'light-theme'
export const DARK_THEME = 'dark-theme'

// VIEW TYPES
export const VIEW_TYPE_TABLE = 'table'
export const VIEW_TYPE_CHART = 'chart'

const VIEW_TYPE_LABELS = { [VIEW_TYPE_TABLE]: 'Таблица', [VIEW_TYPE_CHART]: 'График' }

export const VIEW_TYPE_TAB_OPTIONS = [
  { value: VIEW_TYPE_TABLE, label: VIEW_TYPE_LABELS[VIEW_TYPE_TABLE] },
  { value: VIEW_TYPE_CHART, label: VIEW_TYPE_LABELS[VIEW_TYPE_CHART] },
]

export const NEWS_SOURCE_COMMON = 'common'
export const NEWS_SOURCE_INFLUENCECLUB = 'influenceclub'
