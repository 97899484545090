// TODO:: make exchange with requests when api will appear
const useExchange = (form, amountFromKey, amountToKey) => {
  const onChange = (e) => {
    const toKey = e.target.name === amountFromKey ? amountToKey : amountFromKey
    const value = e.target.value

    form.setValue(e.target.name, value)
    form.setValue(toKey, value)
  }

  return {
    onChange,
  }
}

export default useExchange
